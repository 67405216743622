import img from '@/assets/code.png'

const zh = {
  message: {
    有效期: '有效期',
    过期: '已过期',
    工作台: '工作台',
    我的工作台: '我的工作台',
    确认删除: '该操作不能恢复，确认删除该任务吗？',
    确认中止: '该操作不能恢复，确认终止该任务吗？',
    Language: '语言',
    Chinese: '中文',
    English: 'English',
    Services: '产品模块',
    Help: '帮助',
    Contact: '联系我们',
    ProductsSolutions: '产品与解决方案',
    LearnSupport: '学习与支持',
    ProductDocuments: '产品文档',
    Documents: '文档',
    OtherTools: '其他工具',
    Forum: '社区',
    SuccessCase: '成功案例',
    性质预测: '性质预测',

    PersonCenter: '个人中心',
    TaskCenter: '任务中心',
    Users: '用户',

    CreateTask: '创建任务',
    LearnMore: '了解更多',
    DenovoDrugDesign: '全新药物设计',
    ADMETPredictors: '成药性预测',
    VirtualScreening: '虚拟筛选',

    ServicePipeline: '创建模块任务流',
    ModelBuilding: '拖拽式构建深度模型',
    DataPreprocessingTools: '药物数据预处理',
    LearnMoreModules: '了解更多关于这些模块的信息',

    Signin: '登录',
    EnterUsername: '输入用户名',
    EnterPassword: '输入密码',
    EnterPassword2: '再次输入密码',
    EnterEmail: '输入邮箱',
    WelcomeBack: '欢迎回来',
    LoginIntro: '登录管理你的数据',
    LoginTitle: '登录',
    SuggestSignup: '还没有账户，前往注册！',
    Email: '电子邮件',
    Name: '名字',
    Password: '密码',
    Password_confirmation: '确认密码',
    password_placeholder: '起码8个字符，包括数组和字母',
    '60s后重试前': '',
    '60s后重试后': 's后重试',

    Signup: '注册',
    CreateAccount: '创建一个新的账户',
    SignupIntro: '填写表格开始您的使用。',
    SignupTitle: '注册',
    SuggestLogin: '已经有账户？前往登录',
    提交: '提交',
    重置密码: '重置密码',
    设置密码: '为了您账号安全，请设置密码',
    忘记密码: '忘记密码',
    回到登录页面: '回到登录页面',
    发送邮件: '发送邮件',
    重新发送邮件: '重新发送邮件',
    激活: '激活',
    重新发送激活邮件: '重新发送激活邮件',
    激活语句: '您的激活链接无效，请验证后重新发送激活邮件',
    感谢注册: '感谢注册!',
    请访问: '请访问',
    来激活您的账号: '来激活您的账号',
    返回首页: '返回首页',
    邮件发送成功: '邮件发送成功',
    邮件发送失败: '邮件发送失败',
    来重置您的密码: '来重置您的密码',
    验证中: '验证中，请稍等...',
    验证成功: '验证成功',
    验证失败: '验证失败',
    密码修改成功: '密码修改成功，将在3秒后跳转至登录页面',
    密码设置成功: '密码设置成功，将在3秒后跳转至登录页面',
    登出成功: '登出成功',
    登出失败: '登出失败，请检查网络',
    邮箱地址不正确: '邮箱地址不正确',
    激活邮件已过期: '激活邮件已过期',
    跳转中: '...跳转中...',
    请更新您的浏览器:
        '您的浏览器不支持WebSocket协议，部分功能将无法使用，请更换为Chrome、Safari、Edge或者Firefox的最新版',
    正在处理: '正在处理，请稍后',

    Logout: '登出',
    setting: '设置',
    TaskAssigned_1: '任务创建成功，您可以继续下发任务！',
    TaskAssigned_2: '或者前往',
    TaskAssigned_3: '查看任务运行进度。',
    AskForHelp: '如需帮助，请联系我们',
    ParamsError: '参数存在错误，请检查！',
    UnknownError: '抱歉，出现未知错误。',
    修改成功: '修改成功',
    修改失败检查网络: '出现错误，请检查网络',
    industry: '行业领域',
    interest: '兴趣',
    company: '公司',
    phone: '联系方式',
    职务: '职务',
    姓名: '姓名',
    电话号码: '电话号码',
    单位名称: '单位名称',
    工作职务: '工作职务',
    我已阅读并同意: '我已阅读并同意',
    用户协议: '《用户协议》',
    和: '和',
    隐私协议: '《隐私协议》',
    填写信息进入新版本:
        '尊敬的DrugFlow注册用户，您好！<br /> <br /> 非常感谢您曾经注册试用过碳硅智慧DrugFlow - AI驱动的一站式创新药发现平台。<br /> <br /> 我们很高兴地宣布，现在DrugFlow软件已经升级到了最新版本，并在活性预测、分子生成和AI建模上有重大更新！为了确保您能享受到更好的体验，我们已经关闭了旧版本的网站应用，在您填写完以下信息以后，您将进入全新的DrugFlow1.0，而且您在旧系统上提交任务的结果将会同步迁移到新版本。希望我们的新版本网站应用能够为您带来更好的体验。<br />',
    新版本具体功能: '新版本具体功能如下：',
    感谢关注: '再次感谢您对碳硅智慧DrugFlow - AI驱动的一站式创新药发现平台的关注和支持！',
    更新信息_gpt:
        '您在DrugFlow上填写的信息不完整/不真实。为了确保我们提供更好的服务和支持，请您尽快更新您的信息。感谢您对碳硅智慧DrugFlow - AI驱动的一站式创新药发现平台的信任与支持。',
    更新信息:
        '由于您在DrugFlow上填写的信息不完整/不真实，我们将只会为您提供48h的试用期限。为了确保我们提供更好的服务和支持，请您尽快更新您的信息。感谢您对碳硅智慧DrugFlow - AI驱动的一站式创新药发现平台的信任与支持。',
    '48h已到期':
        '由于您在DrugFlow上填写的信息不完整/不真实，给您提供的48h试用期限已经到期。您可以通过更新以下信息来获取更长时间的试用期限。感谢您对碳硅智慧DrugFlow - AI驱动的一站式创新药发现平台的信任与支持。',
    '2m已到期':
        '您的DrugFlow1.0试用期已经结束，您将无法再提交新的任务。如果您希望继续使用网站功能，请扫描以下二维码联系我们购买License，感谢您对碳硅智慧DrugFlow - AI驱动的一站式创新药发现平台的信任与支持。',
    扫描二维码: '扫描二维码，添加您的专属微信助理',
    扫描购买License:
        '请扫描以下二维码联系我们购买License，感谢您对碳硅智慧DrugFlow - AI驱动的一站式创新药发现平台的信任与支持。',
    删除: '删除',
    中止: '终止',
    错误信息: '错误信息',
    错误详情: '错误详情',
    结果详情: '结果详情',
    edit: '编辑',
    rebind: '重新绑定',
    reset: '重新设置',
    setup: '已设置',
    save: '保存',
    cancel: '取消',
    change_login_pwd: '修改登录密码',
    origin_pwd: '原密码',
    new_pwd: '新密码',
    enter_origin_pwd: '请输入原密码',
    enter_new_pwd: '请输入新密码',
    confirm_new_pwd: '确认新密码',
    change_phone_number: '修改电话号码',
    mobile_code: '手机验证码',
    enter_mobile_code: '请输入手机验证码',
    enter_phone_number: '请输入电话号码',
    send_code: '发送验证码',
    confirm: '确定',
    code_tips: '请先输入手机号',
    暂无数据: '暂无数据',
    重新发起: '重新发起',
    问题类型: '问题类型',
    卡顿: '卡顿',
    界面及操作: '界面及操作',
    账号相关: '账号相关',
    其他: '其他',
    问题描述: '问题描述',
    上传截图: '上传截图',
    操作成功: '操作成功!',
    账户余额详情: '账户余额详情',
    在线充值: '在线充值',
    消费记录: '消费记录',
    充值时间: '充值时间',
    充值记录: '充值记录',
    账号详情: '账号详情',
    充值套餐: '充值套餐',
    请选择支付方式: '请选择支付方式',
    余额: '余额',
    充值提示: 
    "提示:<br />1.如果您有任何未付账单或逾期付款，您的充值金额将首先被分配来结算这些费用。<br />2.如果您通过官网自行充值，需要申请发票，您可以联系我们开具发票(电子邮件:hehao{'@'}carbonsiicon.ai).<br />3.当前只支持人民币充值，且充值后不支持退款，请谨慎充值。",
    套餐一: '套餐一',
    套餐一价格: '500元',
    套餐一token: '* 套餐Token:200w',
    套餐一有效期: '* 有效期: 1个月',
    套餐一详情: '* 所有模块均可使用，在有效期内，只要还有token，就可以发起任务',
    套餐二: '套餐二',
    套餐二价格: '1000元',
    套餐二token: '* 套餐Token:400w',
    套餐二有效期: '* 有效期: 3个月',
    套餐二详情: '* 所有模块均可使用，在有效期内，只要还有token，就可以发起任务',
    微信支付: '微信支付',
    支付宝: '支付宝',
    临期: '临期',
    总: '总',
  },
  index: {
    结构识别及数据提取: '结构提取及表格识别',
    结构识别及数据提取介绍: '该功能用于快速获取图中分子结构的信息，得到的结构式和分析结果可随时导出为计算机可读的各类格式(SDF/CSV等类型)，帮用户解决“画结构式时，耗时长且易出错”、 “批量收集专利或文献中化学结构信息时，耗时长且易漏分析”的难题，辅助科研工作者节省绘制化学结构时间和人力、提高批量处理化学结构数据效率、快速搭建自有数据库。',
    about_us: '杭州碳硅智慧科技发展有限公司',
    QSAR: 'Inno-QSAR',
    虚拟筛选: '虚拟筛选',
    获取验证码: '获取验证码',
    发现与验证: '靶标发现与验证',
    先导化合物发现: '先导化合物发现',
    先导化合物优化: '先导化合物优化',
    帮助文档: '帮助文档',
    我的工作台: '我的工作台',
    成靶性预测: '成靶性预测',
    反向寻靶: '反向寻靶',
    靶标结构预测: '靶标结构预测',
    结合位点预测: '结合位点预测',
    活性预测: '活性预测',
    分子对接: '分子对接',
    AI建模: 'AI建模',
    相似性搜索: '相似性搜索',
    全新药物设计: '全新药物设计',
    成药性预测: '成药性预测',
    活性预测介绍:
        '用于评估蛋白-配体的结合构象和结合亲和力，包含两个模块。<br />1）Inno-Docking：既集成了经典的物理对接程序，又集成了自研的AI 对接程序CarsiDock，用于预测结合构象和结合亲和力。提供完整的蛋白预处理、配体预处理和智能化设置对接参数能力。<br />2）Inno-Rescoring：提供了业界领先的AI打分函数(RTMScore和IGN)，支持用户上传对接构象信息后进行重打分，用于评估蛋白-配体的结合亲和力，在虚拟筛选等任务中相对传统物理打分函数有显著优势。',
    相似性搜索介绍:
        '基于相似性原理，从蛋白和配体的角度，我们为您提供了基于形状的(3D)和基于指纹的(2D)相似性搜索，该功能模块待发布。',
    全新药物设计介绍:
        '也称为分子生成。基于不同的原理和算法，平台为用户提供了两个基于不同原理的生成模块。<br />1）MCMG：基于配体的分子生成方法；<br />2）RELATION：基于结构(蛋白-配体相互作用)的分子生成。',
    成药性预测介绍:
        '用于全面评估分子的成药性，包含3个相关模块。<br />1）Inno-ADMET：预测ADMET相关性质，支持17种物化性质、5种药化性质、6种成药性规则、21种ADME性质、27种毒性性质的系统性评价，目前支持两种算法，一种是基于多图神经网络的MGA方法，速度更快，且提供可解释功能，一种是基于预训练Transformer的MERT方法，精度更高。<br />2）ChemFH：过滤频繁命中化合物。<br />3）Inno-SA：预测子结构是否会造成毒性。',
    分子生成介绍:
        '提供了覆盖功能最全的分子生成模型。<br />1) 支持De novo、RGroup生成、Linker生成、骨架跃迁等多种分子生成方式。<br />2) 同时支持活性配体及口袋结构的生成。<br />3) 支持ADMET性质预测控制分子生成。<br />4) 基于点击化学和片段分割生成合成可及分子。',
    AI建模介绍:
        '主要用于帮助用户基于自有数据构建自定义的分类/回归模型。<br />1）Inno-QSAR：支持经典机器学习算法、GNN/Transformer等深度学习模型，引入了AutoML自动机器学习建模和预训练模型来提高建模效果，方便用户构建自定义的分类/回归模型。<br />2）Inno-QSAR Application：为Inno-QSAR的衍生模块，Inno-QSAR Application基于Inno-QSAR构建的模型来做预测。',
    活性优化: '活性优化',
    成药性优化: '成药性优化',
    选择性优化: '选择性优化',
    抗耐药性设计: '抗耐药性设计',
    蛋白预处理: '蛋白预处理',
    小分子: '小分子',
    小分子预处理: '小分子预处理',
    小分子构象搜索: '小分子构象搜索',
    蛋白质: '蛋白质',
    蛋白对齐: '蛋白对齐',
    蛋白结构质量: '蛋白结构质量',
    研发中: '研发中',
    对接重打分: 'Inno-Docking',
    重打分: 'Inno-Rescoring',
    待发布: '待发布',
    AI驱动的新药发现SaaS平台: 'AI驱动的一站式创新药发现平台',
    平台介绍:
        '拥有自主知识产权的核心算法，涵盖药物发现全流程，包括靶标发现，苗头化合物发现和先导化合物优化。基于最先进的AIGC、自监督预训练、强化学习等AI技术，并深度融合物理计算，帮助药化专家更高效便捷地找到潜在成药分子。',
    请输入您的邮箱: '请输入您的邮箱',
    开始使用: '开始使用',
    平台能力: '平台能力',
    平台特点: '平台特点',
    平台特点详细:
        '涵盖药物发现全流程的一站式SaaS平台，为创新药研发量身定做，互联网风格交互界面，易于上手，集成具有自主知识产权的多项能力，包括基于知识图谱和多模态分析的靶点发现能力，基于人工智能和物理模型深度结合的先导化合物筛选能力，以及AI模型与专家交互并重的先导化合物优化能力等。',
    精准分子对接: '精准分子对接',
    精准分子对接介绍:
        '将配体对接到受体的结合口袋中，并使用先进的 InteractionGraphNet (IGN) 技术重新评估结合亲和力',
    试用Docking: '试用Docking',
    使命: '使命',
    用人工智能重塑新药研发: '用人工智能重塑新药研发',
    愿景: '愿景',
    做中国最好的创新智能研发平台: '做最好的创新药智能研发平台',
    相关链接: '相关链接',
    碳硅智慧科技发展有限公司: '杭州碳硅智慧科技发展有限公司',
    公司业务: '公司业务',
    CARO服务: 'CARO服务',
    关于我们: '关于我们',
    公司简介: '公司简介',
    团队介绍: '团队介绍',
    联系我们: '联系我们',
    加入我们: '加入我们',
    简历投递: '简历投递',
    业务合作: '业务合作',
    投资合作: '投资合作',
    联系电话: '联系电话',
    隐私政策: '隐私政策',
    法律声明: '法律声明',
    营业执照: '营业执照',
    其他业务: '其他业务',
    已有账号: '已有账号，忘记密码？',
    没有账号: '没有账号？前往注册',
    登录以访问控制台: '登录以访问控制台',
    欢迎来到: '欢迎来到',
    账户管理: '账户管理',
    前往登录: '前往登录',
    文档: '文档',
    dashboard: '工作台',
    dashboard_intro: '在该页面，您可以了解节点资源信息和任务运行状态。',
    MCMG: 'MCMG',
    RELATION: 'RELATION',
    ADMET: 'ADMET',
    ChemFH: 'ChemFH',
    SubstructAlert: 'Substruct Alert',
    其他: '其他',
    药厂: '药厂',
    生物技术公司: '生物技术公司',
    高校: '高校',
    研究所: '研究所',
    api: 'API',
    用户信息: '用户信息',
    主要模块: '主要模块',
    Pipeline工具: 'Pipeline工具',
    设置: '设置',
    扩展: '扩展',
    数据处理: '数据处理',
    个人中心: '个人中心',
    更多详情: '更多详情',
    Inno_modeler: 'AI模型应用',
    Inno_modeling: 'AI建模',
    Inno_qsar_app: 'Inno-QSAR Application',
    用户管理: '用户管理',
    APP管理: 'APP管理',
    管理: '管理',
    data_center: '数据中心',
    用户个人数据: '用户个人数据',
    小分子数据库: '小分子数据库',
    任务中心: '任务中心',
    ligand_prep: '配体预处理',
    protein_prep: '蛋白预处理',
    molecular_Factory: '分子工厂',
    screen_virtual: '虚拟筛选',
    应用场景: '应用场景',
    QSAR_train: 'QSAR建模',
    molgen_train: '分子生成建模',
    分子生成: '分子生成',
    'Inno-ADMET': 'Inno-ADMET',
    'Inno-SA': 'Inno-SA',
    spectrum_title: 'AI解谱(NMR)',
    其他工具: '其他工具',
    其他工具介绍:
        'NMR Parsing: 该模块提供了两种不同的AI算法，即CReSS和CMGNet，来解析未知化合物的结构。只需输入化学位移值，平台即可快速协助用户解析分子结构。该方法在有机化学和天然产物化学的研究中具有重要意义。',
    screen_docking_intro: '集蛋白预处理、配体预处理，对接参数设置为一体的AI对接',
    screen_rescoring_intro: '基于AI的打分函数，重新评估蛋白质-配体的结合亲和力',
    admet_dl_intro: '系统性评估分子的成药性',
    admet_fh_intro: '快速过滤频繁命中化合物',
    admet_sa_intro: '预测分子中是否存在毒性子结构',
    model_qsar_intro: '基于AI的自动化建模方法',
    model_use_intro: 'Inno-QSAR衍生的用于预测的模块',
    ligand_prep_intro: '分子处理工具',
    protein_preparation_intro: '交互式的对蛋白进行编辑及预处理',
    spectrum_ai_intro: '快速解析碳谱，帮助专家识别分子结构',
    molecular_factory_intro: '产出专家满意&可快速被验证的分子',
    screen_virtual_intro: '自定义Pipeline发现活性分子',
    意见反馈: '意见反馈'
  },
  molecular_Factory: {
    相互作用残基: '相互作用残基',
    氢健: '氢健',
    对接后配体: '生成的分子',
    删除片段: '删除片段',
    对接构象比较: '对接构象比较',
    微调数据不足: '活性分子数量<300时，会降低模型的fine-tune效果，请重新上传/选择数据',
    警告信息: '警告信息',
    molecular_Factory_title: '分子工厂',
    molecular_Factory_tip:
        '分子工厂是一个帮助用户筛选理想分子的自动化流程。分子工厂分为两步，主要包括了分子生成和Docking两个功能，用户可基于自己的需求设置相关参数，任务完成后，结果页面将提供丰富的功能以帮助用户筛选分子。',
    数据选择: '1.分子生成',
    筛选条件配置: '2.筛选条件配置',
    数据来源: '数据来源',
    分子生成: '分子生成',
    上传文件SDF: '上传文件.sdf',
    数据中心: '数据中心',
    生成类型: '生成类型',
    点击上传pdb文件: '点击上传 pdb 文件',
    上传预处理后的蛋白: '上传预处理后的蛋白',
    选择口袋: '选择口袋',
    指定位置生长: '指定位置生长',
    上传活性分子: '上传活性分子',
    点击上传csvsdf文件: '点击上传 csv/sdf 文件',
    生成方法: '生成方法',
    生成数量: '生成数量',
    生成时间: '生成时间',
    下一步: '下一步',
    分子基本属性过滤: '2D属性过滤',
    配体预处理: '配体预处理',
    点击设置参数: '点击设置参数',
    Docking参数设置: '3D属性计算',
    计算公共自结构的RMSD值: '计算公共自结构的RMSD值',
    信息过滤: '3D信息过滤',
    原子空间位置检测: '原子空间位置检测',
    任务名称: '任务名称',
    提交任务: '提交任务',
    上一步: '上一步',
    确定: '确定',

    上传数据: '上传数据',
    片段来源: '片段来源',
    分子中心: '分子中心',

    复合物配体: '复合物配体',
    预处理后的蛋白: '预处理后的蛋白',
    admet_dl: 'Inno-ADMET',
    admet_fh: 'ChemFH',
    admet_sa: 'Inno-SA',
    conformation: '对接构象',
    点击原子获取保留片段: '点击原子获取保留片段',
    解析配体失败:
        '注意！后台解析PDB中的配体失败，请通过粘贴smiles或者上传配体文件的方式来确定保留的片段',
    选择类型: '选择类型',
    上传蛋白及设置口袋: '上传蛋白及设置口袋',
    设置保留片段: '参考分子/参考片段',
    结束条件: '设置生成方法及结束条件',
    docking参数: 'Docking相关参数设置',
    生成异构体: '生成异构体',
    选择对接方法: '选择对接方法',
    设置对接口袋: '上传蛋白及设置对接口袋',
    上传活性_intro:
        '上传活性分子可对生成模型进行微调，使得生成的分子在结构/性质上与活性分子更相近。',
    结束条件_intro: '只要满足了设置的生成数量或生成时间中的任意一个条件，就可以终止生成任务。',
    生成异构体_intro: '开启异构体将基于生成的分子生成不同的异构体，而关闭将保留生成的原始构型。',
    构象比较_intro: '使用RMSD值来比较不同分子之间相同子结构的构象差异。',
    位置检测_intro: '检测选中原子的5Å空间范围内是否生成相同的原子。',
    保留片段_intro:
        '您可以输入完整的参考分子通过编辑来确定保留的片段，或者直接输入参考片段（仅支持带*的smiles）。',
    至少一个输入: '请输入蛋白或参考分子或活性分子，满足任何一个输入才能发起任务。',
    输入的smiles不满足: '输入的smiles不满足',
    '条件，对于': '条件，对于',
    只有一个片段和一个断点: '模式的smiles输入应该满足只有一个片段和一个断点',
    '有两个片段，并且每个片段都只有一个断点': '有两个片段，并且每个片段都只有一个断点',
    '只有一个骨架片段，有大于1个的断点数量': '只有一个骨架片段，有大于1个的断点数量',
    '有2个以上片段，并且每个片段只有一个断点': '有2个以上片段，并且每个片段只有一个断点',
    请输入正确的smiles: '请输入正确的smiles',
    无法使用蛋白信息: '输入片段smiles做参考分子分子生成时，无法使用蛋白信息，请修改您的输入参数。',
    不支持输入片段分子: '在De Novo时不支持输入片段分子，请修改您的输入参数。'
  },
  task: {
    上传: '上传',
    文件: '文件',
    坐标: '坐标',
    长: '长',
    宽: '宽',
    高: '高',
    确认: '确认',
    清空: '清空',
    任务中心: '任务中心',
    数据中心: '数据中心',
    管理中心: '管理中心',
    注册管理: '注册管理',
    团队管理: '团队管理',
    运行中: '运行中',
    创建任务: '创建任务',
    最近结果: '最近结果',
    输入方式: '输入方式',
    输入SMILES: '输入SMILES',
    示例: '示例',
    重置: '重置',
    绘制分子: '绘制分子',
    上传文件: '本地文件',
    csv文件: 'csv文件',
    输入方法: '输入方法',
    拖动文件到此处或点击上传: '拖动文件到此处或点击上传',
    文件大小应小于10M: '文件大小应小于10M',
    获取示例文件: '获取示例文件',
    示例文件: '示例文件',
    项目名称: '项目名称',
    任务名称: '任务名称',
    提交任务: '提交任务',
    近30天结果: '近30天结果',
    近7天结果: '近7天结果',
    近60天结果: '近60天结果',
    所有结果: '所有结果',
    项目: '项目',
    选择项目: '选择项目',
    任务类别: '任务类别',
    运行状态: '运行状态',
    批量删除: '批量删除',
    详情: '详情',
    蛋白预处理: '1. 蛋白预处理',
    配体预处理: '2. 配体预处理',
    设置对接参数: '3. 设置对接参数',
    数据库导入: '数据库导入',
    选择示例: '选择示例',
    选择方法: '选择方法',
    使用示例: '使用示例',
    当前配体: '当前配体',
    下一步: '下一步',
    自定义: '自定义',
    上一个: '上一个',
    下一个: '下一个',
    返回: '返回',
    操作: '操作',
    存在错误: '存在错误',
    前往: '前往',
    如需查看更多任务结果: '如需查看更多任务结果',
    暂无正在运行的任务: '暂无正在运行的任务',
    任务创建页面: '任务创建页面',
    下发您的第一个任务: '下发您的第一个任务',
    正在运行: '正在运行',
    自定义筛选: '自定义筛选',
    搜索: '搜索',
    运行结果: '运行结果',
    已完成: '已完成',
    选择模型类别: '选择模型类别',
    只显示前50个配体: '只显示前50个配体',
    暂无任务: '暂无任务',
    正确填充参数: '请先正确输入任务参数',
    finished: '运行结束',
    running: '运行中',
    preparing: '准备中',
    pending: '队列中',
    stopped: '暂停',
    aborted: '终止',
    预处理: '预处理',
    生成中: '生成中',
    对接中: '对接中',

    Submit: '提交任务',
    TaskName: '任务名称',
    Props: '属性',
    Target: '靶标',
    Iteration: '迭代的次数',
    GenerateNumber: '生成分子的数量',
    MinimizedRange: 'Minimized Range',
    Minimize: 'Energy Minimize',
    Protonation: 'Protonation',
    ProtonationContent: 'Add Hydrogen (pH=7.4)',
    Correct: 'Correct',
    CorrectContent: 'Correct Faulty Structure/Repair Missing Residues',
    Charge: 'Charge',
    Disconnect: 'Delete Disconnect Group (Metal Ion/salt Ion)',
    Fragment: 'Fragment',
    Hydrogen: 'Hydrogens',
    Keep: 'Keep',
    Delete: 'Delete',
    FragmentContent: 'Only Keep Largest Molecular Fragment',
    Yes: 'Yes',
    X: 'X-Coordinate',
    Y: 'Y-Coordinate',
    Z: 'Z-Coordinate',
    Length: 'Length',
    Height: 'Height',
    Width: 'Width',
    Mode: 'Docking Mode',
    AddHydrogen: 'Add Hydrogen',
    ScoreFunction: 'Scoring Function',
    ReScoreFunction: 'Rescoring',
    Pose: 'Output Pose',
    BoxCenter: 'Docking Site',
    Ligands: 'Ligands',
    切换视图: '切换视图',
    后台未知错误: '后台未知错误',
    参数错误: '请求参数错误',
    登录信息已过期: '登录信息已过期，请重新登录',
    有新的任务完成: '有新的任务完成！',
    // 运行结束: ' 运行结束，总共计算 ',
    运行结束: ' 运行结束。',
    计算分子数量: ' 个分子',
    这是必填项: '这是必填项',
    optional: '可选项',
    sme_full: '计算子结构对预测结果的影响',
    submit: '提交',
    'Upload Data': '上传数据',
    'Data Preparation': '数据批分',
    Settings: '设置',
    Washing: '数据清洗',
    Paratition: '数据批分',
    'Model Type': '模型类型',
    'Select Method': '方法',
    'Select Descriptor': '描述符',
    'Label FieId': '标签列',
    'Active Value': '正例值',
    计算描述符: '计算描述符',
    用户自定义描述符: '用户自定义描述符',
    模型训练: '模型训练',
    输入smlies: '请输入SMILES！',
    绘制分子结构: '请先绘制分子结构！',
    请上传文件: '请先上传文件！',
    超过10m: '该文件超出10M的限制，请上传其他文件！',
    无法解析: '此文件无法解析，请上传其他文件！',
    蛋白结构: '请先输入蛋白结构！',
    pdbid: '请输入正确的PDB ID ！',
    默认位点提交: '您当前使用的是系统默认提供的对接位点，确定使用该对接位点并提交任务吗？',
    自定义位点提交: '您当前使用的是自定义的对接位点，确定使用该对接位点并提交任务吗？',
    选择smiles: '请选择SMILES列！',
    选择示例文件: '请选择示例文件！',
    选择qsar任务: '请选择Inno-QSAR任务！',
    选择描述符: '请选择描述符！',
    选择标签列: '请选择标签列！',
    选择正例值: '请选择正例值！',
    选择口袋: '请选择口袋！',
    输入碳谱: '请输入碳谱！',
    上传post蛋白: '请先上传预处理后的蛋白！',
    上传post配体: '请先上传对接后的配体！',
    上传post复合物: '请先上传对接后的复合物！',
    错误信息: '错误信息：',
    错误详情: '错误详情：',
    选择建议浏览器: '为了确保您有良好的使用体验，请使用Firefox、Google、Safari、Edge浏览器！',
    抢先体验: 'DrugFlow1.0处于内测阶段，点击此处抢先体验新版本',
    申请体验:
        '为了您可以顺利体验内测版DrugFlow1.0，请你填写真实信息，工作人员将根据您填写的信息来确定您的使用资格',
    仅允许单一分子: '仅允许绘制单一分子。',
    分子不能为空: '请绘制分子。',
    请绘制分子: '请在右边分子编辑器中绘制分子结构',
    命名: '命名',
    基于默认值提交:
        '您当前使用的是默认值进行过滤，建议您根据研究的对象来调整，确认基于默认值提交任务吗？',
    步骤: '步骤',
    结构清洗: '结构清洗',
    子结构可解释性: '子结构可解释性',
    子结构解释: '开启可解释性功能，单分子详情页将提供子结构对某属性的影响，但会增加该方法的计算时间。',
    建模数据: '建模数据',
    训练集: '训练集',
    测试集: '测试集',
    获取测试集: '获取测试集',
    采样方法: '采样方法',
    建模方法: '建模方法',
    上传配体: '上传配体',
    预估计算费用: '预估计算费用',
    个人余额: '个人余额',
    计算费用提示: '预计费用仅供参考，最终费用以任务完成后实际计算为准',
    SMILES列qsar提示: "注意！两个文件中的'SMILES'列名不匹配，请在右侧表格进行编辑以实现列名一致。",
    标签列qsar提示: "注意！两个文件中的'标签'列名不匹配，请在右侧表格进行编辑以实现列名一致。",
    结构识别及数据提取: '结构提取及表格识别',
    数据提取介绍: '快速获取图中分子结构的信息',
    预览: '预览',
    上一页: '上一页',
    下一页: '下一页',
    缩小: '缩小',
    放大: '放大',
    打印: '打印',
    下载: '下载',
    选择文件: '选择文件',
    '请上传图片pdf文件': '请上传图片/pdf文件',
    '点击上传图片pdf文件': '点击上传图片/pdf文件',
    选择提取范围: '选择提取范围',
    已选: '已选',
    暂无: '暂无',
    全选: '全选',
    查看: '查看',
    请选择起码一页PDF: '请选择起码一页PDF',
    请在原文中框选分子结构或名称: '请在原文中框选分子结构或名称',
    退出: 'Quit'
  },
  result: {
    复制: '复制',
    定位: '定位',
    数量: '数量',
    序号: '序号',
    Formula: '分子式',
    Result: '结果',
    分子详情页: '分子详情页',
    分子对比页: '分子对比页',
    Table: '列表',
    Grid: '网格',
    Charts: '图表',
    聚类方法: '聚类方法',
    仅收藏: '收藏',
    仅不喜欢: '不喜欢',
    还原: '还原',
    参考配体: '参考配体',
    当前配体: '当前配体',
    对比: '对比',
    Aggregation: '聚类',
    AdvancedFilter: '高级筛选',
    DruggabilityPrediction: '成药性预测',
    MoleculeGenerate: '分子生成',
    SelectDisplay: '选择需要展示的机制',
    SelectDisplayP: '选择需要展示的属性',
    structure: '结构',
    AllMolecules: '输入数量',
    ValidMolecules: '有效数量',
    FilterTitle: '筛选查询',
    MechanismsTitle: '机制',
    RulesTitle: '规则',
    SelectALL: '全选',
    SelectNone: '不选',
    默认: '默认',
    显示隐藏: '显示/隐藏列',
    显示隐藏计算列: '显示/隐藏计算列',
    显示隐藏上传列: '显示/隐藏上传列',
    ResetSelectFilter: '重置筛选',
    BasicProps: '基础属性',
    创建您的过滤器: '创建您的过滤器',
    Import: '导入',
    模型: '模型',
    关闭: '关闭',
    保存: '保存',
    保存成功: '保存成功！',
    保存失败: '保存失败！',
    上传成功: '上传成功！',
    应用: '应用',
    范围: '范围',
    图例: '图例',
    符合建议值: '符合建议值',
    符合建议值但接近临界值: '符合建议值，但接近临界值',
    超出建议值需引起注意: '超出建议值，需引起注意',
    ligand: '配体',
    pocket: '蛋白',
    Complexes: '复合物',
    download_title: '下载提示',
    下载: '下载',
    下载部分: '下载筛选后的分子 & 性质',
    pdb下载部分: '下载筛选后的蛋白配体复合物',
    下载全部: '下载所有分子 & 性质',
    pdb下载全部: '下载所有蛋白配体复合物',
    protein下载提示: '提示：多个复合物pdb文件将打包为zip压缩包下载',
    部分打分函数: '下载筛选后的分子 & 打分函数',
    全部打分函数: '下载所有分子 & 打分函数',
    确认: '确认',
    取消: '取消',
    复合物编号: '复合物编号',
    隐藏无效分子: '隐藏无效分子',
    显示无效分子: '显示无效分子',
    保存文件: '保存文件',
    创建新任务: '创建新任务',
    请先保存数据集: '在创建新任务之前，请将需要计算的数据保存为一个新的数据集',
    取消收藏: '取消收藏',
    至少保留一列: '至少保留一列数据',
    任务名称: '任务名称',
    蛋白文件名称: '蛋白文件名称',
    配体文件名称: '配体文件名称',
    文件名称: '文件名称',
    SMILES列: 'SMILES列',
    对接方法: '对接方法',
    方法: '方法',
    输入方式: '输入方式',
    建模类型: '建模类型',
    训练集文件名称: '训练集文件名称',
    测试集文件名称: '测试集文件名称',
    标签列: '标签列',
    正例值: '正例值',
    建模方法: '建模方法',
    描述符: '描述符',
    模型参数: '模型参数',
    参考分子: '参考分子',
    生长片段: '生长片段',
    活性分子: '活性分子',
    生成方法: '生成方法',
    生成数量: '生成数量',
    生成时间: '生成时间',
    过滤条件: '2D过滤条件',
    虚筛数据: '虚筛数据',
    虚筛Step1: '虚筛Step1',
    虚筛Step2: '虚筛Step2',
    虚筛Step3: '虚筛Step3',
    蛋白预处理: '蛋白预处理',
    配体预处理: '配体预处理',
    参数详情: '任务参数详情',
    文件内容缺失: '文件内容缺失无法重新发起',
    生成类型: '生成类型',
    QSAR模型: 'QSAR Model',
    口袋: '口袋',
    加载更多: '加载更多',
    没有更多了: '没有更多了',
    共: '共',
    个分子: '个分子',
    提取范围: '提取范围'
  },
  admet_fh: {
    status: '综合评分',
    Pass: '通过筛查',
    Pass_intro: '均为绿色',
    LowRisk: '低风险',
    LowRisk_intro: '≤3个黄色',
    HighRisk: '高风险',
    HighRisk_intro: '≥3个红色',
    IntermediateRisk: '中风险',
    IntermediateRisk_intro: '≥4个黄色，或≤3个黄色且≤2个红色',
    Aggregators_name: 'Aggregates',
    'Fluc inhibitors_name': 'FLuc inhibitors',
    'Promiscuous comps_name': 'Promiscuous compounds',
    'Reactive comps_name': 'Reactive compounds',
    'Green fluorescence_name': 'Blue fluorescence',
    'Blue fluorescence_name': 'Green fluorescence',
    'Other assay_name': 'Other assay interferences',
    Aggregators_intro:
        '聚集物：指的胶体聚集化合物，是小分子在溶液中聚集形成的半径60-300 nm的聚集体，由此产生的胶体聚集物会非特异性地结合在蛋白质表面，从而诱导蛋白质局部展开，这通常会导致酶的失稳或变性。',
    'Fluc inhibitors_intro':
        'Fluc抑制剂：FLuc是一种广泛应用于各种HTS发光检测的荧光素酶，特别是研究基因转录水平表达的检测。然而，意外的Fluc抑制剂对Fluc的抑制会对HTS检测产生干扰。',
    'Promiscuous comps_intro':
        '混乱化合物：指可与多种不同的大分子靶标特异性结合的化合物。这些多重交互作用可能包括意外目标，从而引发不良反应和其他安全问题。',
    'Reactive comps_intro':
        '化学反应性化合物：可与蛋白残基发生化学反应导致蛋白的化学修饰，或与亲核测定试剂反应导致试剂结构导致化学修饰的化合物（这种情况较少发生）。',
    'Green fluorescence_intro':
        '蓝荧光化合物：可自然发出蓝色荧光的化合物，然而化合物的自然发光区与检测荧光团区重叠的化合物更容易引起干扰，例如对HTS的干扰。',
    'Blue fluorescence_intro': '绿荧光化合物：可自然发出绿色荧光的化合物，其会对HTS检测产生干扰。',
    'Other assay_intro':
        '其他检测干扰: 对alpha -screen，FRET, TR-FRET，吸光度伪影等实验产生干扰的化合物。',
    task_intro:
        'ChemFH(Chemical Frequent Hitter)模块主要用于识别出在检测试验结果中的频繁命中化合物，包括聚集物、Fluc抑制剂、混乱化合物、化学反应性化合物、蓝/绿荧光化合物、其他检测干扰化合物。这些化合物严重干扰了研究者发现潜在活性化合物，从而导致时间和资源的浪费。基于海量优质数据库的收集和Graph Neural Networks架构的应用，ChemFH具有可靠的化学频繁命中化合物的检测能力，从而提高药物研发效率。'
  },
  admet_dl: {
    carsidock_res_name: 'CarsiScore',
    carsidock_res_intro:
        '基于深度学习算法的打分函数，该值由自定义的统计势与构象合理化损失结合而来，该值越小代表生成的构象越合理。',
    carsidock_rtms_res_name: 'RTMScore',
    carsidock_rtms_res_intro:
        '基于机器学习算法的打分函数，该值是自定义的统计势，该值越大代表蛋白-配体的结合亲和力越好。',
    karmadock_res_name: 'KarmaScore',
    karmadock_res_intro:
        'KarmaDock内置的打分函数。KarmaDock应用混合密度函数学习蛋白配体间最优距离分布来评估蛋白配体的结合亲和力，KarmaScore的值越大代表蛋白-配体的结合亲和力越好。',
    basic: '基础属性',
    absorption: '吸收属性',
    distribution: '分布属性',
    metabolism: '代谢属性',
    excretion: '排泄属性',
    toxicity: '毒性属性',
    basic_toxicity: 'Basic Toxicity',
    envtoxic: 'Envtoxic',
    tox21: 'Tox21',
    Formula_name: '分子式',
    Formula_intro: '',
    Similarity_name: '相似性',
    Similarity_intro:
        '余弦相似度数值(Cosine Similarity score)，该值越大，代表该预测的结构是正确结构的可能性也就越大。',
    MW_name: 'MW',
    cluster_id_name: '聚类ID',
    LogP_name: 'LogP',
    SAS_name: 'SAscore',
    QED_name: 'QED',
    LogS_name: 'LogS',
    LogD_name: 'LogD',
    'Caco-2_name': 'Caco-2',
    'Pgp-inh_name': 'Pgp-inh',
    'Pgp-sub_name': 'Pgp-sub',
    HIA_name: 'HIA',
    'F(20%)_name': 'F(20%)',
    'F(30%)_name': 'F(30%)',
    PPB_name: 'PPB',
    VDss_name: 'VDss',
    BBB_name: 'BBB',
    BBBP_name: 'BBBP',
    Fu_name: 'Fu',
    HLM_name: 'HLMS',
    'CYP1A2-inh_name': 'CYP1A2-inh',
    'CYP1A2-sub_name': 'CYP1A2-sub',
    'CYP2A6-sub_name': 'CYP2A6-sub',
    'CYP2B6-sub_name': 'CYP2B6-sub',
    'CYP2C8-sub_name': 'CYP2C8-sub',
    'CYP2C9-inh_name': 'CYP2C9-inh',
    'CYP2C9-sub_name': 'CYP2C9-sub',
    'CYP2C19-inh_name': 'CYP2C19-inh',
    'CYP2C19-sub_name': 'CYP2C19-sub',
    'CYP2D6-inh_name': 'CYP2D6-inh',
    'CYP2D6-sub_name': 'CYP2D6-sub',
    'CYP2E1-sub_name': 'CYP2E1-sub',
    'CYP3A4-inh_name': 'CYP3A4-inh',
    'CYP3A4-sub_name': 'CYP3A4-sub',
    'HLM-cl_name': 'HLM-CL',
    'HLM-t12_name': 'HLM-T1/2',
    'MLM-cl_name': 'MLM-CL',
    'MLM-t12_name': 'MLM-T1/2',
    'RLM-cl_name': 'RLM-CL',
    'RLM-t12_name': 'RLM-T1/2',
    'UGT-sub_name': 'UGT-sub',
    CL_name: 'CL',
    T12_name: 'T1/2',
    hERG_name: 'hERG',
    'H-HT_name': 'H-HT',
    'Hepatotoxicity_name': 'Hepatotoxicity',
    'Mutagenicity_name': 'Mutagenicity',
    DILI_name: 'DILI',
    Ames_name: 'Ames',
    ROA_name: 'ROA',
    FDAMDD_name: 'FDAMDD',
    SkinSen_name: 'SkinSen',
    Carcinogenicity_name: 'Carcinogenicity',
    EC_name: 'EC',
    EI_name: 'EI',
    Respiratory_name: 'Respiratory',
    BCF_name: 'BCF',
    IGC50_name: 'IGC50',
    LC50_name: 'LC50FM',
    LC50DM_name: 'LC50DM',
    'NR-AR_name': 'NR-AR',
    'NR-AR-LBD_name': 'NR-AR-LBD',
    'NR-AhR_name': 'NR-AhR',
    'NR-Aromatase_name': 'NR-Aromatase',
    'NR-ER_name': 'NR-ER',
    'NR-ER-LBD_name': 'NR-ER-LBD',
    'NR-PPAR-gamma_name': 'NR-PPAR-gamma',
    'SR-ARE_name': 'SR-ARE',
    'SR-ATAD5_name': 'SR-ATAD5',
    'SR-HSE_name': 'SR-HSE',
    'SR-MMP_name': 'SR-MMP',
    'SR-p53_name': 'SR-p53',
    TPSA_name: 'TPSA',
    'Stereo Centers_name': 'Stereo Centers',
    Flexibility_name: 'Flexibility',
    nRig_name: 'nRig',
    fChar_name: 'fChar',
    nHet_name: 'nHet',
    MaxRing_name: 'MaxRing',
    nRing_name: 'nRing',
    nRot_name: 'nRot',
    nHD_name: 'nHD',
    nHA_name: 'nHA',
    Density_name: 'Density',
    Volume_name: 'Volume',
    SAscore_name: 'SAscore',
    Fsp3_name: 'Fsp3',
    'MCE-18_name': 'MCE-18',
    NPscore_name: 'NPscore',
    MDCK_name: 'MDCK',
    MW_intro: '包含氢原子的分子质量。最优为:100~600。',
    Volume_intro: '范德华体积',
    Density_intro: '密度=分子质量/范德华体积。',
    nHA_intro: '氢键受体的数量。最优为:0~12',
    nHD_intro: '氢键供体的数量。最优为:0~7',
    nRot_intro: '可旋转键的数量。最优为:0~11',
    nRing_intro: '环的数量。最优为:0~6',
    MaxRing_intro: '最大环中原子的数量。最优为:0~18',
    nHet_intro: '杂原子的数量。最优为:1~15',
    fChar_intro: '全称为Formal charge, 此处指形式电荷的数量。最优为:-4 ~4。',
    nRig_intro: '刚性键的数量。最优为:0~30',
    Flexibility_intro: '灵活性 = 可旋转键的数量/环的数量',
    'Stereo Centers_intro': '最优为: ≤ 2',
    TPSA_intro: '全称为Topological Polar Surface Area，指拓扑极性表面积。最优为: 0~140',
    LogS_intro: '水溶液溶解度的对数。最优为: -4~0.5 log mol/L',
    LogP_intro: '辛醇/水分配系数的对数。最优为: 0~3',
    LogD_intro: '生理pH 7.4时的logP值。最优为: 1~3',
    QED_intro:
        '一个基于可取性理念的类药性指标；有吸引力的：> 0.67；无吸引力的：0.49~0.67； 太复杂：< 0.34。',
    SAS_intro:
        '全称为Synthetic accessibility score, 是一个评估类药分子合成难易程度的指标；SAscore ≥ 6, 难以合成; SAscore <6, 容易合成。',
    SAscore_intro:
        '全称为Synthetic accessibility score, 是一个评估类药分子合成难易程度的指标；SAscore ≥ 6, 难以合成; SAscore <6, 容易合成。',
    Fsp3_intro: 'Fsp³杂化碳数/总碳数，该值与熔点和溶解度相关；Fsp³≥0.42 比较合适',
    'MCE-18_intro': 'MCE-18代表药物化学进化；MCE-18≥45 比较合适。',
    NPscore_intro:
        '全称为Natural product-likeness score，是类天然产物的评分指标； 这个分数通常在−5到5之间，分数越高，分子属于天然产物的概率越高。',
    PAINS_intro:
        '全称为Pan Assay Interference Compounds, 包括了频繁命中化合物，Alpha-screen artifacts和化学反应性化合物。',
    'ALARM NMR_intro': '会导致NMR警告的硫醇反应性化合物。',
    BMS_intro: '由BMS公司发现的不理想的、化学反应性化合物。',
    'Chelator Rule_intro': '螯合物',
    'Caco-2_intro': 'Caco-2细胞透膜性；表观渗透系数(Papp)，单位是以对数(log10(cm/s))进行测量。吸收不良(吸收率0%~20%)：<-6；吸收中等(20%~70%)：-6~-5；吸收良好(70%~100%)：>-5.',
    MDCK_intro:'MDCK细胞透膜性；表观渗透系数(Papp)，单位是以对数(log10(cm/s))进行测量。吸收不良(吸收率0%~20%)：<-6；吸收中等(20%~70%)：-6~-5；吸收良好(70%~100%)：>-5.',
    'Pgp-inh_intro': 'Category 1: 抑制剂; Category 0: 非抑制剂； 输出值为pgp-抑制剂的概率。',
    'Pgp-sub_intro': 'Category 1: 底物; Category 0: 非底物；输出值为pgp-底物的概率。',
    HIA_intro:
        '全称为Human Intestinal Absorption，指人类肠道吸收值。Category 1: HIA+( HIA ≥ 30%); Category 0: HIA-( HIA < 30%);  输出值为HIA+的概率。',
    'F(20%)_intro':
        '20%生物利用度；Category 1: F20%+ (生物利用度 ≥ 20%); Category 0:F20%- (生物利用度 < 20%);  输出值为F20%+的概率。',
    'F(30%)_intro':
        '30%生物利用度；Category 1: F30%+ (生物利用度 < 30%); Category 0:F30%- (生物利用度 ≥ 30%);  输出值为F30%+的概率。',
    PPB_intro:
        '全称为Plasma Protein Binding(%)，指血浆蛋白结合率；最优: < 90%. 蛋白结合度高的药物，治疗指数可能较低。',
    VDss_intro: '全称为Volume Distribution，指体积的分布；最优: 0.04-20L/kg。',
    BBB_intro:
        '全称为Blood-Brain Barrier Penetration，指血脑屏障穿透的能力； Category 1: BBB+，Category 0: BBB-， 输出值为BBB+的概率。',
    BBBP_intro:
        '全称为Blood-Brain Barrier Penetration，指血脑屏障穿透的能力； Category 1: BBB+( logBB > -1 )，Category 0: BBB-( logBB≤-1 )， 输出值为BBB+的概率。',
    Fu_intro: ' 指血浆中未结合的部分； 低: 0.05，中等: 0.05~0.2，高: > 0.2。',
    'CYP1A2-inh_intro': 'Category 1: 抑制剂， Category 0: 非抑制剂； 输出值为抑制剂的概率。',
    'CYP1A2-sub_intro': 'Category 1: 底物， Category 0: 非底物； 输出值为底物的概率。',

    'CYP2A6-sub_intro': 'Category 1: 底物， Category 0: 非底物； 输出值为底物的概率。',
    'CYP2B6-sub_intro': 'Category 1: 底物， Category 0: 非底物； 输出值为底物的概率。',
    'CYP2C8-sub_intro': 'Category 1: 底物， Category 0: 非底物； 输出值为底物的概率。',

    'CYP2C9-inh_intro': 'Category 1: 抑制剂， Category 0: 非抑制剂； 输出值为抑制剂的概率。',
    'CYP2C9-sub_intro': 'Category 1: 底物， Category 0: 非底物； 输出值为底物的概率。',
    'CYP2C19-inh_intro': 'Category 1: 抑制剂， Category 0: 非抑制剂； 输出值为抑制剂的概率。',
    'CYP2C19-sub_intro': 'Category 1: 底物， Category 0: 非底物； 输出值为底物的概率。',

    'CYP2E1-sub_intro': 'Category 1: 底物， Category 0: 非底物； 输出值为底物的概率。',

    'CYP2D6-inh_intro': 'Category 1: 抑制剂， Category 0: 非抑制剂； 输出值为抑制剂的概率。',
    'CYP2D6-sub_intro': 'Category 1: 底物， Category 0: 非底物； 输出值为底物的概率。',
    'CYP3A4-inh_intro': 'Category 1: 抑制剂， Category 0: 非抑制剂； 输出值为抑制剂的概率。',
    'CYP3A4-sub_intro': 'Category 1: 底物， Category 0: 非底物； 输出值为底物的概率。',
    'HLM-cl_intro': '基于人肝微粒体(HLM)酶的清除率(CL)，设定阈值为20 ml/min/g，Category 1：不稳定，清除率CL ≥ 20，Category 0: 稳定，清除率CL < 20。',
    'HLM-t12_intro': '基于人肝微粒体（HLM）酶的半衰期（T1/2），设定阈值为0.5小时，Category 1：不稳定，半衰期 ≤ 0.5；Category 0：稳定，半衰期 > 0.5。',
    'MLM-cl_intro': '基于小鼠微粒体(MLM)酶的清除率(CL)，设定阈值为20 ml/min/g，Category 1：不稳定，清除率CL ≥ 20，Category 0: 稳定，清除率CL < 20。',
    'MLM-t12_intro': '基于小鼠微粒体（MLM）酶的半衰期（T1/2），设定阈值为0.5小时，Category 1：不稳定，半衰期 ≤ 0.5；Category 0：稳定，半衰期 > 0.5。',
    'RLM-cl_intro': '基于大鼠微粒体(RLM)酶的清除率(CL)，设定阈值为20 ml/min/g，Category 1：不稳定，清除率CL ≥ 20，Category 0: 稳定，清除率CL < 20。',
    'RLM-t12_intro': '基于大鼠微粒体（RLM）酶的半衰期（T1/2），设定阈值为0.5小时，Category 1：不稳定，半衰期 ≤ 0.5；Category 0：稳定，半衰期 > 0.5。',
    'UGT-sub_intro': 'UDP-Glucuronosyl Transferases底物，Category 1：底物；Category 0：非底物； 输出值为底物的概率。',
    'Hepatotoxicity_intro': '指肝毒性； Category 1: 有肝毒性，Category 0: 无肝毒性；输出值为有肝毒性的概率。',
    'Mutagenicity_intro': '该毒性可通过AMES试验进行验证。Category 1: Mutagenicity阳性(+)， Category 0: Mutagenicity阴性(-)； 输出值为Mutagenicity阳性(+)的概率。',
    HLM_intro:
        '人肝微粒体稳定性(Human Liver Microsomal Stability, MLMS)，在人肝微粒体 (Human Liver Microsomal, HLM) 中稳定的概率(t1/2>30 分钟)，如果该值接近 1，则化合物极有可能在 HLM 中稳定。',
    CL_intro:
        '终端半衰期(T1/2)，以小时计。 Category 1: T1/2+，T1/2 ≤ 3; Category 0: T1/2-，T1/2 > 3；输出值是T1/2+的概率。',
    T12_intro:
        '指终末半衰期，Category 1:长半衰期(T1/2 ≤ 3h)， Category 0: 短半衰期(T1/2 >3h)； 输出值为长半衰期的概率。',
    hERG_intro: 'Category 1: 活性，Category 0: 无活性； 输出值为活性的概率。',
    'H-HT_intro':
        '指肝毒性； Category 1: 有肝毒性，Category 0: 无肝毒性；输出值为有肝毒性的概率。',
    DILI_intro:
        '全称为Drug Induced Liver Injury，指药物性肝损伤；Category 1: DILI高风险药物，Category 0: DILI低风险药物；输出值为有毒的概率。',
    Ames_intro: ' Category 1: Ames阳性(+)， Category 0: Ames阴性(-)； 输出值为Ames阳性(+)的概率。',
    ROA_intro: 'Category 0: 低毒性，Category 1: 高毒性；输出值为有高毒性的概率。',
    FDAMDD_intro:
        '每日最大推荐剂量；Category 1: FDAMDD (+)， Category 0: FDAMDD (-)；输出值为FDAMDD (+)的概率。',
    SkinSen_intro: 'Category 1: 敏化剂， Category 0: 非敏化剂；输出值为敏化剂的概率。',
    Carcinogenicity_intro: 'Category 1: 致癌物， Category 0: 非致癌物；输出值为有致癌毒性的概率。',
    EC_intro: 'Category 1: 腐蚀物， Category 0: 非腐蚀物； 输出值为具有眼腐蚀毒性的概率。',
    EI_intro: 'Category 1: 刺激物， Category 0: 非刺激物； 输出值为具有眼睛刺激性的概率。',
    Respiratory_intro:
        'Category 1: 呼吸毒性， Category 0: 无呼吸毒性； 输出值为具有呼吸毒性的概率。',
    BCF_intro:
        '生物浓度因子用于考虑二次中毒的可能性，并通过食物链评估对人类健康的风险； 单位是−log10[(mg/L)/(1000*MW)]',
    IGC50_intro: '四膜虫50%的生长抑制浓度；单位是−log10[(mg/L)/(1000*MW)]',
    LC50_intro: '96小时的黑头小鲦鱼50%的致命浓度；单位是−log10[(mg/L)/(1000*MW)]',
    LC50DM_intro: '48小时大型蚤50%致死浓度；单位是−log10[(mg/L)/(1000*MW)]',
    'NR-AR_intro': ' 雄激素受体；Category 1: 活性，Category 0: 非活性；输出值为活性的概率。',
    'NR-AR-LBD_intro':
        '雄激素受体配体结合域；Category 1: 活性， Category 0: 非活性； 输出值为活性的概率。',
    'NR-AhR_intro': '芳烃受体；Category 1: 活性， Category 0: 非活性；输出值为活性的概率。',
    'NR-Aromatase_intro': 'Category 1: 活性， Category 0: 非活性；输出值为活性的概率。',
    'NR-ER_intro': '雌激素受体；Category 1: 活性，Category 0: 非活性；输出值为活性的概率。',
    'NR-ER-LBD_intro':
        '雌激素受体配体结合域； Category 1: 活性，Category 0: 非活性；输出值为活性的概率。',
    'NR-PPAR-gamma_intro':
        '过氧化酶增殖因子活化受体； Category 1: 活性，Category 0: 非活性； 输出值为活性的概率。',
    'SR-ARE_intro': '抗氧化反应元件；Category 1: 活性，Category 0: 非活性； 输出值为活性的概率。',
    'SR-ATAD5_intro':
        'ATPase 家族 AAA 结构域蛋白 5；Category 1: 活性，Category 0: 非活性；输出值为活性的概率。',
    'SR-HSE_intro': ' 热激因子响应元件；Category 1: 活性，Category 0: 非活性；输出值为活性的概率。',
    'SR-MMP_intro': '线粒体膜电位；Category 1: 活性， Category 0: 非活性；输出值为活性的概率。',
    'SR-p53_intro': 'Category 1: 活性， Category 0: inactives；输出值为活性的概率。',
    Lipinski_name: 'Lipinski',
    Lipinski_intro: 'Lipinski',
    'Veber Rule_name': 'Veber Rule',
    'Veber Rule_intro': 'nRot≤10，且TPSA≤140或nHBD+nHBA≤12的化合物具有更好的口服生物利用度',
    'Lipinski Rule_name': 'Lipinski Rule',
    'Lipinski Rule_intro':
        'MW ≤ 500; logP ≤ 5; Hacc ≤ 10; Hdon ≤ 5。如有两个性质超出范围，可能会导致吸收或渗透性差，只有一个超出范围是可以接受的。',
    'Pfizer Rule_name': 'Pfizer Rule',
    'Pfizer Rule_intro': 'logP (>3)和TPSA (<75) 的化合物可能具有毒性。',
    'GSK Rule_name': 'GSK Rule',
    'GSK Rule_intro': 'MW ≤ 400; logP ≤ 4的化合物成药性更高。',
    'Oprea Rule_name': 'Oprea Rule',
    'Oprea Rule_intro': 'nRing ≥ 3; nRig ≥ 18的化合物类药性更高。',
    'Golden Triangle Rule_name': 'Golden Triangle',
    'Golden Triangle Rule_intro': '200 ≤ MW ≤ 50; -2 ≤ logD ≤ 5的化合物类药性更高。',

    'Physicochemical-Property': '物化性质',
    'Medicinal-Chemistry': '药化性质',
    'Drug-Likeness': '类药性',
    Absorption: '吸收',
    Distribution: '分布',
    Metabolism: '代谢',
    Excretion: '排泄',
    'Toxicity-Property': '毒性',
    smiles_placeholder: '输入多行SMILES',
    task_intro:
        'Inno-ADMET模块主要用于系统的评价药物的吸收、分布、代谢、排泄和毒性性质，也包括一些理化性质和药物化学性质。系统提供了两个不同的模型来计算成药性性质，分别为基于预训练的MERT模型和基于GNN子结构可解释性的MGA模型。基于自主收集的高质量数据，这两个模型均可实现17种物化性质、5种药物化学性质、6种成药性规则、21种ADME性质、27种毒性性质的系统性评价。从模型评估指标的角度来说，MERT模型更为优秀，根据模型能否提供子结构对预测结果的影响上判断，MGA模型更具优势，用户可根据自己的需要去选择相应的计算模型。'
  },
  admet_sa: {
    'Acute Toxicity_intro': '20个子结构；口服时出现急性毒性。',
    'Genotoxic Carcinogenicity_intro': '117个子结构；致癌性或致突变性。',
    'NonGenotoxic Carcinogenicity_intro': '23个子结构； 非基因毒性机制的致癌性。',
    'Skin Sensitization_intro': '155个子结构；皮肤刺激。',
    'Aquatic Toxicity_intro': '99个子结构；毒性液体(水)。',
    NonBioDegradable_intro: '19个子结构； 生物不可降解。',
    SureChEMBL_intro: '164个子结构；MedChem不友好状态。',
    'FAF-Drugs4_intro': '从FAF-Drugs4 web服务器中收集到154个子结构,含有这些子结构的分子可能有毒。',
    'FH Alerts_intro': '具有ChemFH子结构特征的化合物。',

    'Acute Toxicity_name': 'Acute Toxicity',
    'Genotoxic Carcinogenicity_name': 'Genotoxic',
    'NonGenotoxic Carcinogenicity_name': 'NonGenotoxic',
    'Skin Sensitization_name': 'Skin Sensitization',
    'Aquatic Toxicity_name': 'Aquatic Toxicity',
    NonBioDegradable_name: 'NonBioDegradable',
    SureChEMBL_name: 'SureChEMBL',
    'FAF-Drugs4_name': 'FAF-Drugs4',
    'FH Alerts_name': 'FH Alerts',
    RMSD_intro:
        '评估参考分子和生成分子之间相同子结构的构象差异，RMSD为0-1之间的值，该值越小代表构象差异越小。',
    site_check_intro:
        '基于参考分子选择的原子，去检测生成分子在该原子3Å的空间范围内有没有生成相同的原子，有则为true，无则为false。',
    cluster_id: '聚类ID',
    algo: '算法',
    similarity_intro:
        '以ECFP4为描述符，Tc为评估指标，比较生成的分子与输入的参考分子比较相似性。Tc为0-1之间的值，该值越接近1代表相似性越高。',
    task_intro:
        'Inno-SA模块主要用于预测分子中是否存在毒性子结构，包括急性毒性、遗传毒性致癌性、非遗传毒性致癌性、皮肤致敏性、水生毒性、不可生物降解、SureChEMBL毒性、FAF-Drugs4毒性，和造成化合物频繁命中子结构的警告。这些子结构是从大量的实验活性数据中总结出来的造成这些不良反应的高频子结构，Structure Alert模块可以准确的识别出危险的子结构，帮助用户更加清晰的认识分子，通过警告来让用户对该部分子结构引起重视。'
  },
  relation: {
    task_intro:
        'RELATION(REceptor-LigAnd interacTION)模块是一个基于蛋白口袋-配体复合物3D生成和双向迁移学习结合的分子生成方法，能够生成大量结构有效并且对蛋白具有一定结合亲和力的分子。当前的版本中，平台预先训练了32个靶点，用户只能生成这32个靶点下的新分子。后续我们将开放用户自定义的模型，用户可以自定义靶点进行分子生成，敬请期待。'
  },
  rescoring: {
    task_intro:
        'Inno-Rescoring模块提供了一些基于机器学习算法的优秀打分函数。为了方便用户可以更直接的使用我们的重打分函数，Rescoring模块支持用户在本地对接后上传相关的文件进行重打分(目前集成了RTMScore，后续我们将发布更多的重打分函数)，用户可以选择这些重打分函数重新评估蛋白质-配体结合姿势的结合亲和力。'
  },
  screen: {
    显示内容: '显示内容',
    白色背景: '白色背景',
    黑色背景: '黑色背景',
    显示水分子: '显示水分子',
    口袋透明度: '口袋透明度',
    蛋白显示方式: '蛋白显示方式',
    配体显示方式: '配体显示方式',
    更多操作: '更多操作',
    回到中心: '回到中心',
    显示配体: '显示配体',
    显示口袋: '显示口袋',
    截图: '截图',
    全屏: '全屏',
    选择配体: '选择配体',
    请检查输入和网络连接: '抱歉，请检查输入和网络连接!',
    柱状分布图: '柱状分布图',
    统计饼图: '统计饼图',
    结合残基: '结合残基',
    亲和力: '亲和力',
    结果列表: '结果列表',
    数据选择: '数据选择：',
    打分函数: '打分函数：',
    上传: '点击上传 pdb 文件',
    上传配体: '点击上传 mol2/csv/sdf 文件',
    上传csvsdf: '点击上传 csv/sdf 文件',
    请选择csvsdf文件: 'Please select csv/sdf file',
    对接类型: '对接类型',
    柔性对接: '柔性对接',
    半柔性对接: '半柔性对接',
    post蛋白: '预处理后蛋白',
    上传post蛋白: '点击上传 pdb 文件',
    post蛋白示例: '蛋白示例文件',
    post配体: '对接后配体',
    上传post配体: '点击上传 sdf 文件',
    post配体示例: '配体示例文件',
    post_zip: '对接后复合物',
    上传post_zip: '点击上传 pdb 文件或 zip 包',
    post_zip示例: '复合物示例',
    upload_zip_tip: '需上传单个蛋白-配体复合物的 pdb 文件或包含多个 pdb 文件的 zip 包',
    重打分函数: '重打分函数',
    蛋白配体文件: '蛋白和配体文件',
    pdb复合物: '复合物',
    IGN_name: 'IGN',
    RTMS_name: 'RTMScore',
    IGN_intro: '基于机器学习算法的打分函数，该值由ki/kd而来，该值越大代表蛋白-配体的结合亲和力越好',
    RTMS_intro:
        '基于机器学习算法的打分函数，该值是自定义的统计势，该值越大代表蛋白-配体的结合亲和力越好',
    task_intro:
        'Inno-Docking模块主要用于评估蛋白-配体的结合构象和结合亲和力。Inno-Docking不仅集成了经典的物理对接程序AutoDock Vina，还集成了自研的AI 对接程序CarsiDock。相对物理方法，CarsiDock在对接构象精度上有显著优势。此外，Inno-Docking还提供了完整的蛋白预处理、配体预处理和自动智能化设置对接参数能力，且在结果页面提供了详细的数据分析能力。',
    提醒正在使用默认口袋: '您当前使用的是系统默认提供的对接位点，确定使用该对接位点并提交任务吗？',
    提醒正在使用自定义口袋: '您当前使用的是自定义的对接位点，确定使用该对接位点并提交任务吗？',
    请输入正确PDBID: '请输入正确的PDB ID ！',
    文件大小不能超过10M: '该文件超出10M的限制，请上传其他文件！',
    文件大小不能超过100M: '该文件超出100M的限制，请上传其他文件！',
    文件大小不能超过1GB: '该文件超出1GB的限制，请上传其他文件！',
    文件数量不能超过5000条: '该文件超出5000条，请上传其他文件！',
    文件数量不能超过10000条: '该文件超出10000条，请上传其他文件！',
    文件数量不能超过20000条: '该文件超出20000条，请上传其他文件！',
    文件不能超过256列: '文件不能超过256列，请上传其他文件！',
    请上传配体文件: '请上传配体文件！',
    请输入PDBID或者选择示例: '请输入PDB ID或者选择示例！',
    请上传蛋白pdb文件: '请先输入蛋白结构！',
    必须拥有手性: '提示：为得到更准确的结果，请为您的配体生成一定数量的异构体(尤其是手性异构)。',
    缺少原始配体: '你输入的结构缺乏原始配体，建议您选择Vina进行后续的计算。',
    运行模式: '运行模式',
    数据中心导入pdb: '点击选择 pdb 文件',
    数据中心导入csvsdf: '点击选择 csv/sdf 文件',
    数据中心导入sdf: '点击选择 sdf 文件',
    数据中心导入pdf: '点击选择 图片/pdf 文件',
    选择打分函数: '请选择打分函数',
    选择数据: '请选择数据',
    请选择蛋白pdb文件: '请选择蛋白文件',
    参数不能为空: '参数不能为空',
    没有配体: '您上传的pdb文件中没有配体，请上传蛋白-配体复合物',
    设置自定义口袋: '请设置自定义口袋',
    只能上传csvsdfpdb: 'The file type should be a .csv/.sdf/.pdb file.',
    只能上传csvsdf: 'The file type should be a .csv/.sdf file.',
    只能上传sdf: 'The file type should be a .sdf file.',
    只能上传pdb: 'The file type should be a .pdb file.',
    请删除不需要的链以减少计算时间: '请删除不需要的链以减少计算时间',
    继续: '继续',
    修改: '修改',
    警告: '警告',
    解析提示: '提示：解析该分子片段失败，无法提供子结构贡献度',
    至少选择一条蛋白链: '至少选择一条蛋白链'
  },
  screen_table: {
    数据: '数据选择',
    打分函数: '打分函数',
    Vina: 'Autodock vina软件中的打分函数之一，单位为kcal/mol，该值越小代表蛋白-配体的结合亲和力越好',
    'AutoDock 4':
        'Autodock vina软件中的打分函数之一，单位为kcal/mol，该值越小代表蛋白-配体的结合亲和力越好。',
    Plp95: '该值越大代表蛋白-配体的结合亲和力越好。',
    ChemPlp: '该值越大代表蛋白-配体的结合亲和力越好。',
    Plp: '该值越大代表蛋白-配体的结合亲和力越好。',
    Vinardo:
        'Autodock vina软件中的打分函数之一，单位为kcal/mol，该值越小代表蛋白-配体的结合亲和力越好',
    PLP: '该值越大代表蛋白-配体的结合亲和力越好',
    CarsiScore:
        '基于深度学习算法的打分函数，该值由自定义的统计势与构象合理化损失结合而来，该值越小代表生成的构象越合理。',
    KarmaScore:
        'KarmaDock内置的打分函数。KarmaDock应用混合密度函数学习蛋白配体间最优距离分布来评估蛋白配体的结合亲和力，KarmaScore的值越大代表蛋白-配体的结合亲和力越好。',
    IGN: '基于机器学习算法的打分函数，该值由ki/kd而来，该值越大代表蛋白-配体的结合亲和力越好',
    RTMScore:
        '基于机器学习算法的打分函数，该值是自定义的统计势，该值越大代表蛋白-配体的结合亲和力越好',
    残基统计:
        '为方便用户更直观的了解蛋白-配体的相互作用模式，我们统计了与配体发生相互作用的残基数量，并用前10个结合频率高的残基绘制了该饼图。',
    ID_intro:
        '第1个数字代表用户的输入顺序，第二个数字代表生成的异构体，第三个数字代表每个分子输出的构象。'
  },
  lig_prep: {
    task_intro:
        'Ligand Preparation模块是一个常规的分子处理工具。对于上传的分子，平台首先测试是否存在原子价异常, 若存在，则将其多余的氢去除；之后会默认会对分子进行标准化，包括断开金属离子/盐离子、获取最大片段(移除其他片段) 、加氢；一些可选项为：调整质子化状态，是否生成互变异构体、立体异构体。经该模块处理后的分子，可用于平台的QSAR，Docking等模块。'
  },
  spec: {
    解谱方法: '解谱方法',
    输入碳谱: '输入碳谱',
    send: '传入SMILES框',
    分子式: '输入分子式',
    数字输入不正确: '数字输入不正确',
    末尾不允许输入逗号: '末尾不允许输入逗号',
    不允许输入特殊字符: '不允许输入数字、空格和英文逗号外的字符',
    分子式placeholder: '输入已确定的分子式',
    smiles_placeholder: '输入已确定的分子片段SMILES',
    smiles_title: '输入已确定的分子片段SMILES',
    绘制的SMILES: '绘制的SMILES',
    spectrum_title: 'AI解谱(NMR)',
    spectrum_CRESS:'AI解谱(NMR)-CRESS',
    spectrum_CMGnet:'AI解谱(NMR)-CMGnet',
    task_intro:
        '基于核磁共振(NMR)方法求解未知化合物的结构是化学科学中一个长期存在的逆问题。发现新的解决结构解析逆问题的方法将有助于研究人员加速化学发现，尤其对于有机化学及天然产物化学的研究具有重要意义。AI 解谱模块主要在于应用 AI 技术来快速解析碳谱，帮助专家识别分子结构。在此，我们提供了两种不同的方式 CReSS 和 CMGNet 来基于碳谱解析未知化合物的结构。'
  },
  mcmg: {
    task_intro:
        'MCMG Training 是基于MCMG(Multi-Constraint Molecular Generation)方法提供的自定义建模模块。MCMG是一个基于配体的生成方法，它通过知识蒸馏结合条件变换器和基于QSAR的强化学习算法来满足多个约束条件，可以生成具有所需理化和药理学特性的新分子。如果用户想生成某个靶点的活性分子，需要上传该靶点下有活性数据的分子，并且设置生成分子的性质限制，结果页面将提供模型的评估结果，以确定该模型生成分子的可靠性。如需应用构建的MCMG模型生成分子，请跳转分子生成的“Ligand-based”模块。'
  },
  qsar: {
    只支持二分类: '注意！目前系统只支持构建二分类模型，请确保您的标签列只有两个唯一值。',
    基于描述符: '基于描述符',
    基于结构: '基于结构',
    模型参数: '建模参数',
    '文件内应包含SMILES、活性值': '文件内应包含SMILES、活性值',
    描述符: '选择描述符',
    模型类型: '选择模型类别',
    SMILES列: '选择SMILES列',
    smiles列: 'SMILES列',
    标签列: '选择标签列',
    正例值: '选择正例值',
    点击上传csv文件: '点击上传 csv 文件',
    csv示例: 'csv示例',
    文件内容: '文件内容',
    数据概览: '数据概览',
    模型应用: '模型应用',
    选择任务: '选择创建的QSAR模型',
    '数据分布-分类': '数据分布-分类',
    '数据分布-回归': '数据分布-回归',
    碳骨架分析: '骨架分析',
    '结果详情-分类': '结果分析-分类',
    模型: '模型',
    指标: '指标',
    准确率: '准确率',
    精确率: '精确率',
    召回率: '召回率',
    以: '以',
    为正例值: '为正例值',
    真阳性率: '真阳性率',
    假阳性率: '假阳性率',
    测试文件: '测试文件',
    选择模型: '选择模型',
    小型数据测试:
        '仅适用于小型数据测试(仅输出前100条数据)，且系统不会保留您的测试结果，如需使用大型数据进行预测或保留预测结果，请跳转',
    QSAR模型应用: 'QSAR模型应用',
    模块: '模块。',
    开始测试: '开始测试',
    分类模型预测: '分类模型预测',
    MCC_intro:
        '马修斯相关系数(Matthews Correlation Coefficient)，是一个衡量二元变量之间的相关性的指标。它是一个介于-1与+1之间的值，其中+1代表完全一致，0代表随机一致，-1代表完全不一致。对于类别不平衡的数据集、小样本数据集，其表现也往往比准确率、精准率、召回率等常见的分类性能指标更好。',
    准确率_intro:
        'Accuracy = (TP + TN) /(TP + TN+FP+FN)，为总样本中正确预测样本类别的比例,  其取值在0和1之间，该值越高模型性能越好。',
    'ROC-AUC_intro':
        'ROC曲线越靠近左上角，曲线下面积(Area under the curve, AUC)越大，代表模型性能越好。若AUC=0.5，即ROC曲线与上图中的虚线重合，表示模型的区分能力与随机猜测没有差别。',
    'PRC-AUC_intro': 'PRC曲线越靠近右上角，AUC越大，模型性能越好。',
    'F1-Score_intro':
        'F1-Score = 2 ∗ Precision ∗ Recall / (Precision + Recall)，F1-Score为精确率和召回率的调和值。其取值范围为0-1，该值越大模型性能越好。',
    精确率_intro:
        'Precision= TP/ (TP+FP)，在所有预测为阳性的样本中，真阳性样本所占的比例。其取值范围为0-1，该值越大模型性能越好。',
    召回率_intro:
        'Recall =TP/ (TP + FN)，也叫真阳性率，指在所有实际为正类的样本中，真阳性样本所占的比例。其取值范围为0-1，该值越大模型性能越好。',
    '数据划分-分类': '训练集：测试集=4:1(依据：Label）',
    '数据划分-分类_intro':
        '为确保测试集和训练集中的化合物具有相似的化学空间，将分别对阳性(1或活性)和阴性(1或活性)集的分子进行骨架分析，按照骨架进行4-1 批分(当相同骨架的分子少于 2 时，把该分子归为训练集)，最终合并正集和负集批分后的数据集做为训练集和测试集',
    '数据划分-回归': '训练集：测试基=4:1(依据：分子骨架）',
    '数据划分-回归_intro':
        '为确保测试集和训练集中的化合物具有相似的化学空间，基于骨架分析的结果，按照骨架进行4-1 批分训练集和测试集(当相同骨架的分子少于 2 时，把该分子归为训练集)',
    '算法-分类':
        '对平台目前的两个ML算法(RF和XGBoost)，应用“网格搜索”+“5-折交互检验”的组合方法来确定最优参数，根据AUC_ROC来确定最优算法，最终应用最优算法和最优参数来建模。）',
    '算法-回归':
        '对平台目前的两个ML算法(RF和XGBoost)，应用“网格搜索”+“5-折交互检验”的组合方法来确定最优参数，根据R2来确定最优算法，最终应用最优算法和最优参数来建模。',
    模型可解释性: '模型可解释性',
    模型可解释性_intro:
        '此图为重要性排序图。与传统的feature importance不同，SHAP通过SHAP Value能反映出每一个样本中的特征的影响力，还能显示影响的正负性。',
    算法: '算法',
    描述符号: '描述符',
    建模参数: '建模算法',
    数据划分方法: '数据划分方法',
    结果详情: '结果详情',
    回归模型预测: '回归模型预测',
    真实值: '真实值',
    预测值: '预测值',
    MAE_intro: '绝对误差(Mean Absolute Error,MAE)，可以更好地反映预测值误差的实际情况。',
    MSE_intro: '均方误差(Mean Squared Error,MSE)，用来衡量预测值和真实值的差异。',
    RMSE_intro:
        '均方根误差(Root Mean Square Error,RMSE)，即均方误差开根号，该值代表预测的值和观察到的值之差的样本标准差。',
    'R²_intro': '决定系数，反映模型拟合数据的准确程度，R2越接近1，表明模型对数据拟合的越好。',
    PCCs_intro:
        '皮尔逊相关系数(Pearson Correlation Coefficient, PCCs)，用来衡量两个变量线性相关程度的一种方法，相关系数的取值范围是[-1,1]。相关系数的绝对值越大，则表明相关度越高。',
    '结果详情-回归': '结果详情-回归',
    文件内应包含SMILES: '文件内应包含SMILES',
    模型的类型: '模型的类型',
    模型详情: '模型详情',
    评估结果: '评估结果',
    分类模型: '分类模型',
    回归模型: '回归模型',
    task_intro:
        'Inno-QSAR模块主要用于帮助用户基于自有数据构建自定义的分类/回归模型。平台不仅支持XGBoost、随机森林等传统基于描述符表征的机器学习方法，同时还提供了基于GNN、Transformer等深度学习方法的预训练模型，可以在一定程度上帮助用户提升小数据情况下的建模效果。',
    application_task_intro:
        'Inno-QSAR Application是基于Inno-QSAR衍生的模块，用户在Inno-QSAR中构建的模型，可以在Inno-QSAR Application模块应用创建的模型对新的数据进行预测，用户只需要选择/上传数据，选择模型，就可以得到该模型的预测结果。',
    '分类模型-预测的标签饼图': '分类模型-预测的标签饼图',
    '回归模型-预测概率值的柱状分布图': '回归模型-预测概率值的柱状分布图',
    模型类: '模型类型',
    roc曲线: 'ROC曲线',
    'p-r曲线': 'P-R 曲线',
    真实值与预测值的相关性: '真实值与预测值的相关性',
    纯碳链骨架: '纯碳链骨架',
    roc曲线_intro:
        '受试者工作特征(Receiver operating characteristic, ROC)曲线是以“真阳性率”为y轴，以“假阳性率”为x轴，取不同的分类阈值，在此基础上画出来的一条曲线。',
    'p-r曲线_intro':
        'PR曲线是以Precision为y轴，以Recall为x轴，取不同的分类阈值，在此基础上画出来的一条曲线。与ROC不同的是，Precision受样本不平衡的影响，相应的PRC也会因此形状变化。因此，在样本数据量比较大时，ROC会比较稳定，一般选择ROC-AUC来评价模型是较为合适的。而当阈值确定时，Precision、Recall、F1-score都可以用来评价模型。',
    epochs_tr:
        '该值的可调范围为1-50，根据数据集大小视情况设定，默认为10。该值越大训练时间越久，并且有过拟合的风险。',
    batch_tr:
        '该值的可调数值为16、32、64、128，默认为32。该值越大训练速度越快，但是过大的batchsize会导致梯度多样性变少，无法优化到局部最优点，太小的batchsize会导致loss震荡，收敛速度较慢。',
    rate_tr:
        '该值的可调范围为0.00001-0.0002，默认为0.0001。该值过大会导致模型震荡难以收敛，过小会导致收敛速度慢以致于训练结束也没有寻找到loss的极小值。',
    epochs_gnn:
        '该值的可调范围为1-50，默认为10。系统在此处采用了early stop训练策略，模型会在评估指标下降时自动停止训练， 所以epoch 越大越好。',
    batch_gnn:
        '该值的可调数值为16、32、64、128，默认为32。该值越大训练速度越快，但是过大的batchsize会导致梯度多样性变少，无法优化到局部最优点，太小的batchsize会导致loss震荡，收敛速度较慢。',
    rate_gnn:
        '该值的可调范围为0.0001-0.0003，默认为0.0001。该值过大会导致模型震荡难以收敛，过小会导致收敛速度很慢导致训练结束也没有寻找到loss的极小值。',
    smiles和label保持一致:
        '提示：为方便后续的计算，请确保您上传的多个文件中的smiles列名和Label列名保持一致！',
    无需批分: '您已上传完整的数据集，无需再做数据批分',
    批分test: '由于您未上传Test Set，根据以下参数来获取您的Test Set：',
    test_ratio: 'Test Set占比',
    可选项: '可选项',
    label_intro: '输出值为0-1的预测概率值。',
    probability_intro: '以0.5为界限，预测概率值>0.5为正例值。',
    prediction_intro: '输出值与构建回归模型的训练集数据有关。'
  },
  virtual: {
    不支持自定义口袋: '不支持自定义口袋',
    选择虚拟筛选数据: '选择虚拟筛选数据',
    上传蛋白质并确认口袋: '上传蛋白质并确认口袋',
    自定义虚拟筛选: '自定义虚拟筛选流程',
    数据库: '数据库',
    输入数据: '输入数据',
    输入蛋白: '输入蛋白',
    虚筛流程: '虚筛流程',
    task_intro:
      '虚拟筛选是指针对特定靶点，从数据库中发现具有新的化合物骨架和初步生物活性的苗头化合物。碳硅智慧致力于为用户提供一套可用的药物设计工具，通过集成一些常用的数据库或者通过分子生成生成的AI定制库，结合DrugFlow平台已开发的高精度计算工具，用户可以自定义pipeline，以实现虚拟筛选找到苗头化合物。',
    筛选条件不能为空: '筛选条件不能为空',
    最小值不能大于最大值: '设置的最小值不能大于最大值',
  },
  extraction: {
    分子: '分子',
    图表: '图表',
    已选择: '已选择',
    条: '条',
    删除无编号分子: '删除无编号分子',
    批量删除分子: '批量删除',
    添加分子: '添加分子',
    编号: '编号',
    溯源: '溯源',
    其他属性: '其他属性',
    编辑结构: '编辑结构',
    当前定位内容第: '当前定位内容第',
    个: '个',
    共: '共',
    重新定位: '重新定位',
    删除定位: '删除定位',
    新增定位: '新增定位',
    自动缩放: '自动缩放',
    操作: '操作',
    化学结构: '化学结构',
    确认删除分子: '该操作不能恢复，确认删除所选分子吗？',
    确认删除表格: '该操作不能恢复，确认删除所选表格吗？',
    修改成功: '修改成功！',
    修改失败: '修改失败！',
    复制条目: '复制条目',
    复制smiles: '复制smiles',
    暂无可用数据: '暂无可用数据',
    新增图表: '新增图表',
    查看: '展开',
    收起: '收起',
    新增母核结构: '新增母核结构',
    母核结构: '母核结构',
    正在解析: '正在解析中...',
    关联编号: '关联编号',
    '插入行/列': '插入行/列',
    删除: '删除',
    拼接: '拼接结构',
    关联所有: '关联所有',
    关联当前行: '关联当前行',
    向上添加: '向上添加',
    向下添加: '向下添加',
    向左添加: '向左添加',
    向右添加: '向右添加',
    行: '行',
    列: '列',
    删除整行: '删除整行',
    删除整列: '删除整列',
    选择母核编号: '选择母核编号',
    选择拼接的列名: '选择拼接的列名',
    仅拼接当前列: '仅拼接当前列',
    拼接所有同名列: '拼接所有同名列',
    点击此处: '点击此处',
    图表解析中: '图表正在解析中，请稍候片刻，或者点击此处后可查看页面其他信息，<br />或',
    请勿退出: '后可查看页面其他信息，在没有得到提示时请勿退出当前结果页面。',
    请校验: '请校验',
    选择提取范围_intro: '输入需要提取的页码。您可以使用“,”选择单个页面，使用“-”选择连续页面的范围。点击输入框后的“确认”即可快速选择相应的页面。',
    拼接表格: '拼接表格',
    拼接成功: '拼接成功',
    拼接失败: '拼接失败',
  },
  api: {
    创建: '创建新的APP',
    无权限: '抱歉, 您无权限',
    无权限描述: '如需app管理权限, 请联系开发人员',
    api名字: 'APP 名称',
    请求新的appkey: '请求口令和密钥',
    api文档: 'APP 文档',
    token: '口令(appid)',
    secret: '密钥(secret token)',
    已安装: '已创建',
    停用: '停用',
    启用: '启用',
    已停用: '已停用',
    已启用: '已启用',
    tips: '该密钥仅显示一次，请妥善储存'
  },
  pipeline: {
    新建Pipeline: '新建Pipeline'
  },
  setting: {
    公开信息: '公开信息',
    full_name: '称呼',
    first_name: '名',
    last_name: '姓',
    Biography: '个性签名',
    Biography_placeholder: '请输入您的个性签名',
    location: '地址',
    location_placeholder: '请输入您的地址',
    私人信息: '私人信息',
    avatar: '用户头像',
    更新头像: '更新头像',
    更新头像提示: '上传jpg文件'
  },
  molgen: {
    pocket_task_intro:
        '基于口袋的3D分子生成方法，该方法最大的优点是可以在不需要特定目标的活性分子数据的情况下生成具有良好结合亲和力和合理蛋白配体结合姿态的分子，并在一定程度上保证了分子的多样性。该方法不仅适用于药物从头设计，而且支持在已有片段上生成新分子。目前我们提供的方法有ResGen，后续还会推出更多的方法。',
    ligand_task_intro:
        '基于配体的分子生成方法主要用于全新药物设计。此模块目前支持两种方法，MCMG可以生成具有理想特性的分子，对于模型效果好的MCMG模型，用户可以在这里选择相应的模型并生成分子；RELATION可生成大量结构有效并且对蛋白具有较好结合亲和力的分子，当前的版本中，平台预先训练了32个靶点，用户只能生成这32个靶点下的新分子。后续我们将开放用户自定义的模型，用户可以自定义靶点进行分子生成，敬请期待。',
    MCMG_train: 'MCMG Training',
    RELATION_train: 'RELATION Training',
    基于口袋: 'Pocket-Based',
    基于配体: 'Ligand-Based',
    cluster: 'Cluster',
    counts: 'Counts',
    Favourite: '收藏列表',
    cluster_type: '聚类方法',
    Gen_more: '生成更多',
    生成中: '生成中',
    algo: '生成方法',
    类别数量: '分子数量',
    Property_filter: '属性',
    Function_filter: '官能团',
    Userdefine_filter: '自定义片段',
    amine: '氨基',
    carboxyl: '羧基',
    carbonyl: '羰基',
    phenyl: '苯环',
    halogen: '卤素',
    methyl: '甲基',
    thioether: '硫醚',
    phenol_hydroxyl: '酚羟基',
    aryl_thioether: '芳香硫醚',
    aniline: '芳香胺',
    sulfate_ester: '硫酸酯',
    imine: '亚胺',
    hydroxyl: '羟基',
    aryl_nitro: '芳香硝基',
    trifluoromethyl: '三氟甲基',
    aryl_sulfate_ester: '芳香硫酸酯',
    alkyne: '炔基',
    alkene: '烯基',
    alkane: '烷基',
    点击选择需要保留原子: '点击选择需要保留原子',
    已选择的原子: '已选择的原子',
    生成类型: '生成类型',
    从头生成: '从头生成',
    片段生长: '片段生长',
    生成方法: '生成方法',
    蛋白文件: '蛋白文件',
    Database: '数据库',
    复合物: '复合物',
    片段来源: '片段来源',
    选择保留片段: '选择保留片段',
    选择口袋: '选择口袋',
    请先选择复合物口袋: '请先选择复合物口袋',
    请先上传配体: '请先上传配体',
    文件下载中: '文件下载中',
    无MCS: '无公共最大子结构',
    MCMG模型: 'MCMG模型',
    选择MCMG模型: '选择MCMG模型',
    创建新的MCMG模型: '创建新的MCMG模型',
    生成成功: '生成成功',
    自定义QSAR: 'Inno-QSAR Application',
    暂无自定义: '暂无自定义 Inno-QSAR Application',
    计算: '计算',
    计算新属性: '计算新属性',
    亲和力预测: 'Binding Affinity Prediction',
    相似分子: '相似结构',
    qsar任务下发成功: 'qsar任务下发成功, 任务成功后右侧会自动更新',
    至少选择一个QSAR模型: '至少选择一个QSAR模型',
    已经计算过了: '选中的分子已经计算过了',
    计算中: '计算中',
    模型来源: '模型来源',
    平台模型: '平台模型',
    用户自定义模型: '用户自定义模型',
    选择激酶靶标: '请选择平台激酶靶标',
    mcmg子模型: 'MCMG模型',
    预测属性: '预测属性',
    靶标: '靶标',
    警告: '警告',
    难以生成: '该任务难以生成，可以尝试其他口袋或者减少保留的配体原子数量',
    新分子生成: '新分子已准备就绪，点击',
    查看新的分子: '查看新的分子',
    此处: '此处',
    请至少保留一个配体原子: '请至少保留一个配体原子',
    蛋白不存在自带配体: '蛋白不存在自带配体，请上传配体',
    超过5个: '请确保您比较的分子数量不超过5个(包含参考分子)',
    docking_wrong: '蛋白预处理出错导致对接失败，当前仅展示生成分子的2D信息。'
  },
  molecule: {
    显示原子序号: '显示原子序号',
    滑动更改Bond宽度: '滑动更改Bond宽度',
    数据选择: '选择数据'
  },
  data: {
    文件名不能为空: '文件名不能为空',
    文件名字重复: '文件名字重复，请修改后重试',
    数据量过大: '当前保存的数据集超过500个分子，请耐心等待文件保存成功后再进行后续的操作。',
    不支持查看: '暂不支持查看大数据文件',
    data_center_intro: '在该页面，您可以查看上传和保存的数据，并发起新的任务。',
    创建新任务: '创建新任务',
    输入数据名称: '输入数据名称',
    搜索: '搜索',
    上传新数据: '上传新数据',
    确认批量删除: '确认批量删除',
    确认删除: '确认删除',
    批量删除: '批量删除',
    请先选择数据: '请先选择数据',
    部分数据删除失败: '部分数据删除失败',
    删除数据: '删除数据',
    完成删除: '完成删除',
    删除成功: '删除成功',
    删除失败: '删除失败',
    您还未产生任何个人数据: '您还未产生任何个人数据，',
    请: '请',
    'Upload New Data': '上传新数据',
    创建WorkSpace: '创建WorkSpace',
    请先创建您的WorkSpace:
        '请先创建您的WorkSpace，后续您上传、保存的数据都可以在您当前的WorkSpace的数据中心里查看，基于不同的使用目的，您可以随时创建新的WorkSpace或者切换至其他WorkSpace。',
    设置为默认WorkSpace: '设置为默认WorkSpace',
    WorkSpace名称: 'WorkSpace名称',
    上传文件: '上传文件',
    数据名称: '数据名称',
    点击上传sdfcsvpdb: '点击上传文件(.sdf/.csv/.pdb)',
    选择数据: '选择数据',
    数据详情: '数据详情',
    查看: '查看',
    存在相同的数据集:
        '存在相同的数据集名称。如果您的两个数据集内容相同，请从数据中心选择该数据集；如果两个文件内容不同的话，请对您的文件重新命名以后再上传。',
    数据集名称重复: '存在相同的数据集名称，请对您的文件重新命名以后再保存。',
    切换space: '切换WorkSpace',
    数据中心为空:
        '当前数据中心为空，请您选择上传文件的方式来提交任务；如果您已经上传数据但数据中心未显示时，请刷新您的页面。',
    数据集名字重复: '数据集名字重复，请修改名字后上传',
    拓展名不变: '不支持更改文件扩展名，请保持不变'
  },
  protein_pre: {
    protein_preparation: '蛋白预处理',
    protein_pre_tips: '如果您的蛋白已经做过蛋白预处理，您可以直接点击下一步。如果没有，建议您打开开关进行预处理相关的操作',
    title_tips:
        'Protein Preparation模块是一个常规的蛋白预处理工具。针对您上传的蛋白文件(.pdb)，平台会先对该文件进行结构解析，确定结构组成，然后检查有问题的结构和缺失的残基，并提供相关的修复能力；同时还支持蛋白优化能力，包括加氢、调整质子化状态、优化氢网络以及能量最小化。经该模块处理后的分子，可用于平台的Docking，分子工厂等模块。',
    step1: '1.保留结构',
    step2: '2.错误结构',
    step3: '3.缺失残基',
    step4: '4.优化',
    upload_file: '上传文件',
    from_database: '数据库导入',
    keep_polymer: 'Keep Polymer',
    keep_het_group: 'Keep Het Group',
    het_name: 'Het Name',
    water_5a: 'Water(Within 5 Å)',
    quick_delete: '快速删除',
    delete: '删除',
    water_label: 'waters that do not interact with Het Group',
    chain: 'Chain',
    residue_num: 'Residue No.',
    type: 'Type',
    details: 'Details',
    miss_residue_sequence: 'Missing Residue Sequence',
    add_hydrogens: 'Add Hydrogens',
    modify_ph: 'Modify Protonation at pH',
    optimize_network: 'Optimize Hydrogen Bonding Network',
    energy_minimization: 'Energy Minimization',
    task_name: '任务名称',
    previous: '返回',
    next: '下一步',
    correct: '纠正',
    submit: '提交',
    white_bg: '白色背景',
    black_bg: '黑色背景',
    center: '居中',
    screenshots: '截图',
    fullscreen: '全屏',
    step3_tips: '检测发现，您上传的pdb文件缺少部分一维信息，请补充：',
    part_miss_que: '输入缺失的序列',
    all_miss_que: '输入整条序列',
    part_miss_que_tips: '请输入缺失的残基类型',
    all_miss_que_tips: '请粘贴整条蛋白序列',
    no_data: '暂无数据',
    ok: '确定',
    save_file: '保存文件',
    name: '命名',
    input_protein: '输入蛋白',
    select_to_keep_polymer: '选择需要保留的蛋白链',
    select_water: '选择需要保留的小分子及水分子',
    select_water_tips:
        '此处默认展示pdb文件中所有的小分子及其5Å范围内的水分子，系统会默认删除5Å范围外的水，而5Å范围内的水由用户自行决定是否删除。其中具有水桥作用的水分子做了特殊标记，您可以通过【快速删除】按钮一步删除没有水桥作用的水分子',
    correct_structure: '纠正错误残基',
    repair_sequence: '修复缺失的残基序列',
    optimize_protein: '优化蛋白',
    input_pdbid: '输入PDB ID',
    disulfide_bond: '二硫键',
    disulfide_bond_tips:
        '此处显示蛋白中可能的所有二硫键的pair，默认选中1.5～3A范围的二硫键，基于您的研究目的可自行调整。需特别注意的是，选中的二硫键pair将会在蛋白预处理时保留，此操作对蛋白构象影响较大，请谨慎选择。'
  },
  manage: {
    admin: '超级管理员',
    manager: '管理员',
    member: '普通成员',
    团队人数: '团队人数',
    成员管理: '成员管理',
    邀请新成员: '邀请新成员',
    序号: '序号',
    姓名: '姓名',
    邮箱: '邮箱',
    团队角色: '团队角色',
    电话号码: '电话号码',
    工作职务: '工作职务',
    操作: '操作',
    移出: '移出',
    添加成员: '添加成员',
    通过邮箱直接邀请: '通过邮箱直接邀请',
    请输入邮箱: '请输入邮箱，多个邮箱用英文逗号分隔',
    系统会邀请注册: '未注册的邮箱地址，系统会邀请注册',
    添加: '添加',
    取消: '取消',
    提示: '提示',
    账号已成功加入您的团队: '账号已成功加入您的团队。',
    账号暂未注册DrugFlow: '账号暂未注册DrugFlow，',
    账号已经加入了团队: '账号已经加入了团队。',
    是否邀请该成员注册DrugFlow网站: '是否邀请该成员注册DrugFlow网站？',
    请输入有效邮箱地址: '请输入有效邮箱地址',
    团队成员已达上限:
        '团队成员已达上限，请移出当前成员后再邀请新成员，或者联系平台管理员升级您的团队权限。',
    状态: '状态',
    未注册: '未注册',
    已注册: '已注册',
    请输入搜索关键字: '请输入搜索关键字',
    新建团队: '新建团队',
    团队名称: '团队名称',
    所属团队: '所属团队',
    团队成员上限: '团队成员上限',
    已有成员数量: '已有成员数量',
    用户类型: '用户类型',
    有效期: '有效期',
    付费用户: '付费用户',
    非付费用户: '非付费用户',
    团队列表: '团队列表',
    添加成功: '添加成功',
    添加团队: '添加团队',
    未激活: '未激活',
    已激活: '已激活',
    我的工作台: '我的工作台',
    个人中心: '个人中心',
    团队管理: '团队管理',
    团队数量: '团队数量',
    团队成员数量: '团队成员数量',
    注册管理: '注册管理',
    总注册人数: '总注册人数',
    总激活人数: '总激活人数',
    个人用户: '个人用户',
    今日提交任务数量: '今日提交任务数量',
    总任务数量: '总任务数量',
    已完成: '已完成',
    进行中: '进行中',
    账户余额: '账户余额',
    消费记录: '消费记录',
    在线充值: '在线充值',
    温馨提示:
        "温馨提示: 当您的余额不够并希望继续使用DrugFlow时，请扫描下方微信二维码添加工作人员或者发送邮件至",
    工作人员二维码: '工作人员二维码',
    各模块使用频率统计图: '各模块使用频率统计图',
    近7天: '近7天',
    近30天: '近30天',
    全部: '全部',
    管理中心: '管理中心',
    登出: '登出',
    您好: '您好！',
    功能快捷入口: '功能快捷入口',
    有权限: '有权限',
    无权限: '无权限',
    导入表格: '导入表格',
    试用状态: '试用状态',
    审核通过: '审核通过',
    审核不通过: '审核不通过',
    联系方式: '联系方式',
    注册时间: '注册时间',
    激活时间: '激活时间',
    最新任务时间: '最新任务时间',
    注册用户列表: '注册用户列表',
    注册新用户: '注册新用户',
    用户名: '用户名',
    新增用户: '新增用户',
    单位名称: '单位名称',
    删除: '删除',
    激活状态: '激活状态',
    添加用户: '添加用户',
    提交: '添加用户',
    创建APP: '创建APP',
    团队成员管理: '团队成员管理',
    缺失管理员提示:
        '您的修改失败！每个团队都需要至少一名管理员。如果需要调整管理角色，请先将新帐户指定为管理员，然后再修改旧帐户的管理权限。',
    删除团队: '删除该团队后该团队将会被解散，并且其账户内的金额也将被清空，确认删除该团队吗？',
    移出成员: '停用该账号后，该账号将退出团队，并且不再享有团队相关的权限。确认移出该账号吗？',
    操作失败: '操作失败',
    请添加用户: '请添加用户',
    请添加团队: '请添加团队',
    Token余额: 'Token余额',
    已用Token: '已用Token',
    实际计算费用: '实际计算费用',
    权限配置: '权限配置',
    充值: '充值',
    充值金额: '充值金额 (元)',
    请输入正整数: '请输入正整数',
    用户标签: '用户标签',
    高校: '高校',
    非高校: '非高校',
    消费Token: '消费Token',
    消费时间: '消费时间',
    消费账号: '消费账号(名称)',
    充值方式: '充值方式',
    充值Token: '充值Token',
    充值前Token余额: '充值前Token余额',
    充值后Token余额: '充值后Token余额',
    订单总额: '订单总额',
    点击刷新二维码: '点击刷新二维码',
    元: '元',
    关闭二维码提示: '关闭此二维码，将会关闭当前订单，请确认',
    用户信息确认: '用户信息确认'

  },
  molecular_factory_error: {
    101213: '平台暂时无法解析结构中的配体，请换个pdb结构重试。',
    101214: '平台暂时无法解析结构中的配体，请换个pdb结构重试。'
  },
  common_error: {
    无数据: '该任务终止时没有分子完成计算，无法进入结果页面',
    100: '未知错误',
    101: '参数解析错误',
    102: '缺失必备参数',
    103: '前后端参数未对齐',
    104: '参数设定值无效',
    105: '无效操作',
    106: '查询参数时发生错误',
    107: '算法回调程序错误',
    108: '算法内部出错',
    109: '同时运行的任务数量超出 3 个上限',
    110: `<div style="display: flex;flex-direction: column;align-items: center">当前系统提供的免费计算额度已用完，如欲继续使用DrugFlow，请扫描下方微信二维码添加工作人员企业微信进行充值，感谢您对碳硅智慧DrugFlow - AI驱动的一站式创新药发现平台的信任与支持。<br /><img style="width: 180px;margin-left: -20px;" src="${img}"><br /></div>`,
    199: '您无法提交新的任务，因为您的DrugFlow许可证已经过期',
    '199_upload': '您无法上传文件，因为您的DrugFlow许可证已过期',
    101217: '该任务自定义的口袋不合理，请调整您的口袋信息后重试。'
  },
  outer_service_error: {
    400: '触发云服务失败'
  },
  docking_error: {
    101301: '缺少蛋白文件',
    101302: '缺少配体文件',
    101303: 'json解析Inno-Docking参数失败',
    101304: '缺少Inno-Docking必需参数',
    104300: '配体文件解析有误',
    104301: 'pdb文件解析错误',
    100302: '处理缺失残基时出错',
    104303: '缺少Docking的task或者job ',
    107304: 'Inno-Docking触发云服务失败',
    107305: '配体预处理过程中出现错误',
    107306: '蛋白预处理过程中出现错误',
    107307: '对接过程中出现错误',
    108308: '对接结果回调时出错'
  },
  admet_error: {
    101401: '缺少smiles',
    101402: 'jason解析Inno-ADMET参数失败',
    101403: '缺少Inno-ADMET必需参数',
    108404: 'MGA算法出错',
    108405: 'Inno-SA算法出错',
    108406: 'ChemFH算法出错',
    301: '请输入正确的smiles',
    302: '不支持GPU',
    400: 'Inno-ADMET触发云服务失败'
  },
  relation_error: {
    101501: 'jason解析RELATION参数失败',
    101502: '缺少RELATION必需参数',
    108503: 'RELATION算法出错'
  },
  qsar_error: {
    101701: 'jason解析Inno-QSAR参数失败',
    101702: '缺少Inno-QSAR必需参数',
    101703: '缺少dataset_id',
    101704: '缺少label_col或者input_col',
    101705: '训练算法不合法',
    104706: '缺失Inno-QSAR任务必须的参数',
    104707: 'Inno-QSAR任务需要的参数为空',
    104708: '触发Inno-QSAR任务失败',
    107709: 'Inno-QSAR训练结果回调时出错',
    107710: 'Inno-QSAR回调时出错',
    400: 'Inno- QSAR触发云服务失败'
  },
  rescoring_error: {
    101801: 'jason解析Rescoring参数失败',
    101802: '缺少Rescoring的必需参数',
    108803: 'Inno-Rescoring算法出错'
  },
  'lig-prep_error': {
    101901: 'jason解析LigPrep参数错误',
    101902: '缺少LigPrep的必需参数',
    101903: '缺少Ligand',
    108904: 'Ligand Preparation算法出错'
  },
  resgen_error: {
    101201: '缺少job id',
    204: 'job id不存在',
    205: '蛋白口袋处理出错',
    400: 'ResGen触发阿里云云服务失败',
    209: '操作不合法',
    202: '分子详情页未获取到smiles_id',
    201: '分子相似度接口缺少smiles_id',
    101202: '分子相似度接口缺少smiles_id和smiles',
    101203: '分子相似度接口缺少job_id',
    203: '分子相似度中rdkit转mol或生成指纹时出错',
    '101201d': '下载接口缺少job_id',
    210: '当前仅支持下载csv文件',
    101204: '缺少聚类方式的参数',
    211: '没有符合条件的分子可供下载'
  },
  specturm_error: {
    101601: 'NMR参数解析错误',
    101602: '缺失NMR任务的参数',
    108603: 'CReSS算法出错',
    108604: 'CMGNet算法出错'
  },
  img2mol_error: {
    101901: '参数解析错误',
    101902: '参数缺失',
    108905: '任务找不到',
    108904: '结构提取内部错误',
    108906: '新增定位失败，新增定位的分子与原始分子结构不一致。',
    108907: '新增定位的分子与该原始分子结构不一致，而与其他分子结构一致。',
    108908: '新增定位失败，重复添加已经被过滤',
    108910: '抱歉，您的表格解析失败。'
  }
}

export default zh
