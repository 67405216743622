import img from '@/assets/code.png'

const en = {
  message: {
    有效期: 'Expiration Date',
    过期: 'Expired',
    工作台: 'Workbench',
    我的工作台: 'My Workbench',
    确认删除: 'This operation cannot be restored, confirm to delete this task?',
    确认中止: 'This operation cannot be restored, confirm to abort this task?',
    Language: 'Language',
    Chinese: '中文',
    English: 'English',
    Services: 'Services',
    Help: 'Help',
    Contact: 'Contact',
    ProductsSolutions: 'Products & Solutions',
    LearnSupport: 'Learn & Support',
    ProductDocuments: 'Product Documents',
    Documents: 'Documents',
    OtherTools: 'Other Tools',
    Forum: 'Forum',
    SuccessCase: 'Success Cases',
    性质预测: 'Property Prediction',

    PersonCenter: 'Personal Center',
    TaskCenter: 'Task Center',
    Users: 'Users',

    CreateTask: 'Create Task',
    LearnMore: 'Learn More',
    DenovoDrugDesign: 'De novo Drug Design',
    ADMETPredictors: 'ADMET',
    VirtualScreening: 'Virtual Screening',

    ServicePipeline: 'Service Pipeline',
    ModelBuilding: 'Drag-and-drop model building',
    DataPreprocessingTools: 'Data Preprocessing Tools',
    LearnMoreModules: 'Learn more about these modules',

    Signin: 'Sign in',
    EnterUsername: 'Enter Your Username:',
    EnterPassword: 'Enter Your Password:',
    EnterPassword2: 'Enter Your Password Again:',
    EnterEmail: 'Enter Your Email:',
    WelcomeBack: 'Welcome Back',
    LoginIntro: 'Login to manage your data',
    LoginTitle: 'LOGIN',
    SuggestSignup: "Don't have an account yet? Sign up here!",
    Email: 'Email',
    Name: 'Name',
    Password: 'Password',
    Password_confirmation: 'Confirm Password',
    password_placeholder: 'At least 8 characters, including numbers and case sensitive',
    '60s后重试前': 'Retry after ',
    '60s后重试后': ' seconds',

    Signup: 'Sign Up',
    CreateAccount: 'Create an account',
    SignupIntro: 'Fill out the form to get started.',
    SignupTitle: 'SIGNUP',
    SuggestLogin: 'Already have an account ? Login!',
    提交: 'Submit',
    重置密码: 'Reset Password',
    设置密码: 'For your account security, please set password',
    忘记密码: 'Forgot Password',
    回到登录页面: 'Back to Sign in page',
    发送邮件: 'Send Email',
    重新发送邮件: 'Resend Email',
    激活: 'Activate',
    重新发送激活邮件: 'Resend Activation Email',
    激活语句:
        'Your activate link is invalid, please authenticate to send the activating mail again.',
    感谢注册: 'Thanks for Signing up!',
    请访问: 'Please visit ',
    来激活您的账号: ' to activate your account.',
    返回首页: 'Go back to Index',
    邮件发送成功: 'Email sent successfully',
    邮件发送失败: 'Email sent failure',
    来重置您的密码: 'to reset your password',
    验证中: 'Authenticating, please wait...',
    验证成功: 'Authenticate succeeded',
    验证失败: 'Authenticate failed',
    密码修改成功: 'Change password  successfully, 3 seconds later jump to login page',
    密码设置成功: 'Set password successfully, 3 seconds later jump to login page',
    登出成功: 'Signout successfully',
    登出失败: 'Signout failed, please check the network ',
    邮箱地址不正确: 'Email address is incorrect',
    激活邮件已过期: 'Activation email has expired',
    跳转中: '...jumping...',
    请更新您的浏览器:
        'Your browser does not support the WebSocket protocol, some functions will not be available, please replace with the latest version of Chrome, Safari, Edge or Firefox',
    正在处理: 'Processing, please wait',
    Logout: 'Logout',
    setting: 'Setting',
    TaskAssigned_1: 'The task is created successfully! ',
    TaskAssigned_2: 'Go to the ',
    TaskAssigned_3: 'to view the task running progress.',
    AskForHelp: 'Contact us for assistance.',
    ParamsError: 'Parameter error. ',
    UnknownError: 'Sorry, Unknown Error. ',
    修改成功: 'Task name changed successfully',
    修改失败检查网络: 'An error occurred, please check the network',
    industry: 'Industry',
    interest: 'Interest',
    company: 'Organization',
    phone: 'Telephone',
    职务: 'Job Position',
    姓名: 'Name',
    电话号码: 'Telephone Number',
    单位名称: 'Company',
    工作职务: 'Job Position',
    我已阅读并同意: 'I have read and agree to',
    用户协议: ' User Protocol ',
    和: 'and',
    隐私协议: ' Privacy Agreement ',
    填写信息进入新版本:
        'Dear DrugFlow User,<br /> <br /> Thank you very much for registering and trying out the CarbonSilicon AI  platform DrugFlow, an AI-driven One-stop Platform for Innovative Drug Discovery.<br /> <br /> We are pleased to announce that DrugFlow software has been upgraded to the latest version with significant updates in activity prediction, molecule generation, and AI modeling! To ensure you can enjoy a better experience, we have closed the old version of the website application. After you have completed the following information, you will enter the new DrugFlow 1.0, and the results of tasks submitted on the old version will be synchronized migrated to the new version. We hope our new version website application can bring you a better experience.<br />',
    新版本具体功能: 'Specific functions of the new version are as follows:',
    感谢关注:
        'Thank you once again for your attention and support for the CarbonSilicon AI platform DrugFlow. We look forward to continuing to provide you with high-quality service. If you have any questions or suggestions, please feel free to contact us anytime.',
    更新信息_gpt:
        "The information you provided on drugFlow is incomplete/untrue. To ensure that we provide better service and support, please update your information as soon as possible. Thank you for your trust and support in CarbonSilicon AI's DrugFlow - an AI-driven one-stop innovative drug discovery platform.",
    更新信息:
        "As the information you provided on drugFlow is incomplete/untrue, we will only provide you with a 48h trial period. To ensure that we provide better service and support, please update your information as soon as possible. Thank you for your trust and support in CarbonSilicon AI's DrugFlow - an AI-driven one-stop innovative drug discovery platform.",
    '48h已到期':
        "As the information you provided on drugFlow is incomplete/untrue, and the 48h trial period provided to you has expired. You can get a longer trial period by updating the information below. Thank you for your trust and support in CarbonSilicon AI's DrugFlow - an AI-driven one-stop innovative drug discovery platform.",
    '2m已到期':
        "Your DrugFlow 1.0 usage license has expired, and you will no longer be able to submit new tasks. If you wish to continue using the website's functionality, please scan the QR code below to contact us and purchase a license. Thank you for your trust and support in CarbonSilicon AI's DrugFlow - an AI-driven one-stop innovative drug discovery platform.",
    扫描二维码: 'Scan the QR code to add your exclusive WeChat assistant',
    扫描购买License:
        "Please scan the QR code below to contact us and purchase a license. Thank you for your trust and support in CarbonSilicon AI's DrugFlow - an AI-driven one-stop innovative drug discovery platform.",
    删除: 'Delete',
    中止: 'Abort',
    错误信息: 'Error Message',
    错误详情: 'Error Details',
    结果详情: 'Result Details',
    edit: 'Edit',
    rebind: 'Rebind',
    reset: 'Reset',
    setup: 'Set up',
    save: 'Save',
    cancel: 'Cancel',
    change_login_pwd: 'Change Login Password',
    origin_pwd: 'Original Password',
    new_pwd: 'New Password',
    enter_origin_pwd: 'Please Enter the Original Password',
    enter_new_pwd: 'Please Enter the New Password',
    confirm_new_pwd: 'Confirm New Password',
    change_phone_number: 'Change Phone Number',
    mobile_code: 'Mobile Verification Code',
    enter_mobile_code: 'Please Enter the Verification Code',
    enter_phone_number: 'Please Enter Phone Number',
    send_code: 'Send Code',
    confirm: 'Confirm',
    code_tips: 'Please Input Phone Number',
    暂无数据: 'No data available',
    重新发起: 'Reissue',
    问题类型: 'Issue Type',
    卡顿: 'Lag',
    界面及操作: 'Interface And Operation',
    账号相关: 'Account Related',
    其他: 'Other',
    问题描述: 'Issue Description',
    上传截图: 'Upload Screenshot',
    操作成功: 'Operation Successfully!',
    账户余额详情: 'Account Balance Details',
    在线充值: 'Top-up',
    消费记录: 'Spending Record',
    充值时间: 'Recharge Time',
    充值记录: 'Recharge Record',
    账号详情: 'Account Details',
    充值套餐: 'Recharge Package',
    请选择支付方式: 'Please choose a payment method',
    余额: 'Balance',
    充值提示: 
    "Tips:<br />1.If you have any unpaid bills or overdue payments, your recharge amount will be allocated first to settle these fees.<br />2.If you recharge through the official website and need to apply for an invoice, you can contact us to issue an invoice (email: hehao{'@'}carbonsiicon.ai ).<br />3.Currently, only RMB recharge is supported, and refunds are not available after recharging. Please be cautious when recharging.",
    套餐一: 'Package 1',
    套餐一价格: '500 yuan',
    套餐一token: '* Package Token: 2 million',
    套餐一有效期: '* Validity period: 1 month',
    套餐一详情: '* All modules can be used, and within the validity period, as long as there is still a token, tasks can be initiated',
    套餐二: 'Package 2',
    套餐二价格: '1000 yuan',
    套餐二token: '* Package Token: 4 million',
    套餐二有效期: '* Validity period: 3 months',
    套餐二详情: '* All modules can be used, and within the validity period, as long as there is still a token, tasks can be initiated',
    微信支付: 'WeChat Pay',
    支付宝: 'Alipay',
    临期: 'Deadline',
    总: 'Total',
  },
  index: {
    结构识别及数据提取: 'Structure Extraction and Table Recognition',
    结构识别及数据提取介绍: 'This function is used to quickly obtain information about the molecular structure in the image. The structure and analysis results obtained can be exported at any time in various computer-readable formats (such as SDF/CSV), helping users to solve problems such as "time-consuming and easy to make mistakes when drawing structure" and "time-consuming and easy to miss analysis when collecting chemical structure information from patents or literature in bulk". It assists researchers in saving time and manpower in drawing chemical structures, improving efficiency in processing chemical structure data in bulk, and quickly building their own databases.',
    about_us: 'About CarbonSilicon AI',
    虚拟筛选: 'Virtual Screening',
    获取验证码: 'Obtain Verification Code',
    靶标发现与验证: 'Target Discovery and Validation',
    先导化合物发现: 'Lead Compound Discovery',
    先导化合物优化: 'Lead Compound Optimization',
    其他工具: 'Other Tools',
    帮助文档: 'Help Document',
    我的工作台: 'My Workbench',
    成靶性预测: 'Targetability Prediction',
    反向寻靶: 'Target Fishing',
    靶标结构预测: 'Target Prediction',
    结合位点预测: 'Binding Site Prediction',
    活性预测: 'Activity Prediction',
    分子对接: 'Molecular Docking',
    AI建模: 'AI Modeling',
    相似性搜索: 'Similiarity Searching',
    全新药物设计: 'De Novo Design',
    成药性预测: 'Druggability Prediction',
    活性预测介绍:
        'Mainly used to evaluate protein-ligand binding affinity.<br />1) Inno-Docking: integrate both the classic physical docking program AutoDock Vina and the self-developed AI docking program CarsiDock for predicting binding conformation and binding affinity. It provides complete protein preparation, ligand preparation and intelligent setting of docking parameters.<br />2) Inno-Rescoring: provide industry-leading AI scoring functions (such as IGN and RTMScore), and supports users to upload relevant files for re-rating after local docking to re-evaluate protein-ligand binding affinity.',
    相似性搜索介绍:
        'Based on the principle of similarity, we offer you shape-based (3D) and fingerprint-based (2D) similarity searching from the perspective of proteins and ligands. This functional module is in developing...',
    全新药物设计介绍:
        'Also called molecular generation. Based on different principles and algorithms, the platform provides users with 2 generation modules based on different principles.<br />1) MCMG: ligand-based molecular generation method; <br />2) Relation: structure-based (protein-ligand interaction) molecular generation. "',
    成药性预测介绍:
        'To comprehensively assess the druggability of molecules, we provide 3 related computational modules.<br />1) Inno-ADMET: predict ADMET properties, can systematic evaluation of 17 physicochemical properties, 5 pharmacochemical properties, 6 druglikeness rules, 21 ADME properties, and 27 toxic properties.<br />2) ChemFH: filter frequently hit compounds.<br />3) Inno-SA: predict whether substructures will cause toxicity.',
    分子生成介绍:
        'Provides the most comprehensive molecular generation model.<br />1) Supporting De novo Drug Design、R-Group Design、Linker Design, and Scaffold Hopping.<br />2) Support pocket-based generation and ligand-based generation.<br />3) Supports molecular generation with optimized ADMET.<br />4) Generation of synthetic accessible molecules based on click chemistry and fragment segmentation.',
    AI建模介绍:
        'Mainly used to help users build customized classification/regression models based on their own data.<br />1) Inno-QSAR: Supports classic machine learning algorithms, deep learning models like GNN/Transformer, and introduces AutoML for automatic machine learning modeling and pre-trained models to improve modeling results. This makes it easy for users to build customized classification/regression models.<br />2) Inno-QSAR Application: This is a derivative module of Inno-QSAR. The Inno-QSAR Application makes predictions based on the models built by Inno-QSAR.',
    活性优化: 'Activity Optimization',
    成药性优化: 'Druggablility Optimization',
    选择性优化: 'Selcetive Optimization',
    抗耐药性设计: 'Resistance Design',
    蛋白预处理: 'Protein Preparation',
    小分子: 'Ligand',
    小分子预处理: 'Ligand Preparation',
    小分子构象搜索: 'Conformation Searching',
    蛋白质: 'Protein',
    蛋白对齐: 'Protein Align / Superpose',
    蛋白结构质量: 'Protein Structure Quality',
    研发中: 'Developing',
    对接重打分: 'Inno-Docking',
    重打分: 'Inno-Rescoring',
    待发布: 'Developing',
    AI驱动的新药发现SaaS平台: 'AI-driven One-stop Platform for Innovative Drug Discovery',
    平台介绍:
        'We have independent intellectual rights to all core algorithms, which cover the entire workflow of drug discovery, including target discovery, lead compound discovery and optimization. We use advanced AI technologies such as AI-Content generation (AIGC), self-supervised pre-training methods, reinforcement learning, and physics-based computational modeling to help medicinal chemists find potential drug candidates more efficiently and easily.',
    请输入您的邮箱: 'Please Enter Your E-mail',
    开始使用: 'Get Start',
    平台能力: 'Our Ability',
    平台特点: 'Our Features',
    平台特点详细:
        'DrugFlow is a one-stop SaaS platform covering the whole process of drug discovery, tailored for innovative drug research and development, with an Internet-style interactive interface, easy to use, and integrates multiple capabilities with independent intellectual property rights, including target discovery capabilities based on knowledge graphs and multimodal analysis, lead compound screening capabilities based on the deep integration of artificial intelligence and physical models, and lead compound optimization capabilities with equal emphasis on AI models and experts interaction.',
    精准分子对接: 'Precise Molecular Docking',
    精准分子对接介绍:
        'Dock ligands into receptor binding pockets and reassess  binding affinity using advanced InteractionGraphNet (IGN) technology',
    试用Docking: 'Try Docking',
    使命: 'Mission',
    用人工智能重塑新药研发: 'Reshaping new drug development with artificial intelligence',
    愿景: 'Vision',
    做中国最好的创新智能研发平台: 'To be the best intelligent R&D platform for innovative drugs',
    相关链接: 'Related Links',
    碳硅智慧科技发展有限公司: 'HangZhou CarbonSilicon AI Technology Co., Ltd',
    公司业务: 'Business',
    CARO服务: 'CARO Service',
    关于我们: 'About Us',
    公司简介: 'Company Profile',
    团队介绍: 'Core Team',
    联系我们: 'Contact Us',
    加入我们: 'Join Us',
    简历投递: 'Resume Delivery',
    业务合作: 'Business Cooperation',
    投资合作: 'Investment Cooperation',
    联系电话: 'Tel',
    隐私政策: 'Privacy Policy',
    法律声明: 'Legal Notice',
    营业执照: 'Business License',
    其他业务: 'Other business',
    已有账号: 'Forgot your password?',
    没有账号: 'Go to sign up',
    登录以访问控制台: 'Login To Access Dashboard',
    欢迎来到: 'WELCOME TO',
    账户管理: 'Accounting',
    前往登录: 'Goto Sign in',
    文档: 'Docs',
    dashboard: 'Dashboard',
    dashboard_intro:
        'On this page, you can learn about node resource information and task running status.',
    MCMG: 'MCMG',
    RELATION: 'RELATION',
    ADMET: 'ADMET',
    ChemFH: 'ChemFH',
    SubstructAlert: 'Substruct Alert',
    其他: 'Other',
    药厂: 'Pharmaceutical',
    生物技术公司: 'Biotechnology',
    高校: 'University',
    研究所: 'Research Institution',
    api: 'API',
    用户信息: 'User Info',
    主要模块: 'Main Modules',
    Pipeline工具: 'Pipeline',
    设置: 'Setting',
    扩展: 'Interface',
    数据处理: 'Data Processing',
    个人中心: 'Personal Center',
    更多详情: 'More Details',
    Inno_modeler: 'AI Modeling Application',
    Inno_modeling: 'AI Modeling',
    Inno_qsar_app: 'Inno-QSAR Application',
    spectrum_title: 'NMR Parsing',
    用户: 'User',
    用户管理: 'User Manage',
    APP管理: 'APP Manage',
    管理: 'Manage',
    data_center: 'Data Center',
    用户个人数据: 'Personal Data',
    小分子数据库: 'Molecule Database',
    任务中心: 'Task Center',
    ligand_prep: 'Ligand Preparation',
    protein_prep: 'Protein Preparation',
    molecular_Factory: 'Molecular Factory',
    screen_virtual: 'Virtual Screening',
    应用场景: 'Application Scenarios',
    QSAR_train: 'QSAR Modeling',
    molgen_train: 'Molecular Generation Modeling',
    'Inno-ADMET': 'Inno-ADMET',
    'Inno-SA': 'Inno-SA',
    分子生成: 'Molecular Generation',
    QSAR: 'Inno-QSAR',
    其他工具介绍:
        'NMR Parsing: 该模块提供了两种不同的AI算法，即CReSS和CMGNet，来解析未知化合物的结构。只需输入化学位移值，平台即可快速协助用户解析分子结构。该方法在有机化学和天然产物化学的研究中具有重要意义。',
    screen_docking_intro:
        'AI docking that integrates protein preprocessing, ligand preprocessing, and docking parameter settings',
    screen_rescoring_intro:
        'AI-based scoring function, re-evaluate the binding affinity of protein-ligand',
    admet_dl_intro: 'Systematically evaluate the druggability of molecules',
    admet_fh_intro: 'Quickly filter frequently hit compounds',
    admet_sa_intro: 'Predict whether there is a toxic substructure in the molecule',
    model_qsar_intro: 'AI-based automated modeling method',
    model_use_intro: 'The module derived from Inno-QSAR for prediction',
    ligand_prep_intro: 'Molecular Processing Tools',
    protein_preparation_intro: 'Interactive editing and preprocessing of proteins',
    spectrum_ai_intro:
        'Quickly analyze the carbon spectrum to help experts identify molecular structures',
    molecular_factory_intro: 'Produce molecules that satisfy experts & can be quickly verified',
    screen_virtual_intro: 'Custom Pipeline to Discover Active Compound',
    意见反馈: 'Feedback'

  },
  molecular_Factory: {
    相互作用残基: 'Interaction Residue',
    氢健: 'H-bond',
    对接后配体: 'Generated Molecule',
    删除片段: 'Delete fragment',
    对接构象比较: 'Docking conformation comparison',
    微调数据不足:
        'When the number of molecules is less than 300, the fine-tune effect of the generative model will be reduced, please upload more molecules',
    警告信息: 'Warning',
    molecular_Factory_title: 'Molecular Factory',
    molecular_Factory_tip:
        'The Molecular Factory is an automated process that helps users screen desirable molecules. The process includes two functions: molecule generation and Docking. Users can set relevant parameters based on their own needs. After the task is completed, the result page will provide rich functions to help users screen molecules.',
    数据选择: '1.Molecular Generation',
    筛选条件配置: '2.Filter parameter configuration',
    数据来源: 'Data Sources',
    分子生成: 'Molecular Generation',
    上传文件SDF: 'Upload files.sdf',
    数据中心: 'Data Center',
    生成类型: 'Generation Type',
    上传预处理后的蛋白: 'Pre-protein',
    点击上传pdb文件: 'Click to upload pdb file',
    选择口袋: 'Select the pocket',
    指定位置生长: 'Location-specific',
    上传活性分子: 'Active Molecules',
    点击上传csvsdf文件: 'Click to upload csv/sdf file',
    生成方法: 'Algorithm',
    生成数量: 'No.Generation',
    生成时间: 'Generation Time',
    下一步: 'Next',
    分子基本属性过滤: '2D Filtering',
    配体预处理: 'Ligand Preparation',
    点击设置参数: 'Click to set Parameter',
    Docking参数设置: '3D Calculation',
    计算公共自结构的RMSD值: 'Calculate the RMSD value of the common substructure',
    信息过滤: '3D Filtering',
    原子空间位置检测: 'Atomic Detection',
    任务名称: 'Task Name',
    提交任务: 'Submit',
    上一步: 'Previous',
    确定: 'Determine',

    上传数据: 'Upload Data',
    分子中心: 'Molecular center',
    片段来源: 'Fragment source',

    复合物配体: 'Complex ligand',
    预处理后的蛋白: 'The Preprocessed Protein',
    admet_dl: 'Inno-ADMET',
    admet_fh: 'ChemFH',
    admet_sa: 'Inno-SA',
    conformation: 'Docking Comformations',
    点击原子获取保留片段: 'Click the atom to get the reserved fragment',
    解析配体失败:
        'Notice！Failed to resolve the ligand in PDB, please paste smiles or upload the ligand file to determine the reserved fragment',
    选择类型: 'Select Type',
    上传蛋白及设置口袋: 'Upload Protein and Set Pocket',
    设置保留片段: 'Reference Molecule/Reference Fragment',
    结束条件: 'Setting Generation Method and Termination Condition',
    docking参数: 'Docking-related parameter settings',
    生成异构体: 'Isomers',
    选择对接方法: 'Selection of docking method',
    设置对接口袋: 'Uploading protein and setting docking interface pocket',
    上传活性_intro:
        'Uploading active molecules can fine-tune the generated model, making the generated molecules more similar to the active molecules in terms of structure/properties."',
    结束条件_intro:
        'The generation task can be terminated as long as either of the set number of generations or the set duration is satisfied.',
    生成异构体_intro:
        'Enabling the isomers will generate different isomers based on the generated molecules, while disabling it will preserve the original configuration of the generated molecules.',
    构象比较_intro:
        'RMSD values are used to compare conformational differences of the same substructure between different molecules.',
    位置检测_intro:
        'Check if identical atoms are generated within a 5Å spatial range of the selected atom.',
    保留片段_intro:
        'You can enter the full reference molecule and edit to determine the retained fragments, or enter the reference fragment directly (smiles with * only supported)',
    至少一个输入:
        'Please input a protein, a reference molecule, or active molecules. A task can be initiated provided that any one of these inputs is supplied.',
    输入的smiles不满足: 'The smiles input does not satisfy the ',
    '条件，对于': 'condition, so the smiles input in ',
    只有一个片段和一个断点: ' mode should satisfy only one fragment and one breakpoint',
    '有两个片段，并且每个片段都只有一个断点':
        'mode should satisfy that there are two fragments, each with only one breakpoint',
    '只有一个骨架片段，有大于1个的断点数量':
        'mode should satisfy only one skeleton fragment, with a number of breakpoints greater than 1',
    '有2个以上片段，并且每个片段只有一个断点':
        'mode should be satisfied that there are more than 2 fragments and only one breakpoint per fragment',
    请输入正确的smiles: 'Please enter the correct smiles',
    无法使用蛋白信息:
        'When inputting fragment smiles as a reference for molecule generation, protein information cannot be used. Please modify your input parameters.',
    不支持输入片段分子:
        'Input of fragment molecules is not supported when performing De Novo generation. Please modify your input parameters.'
  },
  task: {
    上传: 'Upload',
    文件: 'File',
    坐标: 'Coord',
    长: 'Length',
    宽: 'Width',
    高: 'Height',
    确认: 'Confirm',
    清空: 'Clear',
    任务中心: 'Task Center',
    数据中心: 'Data Center',
    管理中心: 'Manage Center',
    注册管理: 'Registration Management',
    团队管理: 'Team Management',
    运行中: 'Running',
    创建任务: 'Create Tasks',
    最近结果: 'Recent Results',
    输入方式: 'Input Type',
    输入SMILES: 'Paste SMILES',
    示例: 'Example',
    重置: 'Reset',
    绘制分子: 'Draw Mol',
    上传文件: 'Local File',
    csv文件: 'csv File',
    输入方法: 'Input Method',
    拖动文件到此处或点击上传: 'Drop file here or click to upload',
    文件大小应小于10M: 'File with a size less than 10M',
    获取示例文件: 'Get Example',
    示例文件: 'Example File',
    项目名称: 'Project Name',
    任务名称: 'Task Name',
    提交任务: 'Submit Task',
    近30天结果: 'Results Within 30 Days',
    近7天结果: 'Results Within 7 Days',
    近60天结果: 'Results Within 60 Days',
    所有结果: 'All Results',
    项目: 'Project',
    选择项目: 'Select Project',
    任务类别: 'Task Type',
    运行状态: 'Status',
    批量删除: 'Batch Remove',
    详情: 'Details',
    蛋白预处理: '1. Protein Preparation',
    配体预处理: '2. Ligand Preparation',
    设置对接参数: '3. Set Docking Parameter ',
    数据库导入: 'From Database',
    选择示例: 'Select Example',
    选择方法: 'Select Method',
    使用示例: 'Use Example',
    下一步: 'Next Step',
    自定义: 'User-defined',
    当前配体: 'Current ligands',
    上一个: 'Previous',
    下一个: 'Next',
    返回: 'Previous',
    操作: 'Actions',
    存在错误: 'There are errors',
    前往: 'Goto',
    如需查看更多任务结果: 'To view more task results',
    暂无正在运行的任务: 'There are currently no running tasks',
    任务创建页面: 'task page',
    下发您的第一个任务: 'to create your first task',
    正在运行: 'Running',
    自定义筛选: 'Custom Filter',
    运行结果: 'Results',
    已完成: 'Finished',
    选择模型类别: 'Module Type',
    只显示前50个配体: 'Only display 50 ligands',
    暂无任务: 'No tasks',
    正确填充参数: 'Please enter parameters correctly first',
    finished: 'Finished',
    running: 'Running',
    preparing: 'Preparing',
    pending: 'Queued',
    stopped: 'Stopped',
    aborted: 'Aborted',
    预处理: 'Preparation',
    生成中: 'In Generation',
    对接中: 'In Docking',

    TaskName: 'Task Name',
    Props: 'Property',
    Target: 'Target',
    Iteration: 'No. Iteration',
    GenerateNumber: 'Generated Num',
    MinimizedRange: 'Minimized Range',
    Minimize: 'Energy Minimize',
    Protonation: 'Protonation',
    ProtonationContent: 'Add Hydrogen (pH=7.4)',
    Correct: 'Correct',
    CorrectContent: 'Correct Faulty Structure/Repair Missing Residues',
    Charge: 'Charge',
    Disconnect: 'Delete Disconnect Group (Metal Ion/salt Ion)',
    Fragment: 'Fragment',
    Hydrogen: 'Hydrogens',
    Keep: 'Keep',
    Delete: 'Delete',
    FragmentContent: 'Only Keep Largest Molecular Fragment',
    Yes: 'Yes',
    X: 'X-Coordinate',
    Y: 'Y-Coordinate',
    Z: 'Z-Coordinate',
    Length: 'Length',
    Height: 'Height',
    Width: 'Width',
    Mode: 'Docking Mode',
    AddHydrogen: 'Add Hydrogen',
    ScoreFunction: 'Scoring Function',
    ReScoreFunction: 'Rescoring',
    Pose: 'Output Pose',
    BoxCenter: 'Docking Site',
    Ligands: 'Ligands',
    切换视图: 'Switch View',
    后台未知错误: 'Unknown Error',
    参数错误: 'Parameter Error',
    登录信息已过期: 'Login information has expired, please sign in',
    有新的任务完成: 'New Task Finished!',
    // 运行结束: ' is finished, and the total number of ',
    运行结束: ' is finished.',
    计算分子数量: ' molecules is calculated',
    这是必填项: 'This is required',
    optional: 'Optional',
    sme_full: 'Calculate the substructure attribution to the prediction result',
    submit: 'Submit',
    'Upload Data': 'Upload Data',
    'Data Preparation': 'Data Partitioning',
    Settings: 'Settings',
    Washing: 'Data Washing',
    Paratition: 'Data Paratition',
    'Model Type': 'Model Type',
    'Select Method': 'Method',
    'Select Descriptor': 'Descriptor',
    'Label FieId': 'Label FieId',
    'Active Value': 'Active Value',
    基于描述符: 'Descriptor-based',
    基于结构: 'Structure-based',
    模型训练: 'Model Training',
    输入smlies: 'Please enter SMILES!',
    绘制分子结构: 'Please draw a structure!',
    请上传文件: 'Please upload the file!',
    超过10m: 'This file exceeds the 10M limit. Please upload other files!',
    无法解析: 'This file cannot be parsed, please upload other files!',
    蛋白结构: 'Please enter protein structure!',
    pdbid: 'Please enter the correct PDB ID!',
    默认位点提交:
        'You are using the default docking site provided by the system. Are you sure to use this docking site and submit the task?',
    自定义位点提交:
        'You are using a user-defined docking site. Are you sure to use this docking site and submit the task?',
    选择smiles: 'Please select the SMILES column!',
    选择示例文件: 'Please select an example file!',
    选择qsar任务: 'Please select an Inno-QSAR Task!',
    选择描述符: 'Please select the Descriptor!',
    选择标签列: 'Please select the label column!',
    选择正例值: 'Please select the positive value!',
    选择口袋: 'Please select the pocket!',
    输入碳谱: 'Please enter 13C Spectrum!',
    上传post蛋白: 'Please upload the Prepared Protein',
    上传post配体: 'Please upload the Prepared Protein',
    上传post复合物: 'Please upload the Docking Complex',
    错误信息: 'Error message: ',
    错误详情: 'Error details: ',
    选择建议浏览器:
        'To ensure a good user experience, use Firefox, Google, Safari, and Edge browsers!',
    抢先体验: 'DrugFlow1.0 is in private beta stage, click here to experience the new release',
    申请体验:
        'In order for you to successfully experience the beta version of DrugFlow 1.0, please fill in the true information, and the staff will determine your eligibility based on the information you fill in',
    仅允许单一分子: 'Only drawing of a single molecule is permitted.',
    分子不能为空: 'Please draw the molecule.',
    请绘制分子: 'Draw the molecular structure in the Molecular editor on the right',
    命名: 'Name',
    基于默认值提交:
        'You are currently using the default values for 2D property filtering. It is recommended that you adjust these according to your research object. Are you sure you want to submit the task based on the default values?',
    步骤: 'Step',
    结构清洗: 'Structure Wash',
    子结构可解释性: 'Substructure Interpretability',
    子结构解释: 'By enabling the interpretability function, the single molecule detail page will provide the impact of the substructure on a certain attribute, but this will increase the ',
    建模数据: 'Modeling Data',
    训练集: 'Training Set',
    测试集: 'Test Set',
    获取测试集: 'Obtaining the Test Set',
    采样方法: 'Sampling Method',
    建模方法: 'Modeling Method',
    计算描述符: 'Computing Descriptors',
    用户自定义描述符: 'User-defined descriptors',
    上传配体: 'Upload Ligand',
    预估计算费用: 'Estimated cost calculation',
    个人余额: 'Balance',
    计算费用提示: 'The estimated cost is for reference only, the final cost will be based on actual calculation after the task is completed.',
    SMILES列qsar提示: "Attention！The 'SMILES' column names in the two files do not match. Please edit in the table on the right to achieve consistent column names.",
    标签列qsar提示: "Attention！The 'Label' column names in the two files do not match. Please edit in the table on the right to achieve consistent column names.",
    结构识别及数据提取: 'Structure Extraction and Table Recognition',
    数据提取介绍: 'Quickly obtain information about the molecular structure in the image',
    预览: 'Preview',
    搜索: 'Search',
    上一页: 'Previous Page',
    下一页: 'Next Page',
    缩小: 'Zoom Out',
    放大: 'Zoom In',
    打印: 'Print',
    下载: 'Download',
    选择文件: 'Select File',
    '请上传图片pdf文件': 'Please upload the image/pdf file',
    '点击上传图片pdf文件': 'Click to upload the image/pdf file',
    选择提取范围: 'Select Extraction Range',
    已选: 'Select',
    暂无: 'None',
    全选: 'Select All',
    查看: 'View',
    请选择起码一页PDF: 'Please select at least one page of PDF',
    请在原文中框选分子结构或名称: 'Please box the molecular structure or name in the original text',
    退出: 'Quit'
  },
  result: {
    复制: 'Copy',
    定位: 'Orientation',
    数量: 'Count',
    序号: 'No.',
    Formula: 'Formula',
    Result: 'Result',
    分子详情页: 'Molecule Detail Page',
    分子对比页: 'Molecule Contrast Page',
    Table: 'Table',
    Grid: 'Grid',
    Charts: 'Charts',
    聚类方法: 'Cluster method',
    仅收藏: 'Collect',
    仅不喜欢: 'Dislike',
    还原: 'Restore',
    参考配体: 'Reference ligand',
    当前配体: 'Current ligand',
    对比: 'Contrast',
    Aggregation: 'Cluster',
    AdvancedFilter: 'Advanced Filter',
    DruggabilityPrediction: 'Druggability Prediction',
    MoleculeGenerate: 'De Novo Molecule Design',
    SelectDisplay: 'Select Mechanisms',
    SelectDisplayP: 'Select Properties',
    structure: 'Structure',
    AllMolecules: 'All Molecules',
    ValidMolecules: 'Valid Molecules',
    FilterTitle: 'Filter Query',
    MechanismsTitle: 'Mechanisms',
    RulesTitle: 'Rules',
    SelectALL: 'Select All',
    SelectNone: 'Select None',
    默认: 'Default',
    显示隐藏: 'Show/Hide Columns',
    显示隐藏计算列: 'Show/Hide Computed Columns',
    显示隐藏上传列: 'Show/Hide Upload Columns',
    ResetSelectFilter: 'Reset',
    创建您的过滤器: 'Create your own filter',
    Import: 'Import',
    模型: 'Model',
    关闭: 'Close',
    保存: 'Save',
    保存成功: 'Save Successfully!',
    保存失败: 'Save Failed',
    上传成功: 'Upload Successfully!',
    应用: 'Apply',
    范围: 'Range',
    图例: 'Legend',
    符合建议值: 'Meets recommended values',
    符合建议值但接近临界值: 'Meets recommendations, but close to critical',
    超出建议值需引起注意: 'exceeds the recommended value and requires attention',
    ligand: 'Ligands',
    pocket: 'Proteins',
    Complexes: 'Complexes',
    download_title: 'Alert',
    下载: 'Download',
    下载部分: 'Download filtered Molecules & Property',
    pdb下载部分: 'Download filtered Protein-ligand Complexes',
    下载全部: 'Download all Molecules & Property',
    pdb下载全部: 'Download all Protein-ligand Complexes',
    protein下载提示: 'Tips: Multiple complexes will be packaged as a zip archive for download',
    部分打分函数: 'Download filtered Molecules & Score Function',
    全部打分函数: 'Download all Molecules & Score Function',
    确认: 'OK',
    取消: 'Cancel',
    复合物编号: 'Compound ID',
    隐藏无效分子: 'Hide Invalids',
    显示无效分子: 'Show Invalids',
    保存文件: 'Save File',
    创建新任务: 'Create new task',
    请先保存数据集:
        'Before creating a new task, please save the data to be calculated as a new dataset',
    取消收藏: 'Cancel bookmark',
    至少保留一列: 'Keep at least one column of data',
    任务名称: 'Task Name',
    蛋白文件名称: 'Protein File Name',
    配体文件名称: 'Ligand File Name',
    文件名称: 'File Name',
    SMILES列: 'Select SMILES',
    对接方法: 'Docking Method',
    方法: 'Function',
    输入方式: 'Input Type',
    建模类型: 'Model Type',
    训练集文件名称: 'Training Set File Name',
    测试集文件名称: 'Test Set File Name',
    标签列: 'Select Label Column',
    正例值: 'Positive Value',
    建模方法: 'Modeling Method',
    描述符: 'Descriptors',
    模型参数: 'Modeling',
    参考分子: 'Reference Molecule',
    生长片段: 'Fragments',
    活性分子: 'Active Molecules',
    生成方法: 'Method',
    生成数量: 'No.Generation',
    生成时间: 'Generation Time',
    过滤条件: '2D Filter Conditions',
    虚筛数据: 'Virtual Data',
    虚筛Step1: 'Virtual Step1',
    虚筛Step2: 'Virtual Step2',
    虚筛Step3: 'VirtualStep3',
    蛋白预处理: 'Protein Preparation',
    配体预处理: 'Ligand Preparation',
    参数详情: 'Task Parameter Details',
    文件内容缺失: 'The file content is missing and cannot be restarted',
    生成类型: 'Generation Type',
    QSAR模型: 'QSAR Model',
    口袋: 'Pocket',
    加载更多: 'Load More',
    没有更多了: 'No More Available',
    共: 'Total',
    个分子: 'Structures',
    提取范围: 'Extraction Range'
  },
  admet_fh: {
    status: 'Global Score',
    Pass: 'Pass',
    Pass_intro: 'All green',
    LowRisk: 'Low Risk',
    LowRisk_intro: '≤3 yellow',
    HighRisk: 'High Risk',
    HighRisk_intro: '≥4 Red',
    IntermediateRisk: 'Intermediate Risk',
    IntermediateRisk_intro: ' ≥4 yellow, or ≤3 yellow and ≤2 red',
    Aggregators_name: 'Aggregates',
    'Fluc inhibitors_name': 'FLuc inhibitors',
    'Promiscuous comps_name': 'Promiscuous compounds',
    'Reactive comps_name': 'Reactive compounds',
    'Green fluorescence_name': 'Blue fluorescence',
    'Blue fluorescence_name': 'Green fluorescence',
    'Other assay_name': 'Other assay interferences',
    Aggregators_intro:
        'Aggregates: refers to colloidal aggregation compounds, which are aggregates with a radius of 60-300 nm formed by the aggregation of small molecules in solution. The colloidal aggregators would non- specifically bind to the surface of proteins, thus inducing local protein unfolding, which usually results in destabilization or denaturation of enzymes',
    'Fluc inhibitors_intro':
        'FLuc inhibitors: Firefly luciferase, is widely used in a variety of HTS bioluminescence assays, especially in the assay which aims to study gene expression at the transcriptional level. However, the inhibition of Fluc by unexpected FLuc inhibitors would produce interference to HTS assays.',
    'Promiscuous comps_intro':
        'Promiscuous compounds: Promiscuous compounds refer to compounds that specifically bind to different macromolecular targets. These multiple interactions may include unintended targets, thus triggering adverse reactions and other safety issues.',
    'Reactive comps_intro':
        'Reactive compounds: Compounds that can chemically react with protein residues leading to chemical modification of the protein, or react with nucleophilic assay reagents resulting in chemical modification of the reagent structure (which occurs less frequently).',
    'Green fluorescence_intro':
        'Blue fluorescence: Compounds that can naturally emit blue fluorescence, while compounds whose natural luminescence region overlaps with the detection fluorophore region are more likely to cause interference, such as interference to HTS.',
    'Blue fluorescence_intro':
        'Green fluorescence: Compounds that can naturally emit green fluorescence,which would produce interference to HTS assays.',
    'Other assay_intro':
        'Other assay interferences: Compounds that interfere with experiments Alpha-screen, FRET, TR-FRET, absorbance artifacts.',
    task_intro:
        'Chemical Frequent Hitter(ChemFH), is designed to identify compounds that frequently hit the test, including aggregates, Fluc inhibitors, promiscuous compounds, reactive compounds, blue/green fluorescent compounds, and other detection interferences compounds. These compounds severely interfere with the discovery of potentially active compounds, resulting in waste of time and resources. Based on the collection of massive high-quality databases and the application of the Graph Neural Networks architecture, ChemFH has a reliable ability to detect frequently hitters, thereby improving the efficiency of drug research and development.'
  },
  admet_dl: {
    cluster_id_name: 'Cluster ID',
    carsidock_res_name: 'CarsiScore',
    carsidock_res_intro:
        'The deep learning algorithm based scoring function, the value is derived from a combination of self-defined statistical potential and conformational rationalization loss, and the smaller the value, the better the generated conformation.',
    carsidock_rtms_res_name: 'RTMScore',
    carsidock_rtms_res_intro:
        'The machine learning algorithm based scoring function, the value is a self-defined statistical potential, and the larger the value, the better the binding affinity of the protein-ligand.',
    karmadock_res_name: 'KarmaScore',
    karmadock_res_intro:
        'The built-in scoring function of KarmaDock. KarmaDock applied the mixture density networks to learn the optimal distance distribution between protein and ligand to evaluate its binding affinity, and the larger the KarmaScore, the better the binding affinity of the protein-ligand.',
    basic: 'Basic Props',
    absorption: 'Absorption Props',
    distribution: 'Distribution Props',
    metabolism: 'Metabolism Props',
    excretion: 'Excretion Props',
    toxicity: 'Toxicity Props',
    basic_toxicity: 'Basic Toxicity',
    envtoxic: 'Envtoxic',
    tox21: 'Tox21',
    Formula_name: 'Formula',
    Formula_intro: '',
    Similarity_name: 'Similarity',
    Similarity_intro:
        'Cosine Similarity score, a larger similarity score indicated a higher likelihood that the predicted structure is the correct structure.',
    MW_intro: 'Contain hydrogen atoms. Optimal:100~600',
    Volume_intro: 'Van der Waals volume',
    Density_intro: 'Density = MW / Volume',
    nHA_intro: 'Number of hydrogen bond acceptors. Optimal:0~12',
    nHD_intro: 'Number of hydrogen bond donors. Optimal:0~7',
    nRot_intro: 'Number of rotatable bonds. Optimal:0~11',
    nRing_intro: 'Number of rings. Optimal:0~6',
    MaxRing_intro: 'Number of atoms in the biggest ring. Optimal:0~18',
    nHet_intro: 'Number of heteroatoms. Optimal:1~15',
    fChar_intro: 'Formal charge. Optimal:-4 ~4',
    nRig_intro: 'Number of rigid bonds. Optimal:0~30',
    Flexibility_intro: 'Flexibility = nRot /nRig',
    'Stereo Centers_intro': 'Optimal: ≤ 2',
    TPSA_intro: 'Topological Polar Surface Area. Optimal:0~140',
    LogS_intro: 'Log of the aqueous solubility. Optimal: -4~0.5 log mol/L',
    LogP_intro: 'Log of the octanol/water partition coefficient. Optimal: 0~3',
    LogD_intro: 'logP at physiological pH 7.4. Optimal: 1~3',
    QED_intro:
        ' A measure of drug-likeness based on the concept of desirability; Attractive: > 0.67; unattractive: 0.49~0.67; too complex: < 0.34',
    SAS_intro:
        'Synthetic accessibility score is designed to estimate ease of synthesis of drug-like molecules; SAscore ≥ 6, difficult to synthesize; SAscore < 6, easy to synthesize',
    SAscore_intro:
        ' Synthetic accessibility score is designed to estimate ease of synthesis of drug-like molecules; SAscore ≥ 6, difficult to synthesize; SAscore < 6, easy to synthesize',
    Fsp3_intro:
        'The number of sp³ hybridized carbons / total carbon count, correlating with melting point and solubility; Fsp³ ≥ 0.42 is considered a suitable value.',
    'MCE-18_intro':
        'MCE-18 stands for medicinal chemistry evolution; MCE-18≥45 is considered a suitable value.',
    NPscore_intro:
        'Natural product-likeness score; This score is typically in the range from -5 to 5. The higher the score is, the higher the probability is that the molecule is a NP.',
    PAINS_intro:
        'Pan Assay Interference Compounds, frequent hitters, Alpha-screen artifacts and reactive compound.',
    'ALARM NMR_intro': 'Thiol reactive compounds.',
    BMS_intro: 'Undesirable, reactive compounds.',
    'Chelator Rule': 'Chelating compounds.',
    'Caco-2_intro': 'Caco-2 cell permeability; Apparent Permeability Coefﬁcient (Papp), measured in log10(cm/s). Poor absorption (absorption rate 0% to 20%): <-6;  moderate absorption (20% to 70%): -6 to -5;  good absorption (70% to 100%): >-5.',
    'Pgp-inh_intro':
        'Category 1: Inhibitor; Category 0: Non-inhibitor; The output value is the probability of being Pgp-inhibitor',
    'Pgp-sub_intro':
        'Category 1: substrate; Category 0: Non-substrate;The output value is the probability of being Pgp-substrate',
    HIA_intro:
        'Human Intestinal Absorption;Category 1: HIA+( HIA ≥  30%); Category 0: HIA-( HIA < 30%); The output value is the probability of being HIA+.',
    'F(20%)_intro':
        '20% Bioavailability; Category 1: F20%+ (bioavailability ≥ 20%); Category 0: F20%- (bioavailability < 20%); The output value is the probability of being F20%+.',
    'F(30%)_intro':
        '30% Bioavailability; Category 1: F30%+ (bioavailability < 30%); Category 0: F30%- (bioavailability > 30%); The output value is the probability of being F30%+',
    PPB_intro:
        'Plasma Protein Binding;Optimal: < 90%. Drugs with high protein-bound may have a low therapeutic index.',
    VDss_intro: 'Volume Distribution;Optimal: 0.04-20L/kg',
    BBB_intro:
        ' Blood-Brain Barrier Penetration;Category 1: BBB+; Category 0: BBB-; The output value is the probability of being BBB+',
    BBBP_intro:
        ' Blood-Brain Barrier Penetration; Category 0: Molecules with logBB ≤ -1 were classified as BBB-; Category 1: Molecules with logBB > -1 were classified as BBB+',
    Fu_intro: 'The fraction unbound in plasms; Low: <0.05; Middle: 0.05~0.2; High: > 0.2.',
    'CYP1A2-inh_intro':
        'Category 1: Inhibitor; Category 0: Non-inhibitor;The output value is the probability of being inhibitor.',
    'CYP1A2-sub_intro':
        'Category 1: Substrate; Category 0: Non-substrate; The output value is the probability of being substrate.',

    'CYP2A6-sub_intro':
        'Category 1: Substrate; Category 0: Non-substrate; The output value is the probability of being substrate.',
    'CYP2B6-sub_intro':
        'Category 1: Substrate; Category 0: Non-substrate; The output value is the probability of being substrate.',
    'CYP2C8-sub_intro':
        'Category 1: Substrate; Category 0: Non-substrate; The output value is the probability of being substrate.',


    'CYP2C9-inh_intro':
        'Category 1: Inhibitor; Category 0: Non-inhibitor; The output value is the probability of being inhibitor.',
    'CYP2C9-sub_intro':
        'Category 1: Substrate; Category 0: Non-substrate; The output value is the probability of being substrate.',
    'CYP2C19-inh_intro':
        'Category 1: Inhibitor; Category 0: Non-inhibitor; The output value is the probability of being inhibitor.',
    'CYP2C19-sub_intro':
        'Category 1: Substrate; Category 0: Non-substrate; The output value is the probability of being substrate.',
    'CYP2D6-inh_intro':
        'Category 1: Inhibitor; Category 0: Non-inhibitor; The output value is the probability of being inhibitor.',
    'CYP2D6-sub_intro':
        'Category 1: Substrate; Category 0: Non-substrate; The output value is the probability of being substrate.',
    'CYP2E1-sub_intro':
        'Category 1: Substrate; Category 0: Non-substrate; The output value is the probability of being substrate.',

    'CYP3A4-inh_intro':
        'Category 1: Inhibitor; Category 0: Non-inhibitor; The output value is the probability of being inhibitor.',
    'CYP3A4-sub_intro':
        'Category 1: Substrate; Category 0: Non-substrate; The output value is the probability of being substrate.',
    'HLM-cl_intro': 'Based on the human liver microsomal (HLM) enzyme clearance rate (CL), with a threshold of 20 ml/min/g, Category 1: Unstable, with CL ≥ 20；Category 0: stable, with CL <20.',
    'HLM-t12_intro': 'Based on the human liver microsomal (HLM) enzyme half-life (T1/2), with a threshold of 0.5 hours, Category 1: Unstable, with T1/2 ≤ 0.5; Category 0: stable, with T1/2 > 0.5.',
    'MLM-cl_intro': 'Based on the mouse liver microsomal (MLM) enzyme clearance rate, with a threshold of 20 ml/min/g, Category 1: Unstable, with CL ≥ 20; Category 0: stable, with CL <20.',
    'MLM-t12_intro': 'Based on the Mouse liver microsomal (MLM) enzyme half-life (T1/2), with a threshold of 0.5 hours, Category 1: Unstable, with T1/2 ≤ 0.5; Category 0: stable, with T1/2 > 0.5.',
    'RLM-cl_intro': 'Based on the rat liver microsomal (RLM) enzyme clearance rate, with a threshold of 20 ml/min/g, Category 1: Unstable, with CL ≥ 20; Category 0: stable, with CL <20.',
    'RLM-t12_intro': 'Based on the rat liver microsomal (RLM) enzyme half-life (T1/2), with a threshold of 0.5 hours, Category 1: Unstable, with T1/2 ≤ 0.5; Category 0: stable, with T1/2 > 0.5.',
    'UGT-sub_intro': 'UDP-Glucuronosyl Transferases (UGT) substrate; Category 1: Substrate; Category 0: Non-substrate.',
    'Hepatotoxicity_intro': 'The hepatotoxicity. Category 1: hepatotoxicity; Category 0: non-hepatotoxicity; The output value is the probability of being active.',
    'Mutagenicity_intro': 'This toxicity can be verified by the AMES test. Category 1: Mutagenicity positive(+); Category 0: Mutagenicity negative(-); The output value is the probability of being Mutagenicity positive(+).',
    'MDCK_intro': 'MDCK cell permeability; Apparent Permeability Coefﬁcient (Papp), measured in log10(cm/s). Poor absorption (absorption rate 0% to 20%): <-6;  moderate absorption (20% to 70%): -6 to -5;  good absorption (70% to 100%): >-5.',
    HLM_intro:
        'Human Liver Microsomal Stability, the probability to be stable in Human Liver Microsomal (HLM)(with a t1/2>30 min), the compound is highly possible to be stable in HLM if the value is close to 1.',
    CL_intro: 'Clearance in plasma, measured in ml/min/kg. Category 1: Substrate; Category 0: Non-substrate; The output value is the probability of being substrate.',
    T12_intro:'The terminal half-life (T1/2), measured in hours. Category 1: T1/2 +, with T1/2 ≤ 3, Category 0: T1/2 -, with T1/2 > 3; The output value is the probability of T1/2 +.',
    hERG_intro:
        'Category 1: active; Category 0: inactive; The output value is the probability of being active.',
    'H-HT_intro':
        'The hepatotoxicity. Category 1: hepatotoxicity; Category 0: non-hepatotoxicity; The output value is the probability of being active.',
    DILI_intro:
        'Drug Induced Liver Injury;  Category 1: drugs with a high risk of DILI; Category 0: drugs with no risk of DILI. The output value is the probability of being toxic.',
    Ames_intro:
        'Category 1: Ames positive(+); Category 0: Ames negative(-); The output value is the probability of being toxic.',
    ROA_intro:
        'Category 0: low-toxicity; Category 1: high-toxicity; The output value is the probability of being highly toxic.',
    FDAMDD_intro:
        'Maximum Recommended Daily Dose; Category 1: FDAMDD (+); Category 0: FDAMDD (-); The output value is the probability of being positive.',
    SkinSen_intro:
        'Category 1: Sensitizer; Category 0: Non-sensitizer; The output value is the probability of being sensitizer.',
    Carcinogenicity_intro:
        'Category 1: carcinogens; Category 0: non-carcinogens; The output value is the probability of being toxic.',
    EC_intro:
        'Category 1: corrosives ; Category 0: noncorrosives; The output value is the probability of being corrosives.',
    EI_intro:
        'Category 1: irritants ; Category 0: nonirritants;The output value is the probability of being irritants.',
    Respiratory_intro:
        'Category 1: respiratory toxicants, Category 0: respiratory nontoxicants; The output value is the probability of being toxic.',
    BCF_intro:
        'Bioconcentration factors are used for considering secondary poisoning potential and assessing risks to human health via the food chain; The unit is -log10[(mg/L)/(1000*MW)]',
    IGC50_intro:
        'Tetrahymena pyriformis 50 percent growth inhibition concentration; The unit is -log10[(mg/L)/(1000*MW)]',
    'IGC50 FM':
        '96-hour fathead minnow 50 percent lethal concentration; The unit is -log10[(mg/L)/(1000*MW)]',
    LC50DM_intro:
        '48-hour daphnia magna 50 percent lethal concentration; The unit is -log10[(mg/L)/(1000*MW)]',
    'NR-AR_intro':
        'Androgen receptor; Category 1: actives ; Category 0: inactives; The output value is the probability of being active.',
    'NR-AR-LBD_intro':
        'Androgen receptor ligand-binding domain; Category 1: actives ; Category 0: inactives; The output value is the probability of being active.',
    'NR-AhR_intro':
        'Aryl hydrocarbon receptor; Category 1: actives ; Category 0: inactives; The output value is the probability of being active.',
    'NR-Aromatase_intro':
        'Category 1: actives ; Category 0: inactives; The output value is the probability of being active.',
    'NR-ER_intro':
        'Estrogen receptor;  Category 1: actives ; Category 0: inactives; The output value is the probability of being active.',
    'NR-ER-LBD_intro':
        'Estrogen receptor ligand-binding domain;  Category 1: actives ; Category 0: inactives; The output value is the probability of being active.',
    'NR-PPAR-gamma_intro':
        'Peroxisome proliferator-activated receptor gamma,  Category 1: actives ; Category 0: inactives; The output value is the probability of being active.',
    'SR-ARE_intro':
        'Antioxidant response element; Category 1: actives ; Category 0: inactives; The output value is the probability of being active.',
    'SR-ATAD5_intro':
        'ATPase family AAA domain-containing protein 5; Category 1: actives ; Category 0: inactives; The output value is the probability of being active.',
    'SR-HSE_intro':
        'Heat shock factor response element; Category 1: actives, Category 0: inactives; The output value is the probability of being active.',
    'SR-MMP_intro':
        'Mitochondrial membrane potential; Category 1: actives, Category 0: inactives; The output value is the probability of being active.',
    'SR-p53_intro':
        'Category 1: actives, Category 0: inactives; The output value is the probability of being active.',
    'Veber Rule_name': 'Veber Rule',
    'Veber Rule_intro':
        'Compounds with nRot ≤ 10 and TPSA ≤ 140 or nHBD+nHBA ≤ 12 have better oral bioavailability.',
    'Lipinski Rule_name': 'Lipinski Rule',
    'Lipinski Rule_intro':
        'MW ≤ 500; logP ≤ 5; Hacc ≤ 10; Hdon ≤ 5. If two properties are out of range, a poor absorption or permeability is possible, one is acceptable.',
    'Pfizer Rule_name': 'Pfizer Rule',
    'Pfizer Rule_intro': 'Compounds with  logP (>3) and low TPSA (<75) are likely to be toxic.',
    'GSK Rule_name': 'GSK Rule',
    'GSK Rule_intro': 'Compounds with MW ≤ 400 and logP ≤ 4 are likely to be drug.',
    'Oprea Rule_name': 'Oprea Rule',
    'Oprea Rule_intro': 'Compounds with nRing ≥ 3; nRig ≥ 18 are likely to be drug.',
    'Golden Triangle_name': 'Golden Triangle',
    'Golden Triangle_intro': 'Compounds with 200 ≤ MW ≤ 50; -2 ≤ logD ≤ 5 are likely to be drug.',
    'Physicochemical-Property': 'Physicochemical Property',
    'Medicinal-Chemistry': 'Medicinal Chemistry',
    'Drug-Likeness': 'Drug Likeness',
    Absorption: 'Absorption',
    Distribution: 'Distribution',
    Metabolism: 'Metabolism',
    Excretion: 'Excretion',
    'Toxicity-Property': 'Toxicity Property',
    smiles_placeholder: 'Enter Multiple Lines of SMILES',
    task_intro:
        'Inno-ADMET module is designed to systematically evaluate the absorption, distribution, metabolism, excretion and toxicity properties of drugs, as well as some physicochemical properties and medicinal chemical properties, which provides two different models to calculate these properties: the pre-trained MERT model and the MGA substructure interpretability model based on GNN. Based on the independently collected high-quality data, both of these two models can realize the evaluation of 17 physicochemical properties, 5 pharmacochemical properties, 6 druglikeness rules, 21 ADME properties, and 27 toxic properties.. From the perspective of model evaluation metrics, MERT model is more excellent, and MGA model has more advantages in terms of whether the model can provide the influence of substructure on the prediction results. Users can choose the corresponding calculation model according to their own needs. '
  },
  admet_sa: {
    'Acute Toxicity_intro': '20 substructures,  acute toxicity during oral administration',
    'Genotoxic Carcinogenicity_intro': '117 substructures, carcinogenicity or mutagenicity',
    'NonGenotoxic Carcinogenicity_intro':
        '23 substructures, carcinogenicity through nongenotoxic mechanisms',
    'Skin Sensitization_intro': '155 substructures, skin irritation',
    'Aquatic Toxicity_intro': '99 substructures; toxicity to liquid(water)',
    NonBioDegradable_intro: '19 substructure; non-biodegradable',
    SureChEMBL_intro: '164 substructures, MedChem unfriendly status',
    'FAF-Drugs4_intro':
        '154 substructures collected form FAF-Drugs4 web server, Molecules containing these substructures may be toxic.',
    'FH Alerts_intro': 'Compounds with ChemFH substructural features.',
    'Acute Toxicity_name': 'Acute Toxicity',
    'Genotoxic Carcinogenicity_name': 'Genotoxic',
    'NonGenotoxic Carcinogenicity_name': 'NonGenotoxic',
    'Skin Sensitization_name': 'Skin Sensitization',
    'Aquatic Toxicity_name': 'Aquatic Toxicity',
    NonBioDegradable_name: 'NonBioDegradable',
    SureChEMBL_name: 'SureChEMBL',
    'FAF-Drugs4_name': 'FAF-Drugs4',
    'FH Alerts_name': 'FH Alerts',
    RMSD_intro:
        'Evluating the conformational differences of the same substructure between the reference molecule and the generated molecule. RMSD is a value between 0 and 1, where a smaller value represents a smaller conformational difference.',
    site_check_intro:
        'Based on the selection of atoms by reference molecules, to detect whether the generated molecules have generated the same atoms within the spatial range of 3 Å of that atom. If yes, it is true, and if no, it is false.',
    cluster_id: 'Cluster ID',
    algo: 'Algorithm',
    similarity_intro:
        'Using ECFP4 as the descriptor and Tc as the evaluation metric to compare the similarity between the generated molecules and the input reference molecules. Tc is a value between 0 and 1, where a value closer to 1 represents a higher similarity.',
    task_intro:
        'Inno-SA module is designed to predict the presence of toxic substructures in molecules, including acute toxicity, genotoxic carcinogenicity, nongenotoxic carcinogenicity, skin sensitization, aquatic toxicity, non-biodegradability, SureChEMBL toxicity, FAF-Drugs4 toxicity, and ChemFH substructure alerts. These substructures are the high-frequency substructures that summarized from a large number of experimental activity data. The Structure Alert module can accurately identify dangerous substructures, help users to understand molecules more clearly, and make users pay attention to some substructures through warning.'
  },
  relation: {
    task_intro:
        'RELATION(REceptor-LigAnd interacTION) module is a molecular generation method based on the 3D binding conformations of protein−ligand complexes and bidirectional transfer learning, which can efficiently generate molecules with massive valid structure and favorable binding affinity.In the current version, the platform has pre-trained 32 targets, users can only generate new molecules at those 32 targets. In the future, we will open user-defined models, and users can customize targets for molecular generation. Please look forward to it.'
  },
  rescoring: {
    task_intro:
        'Inno-Rescoring module provides some excellent scoring functions based on machine learning algorithms. To facilitate users to use our rescoring functions more directly, the Rescoring module supports users to upload relevant files for rescoring after local docking (currently, RTMScore are integrated, and we will release more re scoring functions later). Users can select these rescoring functions to re-evaluate the binding affinity of protein-ligand binding pose.'
  },
  screen: {
    显示内容: 'Display Options',
    白色背景: 'White Background',
    黑色背景: 'Black Background',
    显示水分子: 'Show Water',
    口袋透明度: 'Pocket Transparency',
    蛋白显示方式: 'Protein',
    配体显示方式: 'Ligand',
    更多操作: 'More Actions',
    显示配体: 'Show Ligand',
    回到中心: 'Center',
    截图: 'Screenshots',
    全屏: 'Full Screen',
    显示口袋: 'Show Pocket',
    选择配体: 'Select Ligand',
    请检查输入和网络连接: 'Sorry, please check your input and network connection!',
    柱状分布图: 'Distribution Histogram',
    统计饼图: 'Statistics Pie Chart',
    结合残基: 'Binding Residue ',
    亲和力: 'Binding Affinity ',
    结果列表: 'Results Table',
    数据选择: 'Selected Data:',
    打分函数: 'Score Function:',
    上传: 'Click to Upload pdb File',
    上传配体: 'Click to Upload mol2/csv/sdf File',
    对接类型: 'Docking Mode',
    柔性对接: 'Flexible Docking',
    半柔性对接: 'Semi-flexible Docking',
    post蛋白: 'Prepared Protein',
    上传post蛋白: 'Click to upload pdb file',
    post蛋白示例: 'Protein Example',
    post配体: 'Prepared Ligand',
    上传post配体: 'Click to upload sdf file',
    post配体示例: 'Ligand Example',
    post_zip: 'Docking Complex',
    上传post_zip: 'Click to upload pdb/zip file',
    post_zip示例: 'Complex Example',
    upload_zip_tip:
        'Upload pdb files for a single protein-ligand complex or zip packages containing multiple pdb files',
    重打分函数: 'Rescoring Function',
    蛋白配体文件: 'Protein and Ligand Files',
    pdb复合物: 'Protein-ligand Complex',
    IGN_name: 'IGN',
    RTMS_name: 'RTMScore',
    上传csvsdf: 'Click to Upload csv/sdf File',
    请选择csvsdf文件: 'Please select csv/sdf file',
    IGN_intro:
        'The machine learning algorithm based scoring function, the value is derived from ki/kd, and the larger the value, the better the binding affinity of the protein-ligand.',
    RTMS_intro:
        'The machine learning algorithm based scoring function, the value is a self-defined statistical potential, and the larger the value, the better the binding affinity of the protein-ligand.',
    task_intro:
        'Inno-Docking module is used to evaluate binding conformation and binding affinity of protein-ligand. Inno-Docking not only integrates the classical physical docking program  AutoDock Vina, but also integrates the self-developed AI docking programCarsiDock. Compared with physical methods, CarsiDock has significant advantages in the accuracy of docking conformation. In addition, Inno-Docking also provides complete protein preparation, ligand preparation, and automatic intelligently setting of docking parameters. and provides detailed data analysis capabilities on the result page.',
    提醒正在使用默认口袋:
        'You are using the default docking site provided by the system. Are you sure to use this docking site and submit the task?',
    提醒正在使用自定义口袋:
        'You are using a user-defined docking site. Are you sure to use this docking site and submit the task?',
    请输入正确PDBID: 'Please enter the correct PDB ID!',
    文件大小不能超过10M: 'This file exceeds the 10M limit. Please upload other files!',
    文件大小不能超过100M: 'This file exceeds the 100M limit. Please upload other files!',
    文件大小不能超过1GB: 'This file exceeds the 1GB limit. Please upload other files!',
    文件数量不能超过5000条: 'This file exceeds 5000. Please upload other files!',
    文件数量不能超过10000条: 'This file exceeds 10000. Please upload other files!',
    文件数量不能超过20000条: 'This file exceeds 20000. Please upload other files!',
    文件不能超过256列: 'The file cannot have more than 256 columns. Please upload other files!',
    请上传配体文件: 'Please enter ligand structure!',
    请输入PDBID或者选择示例: 'Please enter the PDB ID or choose an example!',
    请上传蛋白pdb文件: 'Please enter protein structure!',
    必须拥有手性:
        'Tip: For more accurate results, please generate a certain amount of isomers for your ligand(especially chiral isomers).',
    缺少原始配体:
        'The structure you input lacks the original ligand, we recommend that you choose Vina for subsequent calculations.',
    运行模式: 'Operation mode',
    数据中心导入pdb: 'Click to select pdb file',
    数据中心导入csvsdf: 'Click to select csv/sdf file',
    数据中心导入sdf: 'Click to select sdf file',
    数据中心导入pdf: 'Click to select pictures/pdf file',
    选择打分函数: 'Please select scoring function',
    选择数据: 'Please select data',
    请选择蛋白pdb文件: 'Please select protein file',
    参数不能为空: 'The parameter cannot be empty',
    没有配体: 'There is no ligand in the pdb file, please re-upload the complex pdb file',
    设置自定义口袋: 'Setting up a custom pocket',
    请删除不需要的链以减少计算时间: 'Please delete unnecessary chains to reduce computation time.',
    继续: 'Continue',
    修改: 'Edit',
    警告: 'Warning',
    解析提示: 'Note: Analysis of this molecular fragment has failed, unable to provide substructure contributions.',
    至少选择一条蛋白链: 'Choose at least one protein chain.'
  },
  screen_table: {
    数据: 'Data',
    打分函数: 'Score',
    Vina: 'One of the scoring functions in Autodock vina software, the unit is kcal/mol, the smaller the value, the better the binding affinity of protein-ligand.',
    'AutoDock 4':
        'One of the scoring functions in Autodock vina software, the unit is kcal/mol, the smaller the value, the better the binding affinity of protein-ligand.',
    Plp95: 'The larger the value, the better the protein-ligand binding affinity.',
    ChemPlp: 'The larger the value, the better the protein-ligand binding affinity.',
    Plp: 'The larger the value, the better the protein-ligand binding affinity.',
    Vinardo:
        'One of the scoring functions in Autodock vina software, the unit is kcal/mol, the smaller the value, the better the binding affinity of protein-ligand.',
    PLP: 'The larger the value, the better the protein-ligand binding affinity',
    CarsiScore:
        'The deep learning algorithm based scoring function, the value is derived from a combination of self-defined statistical potential and conformational rationalization loss, and the smaller the value, the better the generated conformation.',
    KarmaScore:
        'The built-in scoring function of KarmaDock. KarmaDock applied the mixture density networks to learn the optimal distance distribution between protein and ligand to evaluate its binding affinity, and the larger the KarmaScore, the better the binding affinity of the protein-ligand.',
    RTMScore:
        'The machine learning algorithm based scoring function, the value is a self-defined statistical potential, and the larger the value, the better the binding affinity of the protein-ligand.',
    IGN: 'The machine learning algorithm based scoring function, the value is derived from ki/kd, and the larger the value, the better the binding affinity of the protein-ligand.',
    残基统计:
        'In order to facilitate users to more intuitively understand the protein-ligand interaction mode, we counted the number of residues interacting with the ligand, and drew the pie chart with the first 10 residues with high binding frequency.',
    ID_intro:
        "The first number represents the order of the user's input, the second number represents the generated isomers, and the third number represents the conformation output for each molecule."
  },
  lig_prep: {
    task_intro:
        'Ligand Preparation module is a routine molecular processing tool. For the uploaded molecules, the platform first tests for atom valence exception, and if so, removes excess hydrogen; After that, molecules will be standardized by default, including disconnecting metal ions/salt ions, obtaining the largest fragment (removing other fragments), and hydrogenation; Some options are: adjust the protonation state, whether to generate tautomer, stereoisomer. The molecules processed by this module can be used in QSAR, Docking and other modules of the platform.'
  },
  spec: {
    解谱方法: 'Select Method',
    输入碳谱: '13C Spectrum',
    send: 'To SMILES Box',
    分子式: 'Mol Formula',
    数字输入不正确: 'Unvalid number',
    末尾不允许输入逗号: 'Trailing comma is not allowed',
    不允许输入特殊字符: 'Characters other than numbers, spaces and commas are not allowed',
    分子式placeholder: 'Input Molecular Formula',
    smiles_placeholder: 'Input the SMILES for the Identified Molecular Fragment',
    smiles_title: 'Input the SMILES for the Identified Molecular Fragment',
    绘制的SMILES: 'SMILES Drawed',
    spectrum_title: 'NMR Parsing',
    spectrum_CRESS:'NMR Parsing-CRESS',
    spectrum_CMGnet:'NMR Parsing-CMGnet',
    task_intro:
        'Structure elucidation of unknown compounds based on nuclear magnetic resonance (NMR) is one of the inverse problems that remain challenging in chemical science. Finding new methods to tackle the inverse problem of structure elucidation will aid researchers in accelerating chemical discovery, especially for the research of organic chemistry and natural product chemistry. AI assisted Structure Elucidation module mainly applies AI technology to quickly analyze carbon spectrum and help experts identify molecular structure. Here, we provide two different methods including CReSS and CMGNet to elucidate the structure of unknown compounds.'
  },
  mcmg: {
    task_intro:
        'MCMG Training is a custom modeling module based on the MCMG(Multi-Constraint Molecular Generation) method. MCMG is a ligand-based molecular generation method, which can satisfy multiple constraints through knowledge distillation combined with conditional converters and QSAR based reinforcement learning algorithm, and generate new molecules with desired physicochemical and pharmacological properties. If users want to generate active molecules of a certain target, they need to upload molecules with activity data under the target, and set the property constraints of the generated molecules. The result page will provide the performances of the model to determine the reliability of the molecules generated. If you need to use the constructed MCMG model to generate molecules, please jump to the "Ligand-based" module of Molecular Generation. '
  },
  qsar: {
    只支持二分类: 'Note: Our system currently only supports the construction of binary classification models. Please ensure that your label column contains only two unique values.',
    基于描述符: 'Descriptor-based',
    基于结构: 'Structure based',
    模型参数: 'Modeling',
    '文件内应包含SMILES、活性值': 'SMILES and active value should be included',
    描述符: 'Select Descriptor',
    模型类型: 'Select Model Type',
    SMILES列: 'Select SMILES',
    smiles列: 'SMILES',
    标签列: 'Select Label Column',
    正例值: 'Select Positive Value',
    点击上传csv文件: 'Click to Upload csv File',
    csv示例: 'csv example',
    文件内容: 'Content',
    数据概览: 'Data Overview',
    模型应用: 'Model Application',
    选择任务: 'Select QSAR Task',
    数据分布: 'Data Distribution',
    '数据分布-分类': 'Data Distribution-Classification Model',
    '数据分布-回归': 'Data Distribution-Regression Model',
    碳骨架分析: 'Scaffold Analysis',
    '结果详情-分类': 'Result analysis - Classification Model',
    模型: 'Model',
    指标: 'Metrics',
    准确率: 'Accuracy',
    精确率: 'Presicion',
    召回率: 'Recall',
    以: 'Take',
    为正例值: 'as the positive value',
    真阳性率: 'True positive rate',
    假阳性率: 'False positive rate',
    测试文件: 'Test File',
    选择模型: 'Select Model',
    小型数据测试:
        'It is only applicable to small data tests (only the first 100 pieces of data are output), and the system will not retain your test results. If you need to use large data or retain prediction results, please jump to the ',
    QSAR模型应用: 'QSAR model application',
    模块: ' page.',
    开始测试: 'Start Testing',
    分类模型预测: 'Classification Model Prediction',
    MCC_intro:
        'The Matthews Correlation Coefficient (MCC) is a measure of the correlation between two binary variables. It is a value between -1 and +1, where +1 represents perfect agreement, 0 represents random agreement, and -1 represents total disagreement. For imbalanced datasets and small sample datasets, it often performs better than common classification performance metrics such as accuracy, precision, and recall.',
    准确率_intro:
        "Accuracy = (TP + TN)/(TP + TN+FP+FN)，the proportion of correctly predicted sample categories in the total sample.It's value range is 0-1, the higher the value, the better the accuracy of the model.",
    'ROC-AUC_intro':
        'The closer the ROC curve is to the upper left corner, the larger the area under the curve (AUC), which means the better the model performance. If AUC=0.5, that is, the ROC curve coincides with the dotted line in the above figure, it means that the models discrimination ability is not different from that of random guess.',
    'PRC-AUC_intro':
        'The closer the PRC curve is to the upper right corner, the larger the AUC,the better performance of the model.',
    'F1-Score_intro':
        "F1-score = 2 ∗ Precision ∗ Recall / (Precision + Recall), F1-Score is the harmonic value of Accuracy and Recall. It's value range is 0-1,the higher the value, the better performance of the model.",
    精确率_intro:
        "Precision= TP/ (TP+FP), the proportion of true positive samples among all predicted positive samples. It's value range is 0-1,the higher the value, the better performance of the model.",
    召回率_intro:
        "Recall =TP/ (TP + FN), The proportion of true positive samples among all the actual positive samples, also called TPR. It's value range is 0-1,the higher the value, the better performance of the model.",
    '数据划分-分类': 'Training set: Test set =4:1(by Label). ',
    '数据划分-分类_intro':
        'The positive set(1 or active) and negative set (1 or active) corresponding to the scaffold information of SMILES were analyzed. In order to ensure that the compounds in the test set and the training set have similar chemical space,  4-1 batches will be divided according to the scaffold (when the same scaffold was less than 2, the molecule is classified as the training set). Finally, the data set after combining the positive set and the negative set is used as the training set and the test set.',
    '数据划分-回归': 'Training set: Test set =4:1(by Molecule Scaffold). ',
    '数据划分-回归_intro':
        'To ensure that the compounds in the test set and the training set have similar chemical space, based on the results of scaffold analysis, 4-1 batches will be divided according to the skeleton (when the same scaffold is less than 2, the molecule will be classified as the training set) ',
    '算法-分类':
        'the combination method of "grid search" + "5-fold cross-validation" is applied to determine the optimal parameters, and the optimal algorithm is determined according to AUC_ROC. Finally, the optimal algorithm and the optimal parameters are applied to construct the model.',
    '算法-回归':
        'the combination method of "grid search" + "5-fold cross-validation" is applied to determine the optimal parameters, and the optimal algorithm is determined according to R2. Finally, the optimal algorithm and the optimal parameters are applied to construct the model.',
    模型可解释性: 'SHAP Value',
    模型可解释性_intro:
        'This is an importance ranking chart. Unlike traditional feature importance, SHAP can reflect the influence of features in each sample through SHAP Value, and also show the positive and negative effects.',
    算法: 'Algorithm',
    建模参数: 'Modeling Algorithm',
    描述符号: 'Descriptor',
    数据划分方法: 'Data Partition Method',
    结果详情: 'Result analysis',
    回归模型预测: 'Regression Model Prediction',
    真实值: 'True Value',
    预测值: 'Predicted Value',
    MAE_intro:
        'Mean Absolute Error(MAE),it can be used to reflect the actual situation of the predicted value error.',
    MSE_intro:
        'Mean Squared Error(MSE),it can be used to measure the difference between the predicted value and the true value.',
    RMSE_intro:
        'Root Mean Square Error(RMSE)，which represents the standard deviation of the difference between the predicted value and the observed value.',
    'R²_intro':
        'Determination coefficient，which reflects the accuracy of model fitting data, and the closer R2 is to 1, the better the model fits the data.',
    PCCs_intro:
        'Pearson Correlation Coefficient(PCCs),it can be used to measure the degree of linear correlation between two variables. The value range of correlation coefficient is [- 1,1]. The larger the absolute value of the correlation coefficient, the higher the correlation.',
    '结果详情-回归': 'Result analysis - Regression Model',
    文件内应包含SMILES: 'SMILES should be included',
    模型的类型: 'Model Type',
    模型详情: 'Model Details',
    评估结果: 'Evaluation results',
    分类模型: 'Classification Model',
    回归模型: 'Regression Model',
    task_intro:
        'Inno-QSAR module is designed to help users build custom classification/regression models based on their own data. The platform not only supports traditional descriptor-based machine learning methods such as XGBoost and random forest. Meanwhile, it also provides pre-training models based on deep learning methods such as GNN and Transformer, which can help users to improve the modeling performance in the case of small data to a certain extent.',
    application_task_intro:
        'Inno-QSAR Application is a module derived from Inno-QSAR. The model built by users in Inno-QSAR can be used in Inno-QSAR Application module to predict new data. Users only need to select/upload data and select the model to get the prediction results of the model. ',
    '分类模型-预测的标签饼图': 'Classification Model- Pie Chart of Prediction Label',
    '回归模型-预测概率值的柱状分布图':
        'Regression Model- Histogram Distribution of Predicted Probability Values',
    模型类: 'Model Type',
    roc曲线: 'ROC Curve',
    'p-r曲线': 'P-R Curve',
    真实值与预测值的相关性: 'Correlation between true and predicted values',
    纯碳链骨架: 'Pure Carbon Chain Scaffold',
    roc曲线_intro:
        'The receiver operating characteristic (ROC) curve  is a curve drawn on the basis of different classification thresholds with TPR as the y-axis and FPR as the x-axis.',
    'p-r曲线_intro':
        'PR curve is a curve drawn on the basis of different classification thresholds with Precision as the y-axis and Recall as the x-axis. Different from ROC, Precision is affected by sample imbalance, and the shape of corresponding PRC will change accordingly. Therefore, when using large sample data, ROC will be relatively stable, and it is generally appropriate to select ROC-AUC to evaluate the model. When the threshold is determined, Precision, Recall and F1 score can be used to evaluate the model.',
    epochs_tr:
        'The value can be adjusted from 1 to 50, depending on the size of the data set. and the default value is 10. A large value will result in a long training time, and the parameter is too large for the risk of overfitting.',
    batch_tr:
        'The optional values for this parameter are 16, 32, 64, 128, and the default value is 32. A large value have a fast training speed. But a too large value will lead to a less gradient diversity and cannot be optimized to the local optimum; a too small batchsize will lead to loss shock and a slow convergence speed.',
    rate_tr:
        'The value can be adjusted from 0.00001 to 0.0002, and the default value is 0.0001. A large value will be difficult for model oscillation to converge, and a small value will lead to a slow convergence rate, resulting in the failure to find the minimum value of loss at the end of training.',
    epochs_gnn:
        'The value can be adjusted from 1 to 50, and the default value is 10. The system adopts the early stop training strategy here. The model will automatically stop training when the evaluation metrics declines. So a bigger value will be better.',
    batch_gnn:
        'The optional values for this parameter are 16, 32, 64, 128, and the default value is 32. A large value have a fast training speed. But a too large value will lead to a less gradient diversity and cannot be optimized to the local optimum; a too small batchsize will lead to loss shock and a slow convergence speed.',
    rate_gnn:
        'The value can be adjusted from 0.0001 to 0.0003, and the default value is 0.0001. A large value will be difficult for model oscillation to converge, and a small value will lead to a slow convergence rate, resulting in the failure to find the minimum value of loss at the end of training.',
    smiles和label保持一致:
        'Tip:To facilitate subsequent calculations, please ensure that the smiles column names and Label column names in multiple files you upload are consistent!',
    无需批分:
        'You have uploaded the complete data set, there is no need to do data partition, please directly click Next Step.',
    批分test:
        'Since you have not uploaded the Test Set, obtain your Test Set according to the following parameters:',
    test_ratio: 'Test Set Ratio',
    可选项: 'Optional',
    label_intro: 'The output value is a predicted probability value ranging from 0 to 1.',
    probability_intro:
        'With 0.5 as the boundary, a predicted probability value > 0.5 is considered as a positive case.',
    prediction_intro:
        'The output value is related to the training set data for building the regression model. '
  },
  virtual: {
    不支持自定义口袋: 'Custom pockets are not supported',
    选择虚拟筛选数据: 'Select Virtual Screening Data',
    上传蛋白质并确认口袋: 'Upload Protein and Confirm Pocket',
    自定义虚拟筛选: 'Custom Virtual Screening Pipeline',
    数据库: 'Database',
    输入数据: 'Input Data',
    输入蛋白: 'Input Protein',
    虚筛流程: 'Virtual Screening Pipeline',
    task_intro:
      'Virtual screening is to find hit compounds with new structure scaffold and preliminary biological activities from the database for specific targets. CarbonSilicon AI is committed to provide users with a set of available drug design tools. By using some commonly used databases or AI custom libraries generated by molecular generation, combined with the high-precision calculation tools developed by the DrugFlow, users can customize the virtural screening pipeline to find potential hit compounds.',
    筛选条件不能为空: 'The filter cannot be empty',
    最小值不能大于最大值: 'The minimum value cannot be greater than the maximum value',
  },
  extraction: {
    分子: 'Molecular',
    图表: 'Table',
    已选择: 'Selected',
    条: 'Items',
    删除无编号分子: 'Delete Molecules without Numbers',
    批量删除分子: 'Batch Delete',
    添加分子: 'Add Molecules',
    编号: 'Number',
    溯源: 'Traceability',
    其他属性: 'Other Attributes',
    编辑结构: 'Edit Structure',
    当前定位内容第: 'Current location content',
    个: '',
    共: 'Total',
    重新定位: 'Reposition',
    删除定位: 'Delete location',
    新增定位: 'New positioning',
    自动缩放: 'Auto Zoom',
    操作: 'Actions',
    化学结构: 'Structure',
    确认删除分子: 'This operation cannot be restored. Are you sure to delete the selected molecule?',
    确认删除表格: 'This operation cannot be restored. Are you sure to delete the selected form?',
    修改成功: 'Modified successfully!',
    修改失败: 'Modification failure!',
    复制条目: 'Copy entry',
    复制smiles: 'Copy smiles',
    暂无可用数据: 'No data available',
    新增图表: 'New chart',
    查看: 'Unfold',
    收起: 'Fold',
    新增母核结构: 'New parent nucleus structure',
    母核结构: 'Parent nucleus structure',
    正在解析: 'Processing...',
    关联编号: 'Associated number',
    '插入行/列': 'Insert rows/columns',
    删除: 'Delete',
    拼接: 'Splice structure',
    关联所有: 'Associated ownership',
    关联当前行: 'Associate current row',
    向上添加: 'Add up',
    向下添加: 'Add down',
    向左添加: 'Add left',
    向右添加: 'Add right',
    行: 'line',
    列: 'column',
    删除整行: 'Delete entire line',
    删除整列: 'Delete entire column',
    选择母核编号: 'Select parent number',
    选择拼接的列名: 'Select concatenated column name',
    仅拼接当前列: 'Concatenate only the current column',
    拼接所有同名列: 'Concatenate all the same names',
    点击此处: 'click here',
    图表解析中: 'The chart is being analyzed. Please wait a moment, or ',
    请勿退出: ' to view other information on the page. <br />Do not leave the current results page until you receive a prompt.',
    请校验: 'Please check',
    选择提取范围_intro: 'Enter the page numbers you need to extract. You can use a "," to select individual pages and a "-" to select a range of consecutive pages. Click the "confirm" button to quickly select the corresponding pages.',
    拼接表格: 'Spliced table',
    拼接成功: 'Splicing success',
    拼接失败: 'Splicing failure',
  },
  api: {
    创建: 'Create New APP',
    无权限: 'Sorry, No Permission',
    无权限描述: 'You have no permission to access this function, please contact the developer.',
    api名字: 'APP Name',
    请求新的appkey: 'Request Token and Private Key',
    api文档: 'APP Document',
    token: 'Appid',
    secret: 'Secret Token',
    已安装: 'Created',
    停用: 'Disable',
    启用: 'Enable',
    已停用: 'Disabled',
    已启用: 'Enabled',
    tips: 'This key is only displayed once, please store it safely'
  },
  pipeline: {
    新建Pipeline: 'Create a new pipeline'
  },
  setting: {
    公开信息: 'Public Info',
    full_name: 'Full Name',
    first_name: 'First Name',
    last_name: 'Last Name',
    Biography: 'Biography',
    Biography_placeholder: 'Input Your Biography',
    location: 'Location',
    location_placeholder: 'Input Your Location',
    私人信息: 'Secret Info',
    avatar: 'Avatar',
    更新头像: 'Update Avatar',
    更新头像提示: 'Upload .jpg File'
  },
  molgen: {
    pocket_task_intro:
        'Pocket-based 3D molecular generation Method, the most important advantage of the method is that it can generate molecules with good binding affinity and reasonable protein-ligand binding pose without the active molecular data of specific target, as well as ensured the molecular diversity to a certain extent. This method is not only suitable for De novo Drug Design, but also supports the generation of new molecules based on fragments. At present, we provide ResGen, and more methods will be launched in the future. ',
    ligand_task_intro:
        'Ligand-based molecular generation methods are mainly used in De Novo Drug Design. This module currently supports two methods: MCMG can generate molecules with ideal characteristics. For the MCMG model with good performance, users can select the corresponding model and generate molecules; RELATION can efficiently generate molecules with massive valid structure and favorable binding affinity. In the current version, the platform has pre-trained 32 targets, users can only generate new molecules at those 32 targets. In the future, we will open user-defined models, and users can customize targets for molecular generation. Please look forward to it.',
    MCMG_train: 'MCMG Training',
    RELATION_train: 'RELATION Training',
    基于口袋: 'Pocket-Based',
    基于配体: 'Ligand-Based',
    cluster: 'Cluster',
    counts: 'Counts',
    Favourite: 'Favorite List',
    cluster_type: 'Cluster Method',
    Gen_more: 'Generate More',
    生成中: 'Generating',
    algo: 'Method',
    类别数量: 'Molecule Counts',
    Property_filter: 'Property',
    Function_filter: 'Function Group',
    Userdefine_filter: 'User-define Fragment',
    amine: 'Amine',
    carboxyl: 'Carboxyl',
    carbonyl: 'Carbonyl',
    phenyl: 'Phenyl',
    halogen: 'Halogen',
    methyl: 'Methyl',
    thioether: 'Thioether',
    phenol_hydroxyl: 'Phenol Hydroxyl',
    aryl_thioether: 'Aryl Thioether',
    aniline: 'Aniline',
    sulfate_ester: 'Sulfate Ester',
    imine: 'Amine',
    hydroxyl: 'Hydroxyl',
    aryl_nitro: 'Aryl Nitro',
    trifluoromethyl: 'Trifluoromethyl',
    aryl_sulfate_ester: 'Aryl Sulfate Ester',
    alkyne: 'Alkyne',
    alkene: 'Alkene',
    alkane: 'Alkane',
    点击选择需要保留原子: 'Click to Keep the Atom',
    已选择的原子: 'Selected Atoms',
    生成类型: 'Type',
    从头生成: 'De Novo',
    片段生长: 'Fragment Generation',
    生成方法: 'Method',
    蛋白文件: 'Protein',
    Database: 'Database',
    复合物: 'Complex',
    片段来源: 'Fragment',
    选择保留片段: 'Reserve Fragment',
    选择口袋: 'Pocket',
    请先选择复合物口袋: 'Please choose a pocket first',
    请先上传配体: 'Please upload a ligand first',
    文件下载中: 'Downloading',
    无MCS: 'No MCS',
    选择MCMG模型: 'MCMG Model',
    创建新的MCMG模型: 'Create a New MCMG Model',
    生成成功: 'Generation Success',
    自定义QSAR: 'Inno-QSAR Application',
    暂无自定义: 'No Inno-QSAR Application',
    计算: 'Compute',
    计算新属性: 'Compute New Metric',
    亲和力预测: 'Binding Affinity Prediction',
    相似分子: 'Smiliar Structures',
    qsar任务下发成功: 'qsar task has been sent',
    至少选择一个QSAR模型: 'Please select at least one QSAR model',
    已经计算过了: 'Already computed',
    计算中: 'Computing',
    模型来源: 'Model Source',
    平台模型: 'Platform Model',
    用户自定义模型: 'User-defined Model',
    选择激酶靶标: 'Select Target',
    mcmg子模型: 'MCMG Model',
    预测属性: 'Predict Property',
    靶标: 'Target',
    警告: 'Warning',
    难以生成:
        'This task is difficult to generate. You can try other pockets or reduce the retained ligand atoms',
    新分子生成: 'New molecules are ready, ',
    查看新的分子: 'to see new molecules',
    此处: 'click here',
    请至少保留一个配体原子: 'Please keep at least one ligand atom',
    蛋白不存在自带配体: 'The protein does not have a ligand, please upload a ligand',
    超过5个:
        'Make sure that your number of comparison molecules does not exceed 5 (including reference molecules)',
    docking_wrong:
        'Protein preprocessing error led to docking failure, only the 2D information of the generated molecules is displayed.'
  },
  molecule: {
    显示原子序号: 'Show Atom Index',
    滑动更改Bond宽度: 'Sliding to Change Bond Width',
    test_comma: 'test comma',
    数据选择: 'Selected Data'
  },
  data: {
    文件名不能为空: 'The file name cannot be empty',
    文件名字重复: 'The file name is the same. Please modify it and try again',
    数据量过大: 'The saved dataset contains over 500 molecules, please wait patiently for the file to save successfully before carrying out further operations.',
    不支持查看: 'Not support view large data file content',
    data_center_intro:
        'On this page, you can view the uploaded and saved data and create new tasks.',
    创建新任务: 'Create a new task',
    输入数据名称: 'Enter data name',
    搜索: 'Search',
    上传新数据: 'Upload New Data',
    确认批量删除: 'Confirm Batch Delete',
    确认删除: 'Confirm Delete',
    批量删除: 'Batch Delete',
    请先选择数据: 'Please select data first.',
    部分数据删除失败: 'Some data deletion failed.',
    删除数据: 'Delete Data',
    完成删除: 'Delete Completed',
    删除成功: 'Deletion Successful',
    删除失败: 'Deletion Failed',
    您还未产生任何个人数据: 'You have not generated any personal data yet. Please upload new data,',
    请: 'Please',
    'Upload New Data': 'Upload New Data',
    创建WorkSpace: 'Create WorkSpace',
    请先创建您的WorkSpace:
        'Please create your WorkSpace first, then the data you upload and save can be viewed in the data center of your current WorkSpace. You can create a new WorkSpace or switch to another WorkSpace at any time based on different purposes.',
    设置为默认WorkSpace: 'Set as the default workSpace',
    WorkSpace名称: 'WorkSpace Name',
    上传文件: 'Upload Data',
    数据名称: 'Data name',
    点击上传sdfcsvpdb: 'Click to Upload file (.sdf/.csv/.pdb)',
    选择数据: 'Selective Data',
    数据详情: 'Data Detail',
    查看: 'View',
    存在相同的数据集:
        'There is a duplicate dataset name. If your two datasets have the same content, please select that dataset from the data center. If the two files have different contents, please rename your file before uploading it.',
    数据集名称重复: 'There is a duplicate dataset name, please rename your file before saved it.',
    切换space: 'Switch WorkSpace',
    数据中心为空:
        'The current WorkSpace is empty. Please select the method of uploading files to submit the task. If you have already uploaded data but it is not displayed in the WorkSpace, please refresh your page.',
    数据集名字重复: 'The data set has the same name. Please change the name and upload it',
    拓展名不变: 'unsupport change file extension, please keep it same'
  },
  protein_pre: {
    protein_preparation: 'Protein preparation',
    protein_pre_tips: 'If your protein has been preprocessed, you can proceed to the next step directly. If not, we recommend you to toggle the switch to perform preprocessing-related operations.',
    title_tips:
        'The Protein Preparation module is a routine protein preprocessing tool. For the uploaded protein file (.pdb), the platform will first analyze the structure of the file to determine the structure composition, and then check the problematic structures and missing residues, provide relevant repair capabilities. Meanwhile, it also supports protein optimization, including hydrogenation, adjustment of protonation state, optimization of hydrogen networks, and energy minimization. The molecules processed by this module can be used in other modules on the platform, such as Docking and Molecular Factory.',
    step1: '1.Keep Structure',
    step2: '2.Structure Issues',
    step3: '3.Missing Residues',
    step4: '4.Refinement',
    upload_file: 'Upload File',
    from_database: 'From Database',
    keep_polymer: 'Keep Polymer',
    keep_het_group: 'Keep Het Group',
    het_name: 'Het Name',
    water_5a: 'Water(Within 5 Å)',
    quick_delete: 'Quick Delete',
    delete: 'Delete',
    water_label: 'waters that do not interact with Het Group',
    chain: 'Chain',
    residue_num: 'Residue No.',
    type: 'Type',
    details: 'Details',
    miss_residue_sequence: 'Missing Residue Sequence',
    add_hydrogens: 'Add Hydrogens',
    modify_ph: 'Modify Protonation at pH',
    optimize_network: 'Optimize Hydrogen Bonding Network',
    energy_minimization: 'Energy Minimization',
    task_name: 'Task Name',
    previous: 'Previous',
    next: 'Next',
    correct: 'Correct',
    submit: 'Submit',
    white_bg: 'White Background',
    black_bg: 'Black Background',
    center: 'Center',
    screenshots: 'Screenshots',
    fullscreen: 'Full Screen',
    step3_tips:
        'It was found that the PDB file you uploaded is missing some one-dimensional information. Please supplement it:',
    part_miss_que: 'Enter the missing sequence',
    all_miss_que: 'Enter the entire sequence',
    part_miss_que_tips: 'Please enter the type of missing residue',
    all_miss_que_tips: 'Please paste the entire protein sequence',
    no_data: 'No data available',
    ok: 'OK',
    save_file: 'Save File',
    name: 'Name',
    input_protein: 'Input Protein',
    select_to_keep_polymer: 'Select to Keep Polymer',
    select_water: 'Select to Keep Het Group and Water',
    select_water_tips:
        'The system will automatically delete the water outside of the 5Å range, and the water within the 5Å range is decided by the user whether to delete it or not. Water molecules that have a water-bridge role are specially marked, and you can use the "Quick Delete" button to delete water molecules that do not have a water-bridge role in one step.',
    correct_structure: 'Correct Erroneous Residue Structures',
    repair_sequence: 'Repair Missing Residue Sequences',
    optimize_protein: 'Optimize Protein',
    input_pdbid: 'input PDB ID',
    disulfide_bond: 'Disulfide Bond',
    disulfide_bond_tips:
        'All possible disulfide bond pairs in the protein are shown here. By default, the disulfide bond in the range of 1.5 ~ 3A is selected, which can be adjusted according to your research purpose. Special attention should be paid to the fact that the selected disulfide bond pair will be retained during protein pretreatment, which has a great impact on protein conformation.'
  },
  manage: {
    admin: 'Administrator',
    manager: 'Administrator',
    member: 'Regular Member',
    团队人数: 'Team Size',
    成员管理: 'Member Management',
    已有成员数量: 'Existing Members Number',
    邀请新成员: 'Invite New Members',
    序号: 'Serial Number',
    姓名: 'Name',
    邮箱: 'Email',
    团队角色: 'Team Role',
    电话号码: 'Phone Number',
    工作职务: 'Job Title',
    移出: 'Remove',
    添加成员: 'Add Members',
    通过邮箱直接邀请: 'Directly Invite via Email',
    请输入邮箱: 'Please enter the email, multiple emails are separated by commas',
    系统会邀请注册: 'Not yet registered email address, the system will invite to register',
    添加: 'Add',
    取消: 'Cancel',
    提示: 'Tips',
    账号已成功加入您的团队: ' has successfully joined your team.',
    账号暂未注册DrugFlow: ' has not yet registered for DrugFlow,',
    账号已经加入了团队: ' has already joined the team.',
    是否邀请该成员注册DrugFlow网站:
        'Do you wish to invite this member to register for the DrugFlow website?',
    请输入有效邮箱地址: 'Enter a valid email address.',
    团队成员已达上限:
        'The number of team members has reached the limit. Please remove current members before inviting new ones, or contact the platform administrator to upgrade your team permissions.',
    状态: 'Registration Status',
    未注册: 'Unregistered',
    已注册: 'Registered',
    团队名称: 'Team Name',
    所属团队: 'Team Name',
    团队成员上限: 'Team Member Limit',
    操作: 'Operation',
    团队列表: 'Team List',
    添加成功: 'Add Successfully',
    添加团队: 'Add Team',
    未激活: 'Unactivated',
    已激活: 'Activated',
    请输入搜索关键字: 'Please enter the search keyword',
    我的工作台: 'My workbench',
    个人中心: 'User Center',
    团队管理: 'Team Management',
    团队数量: 'Number of Teams',
    团队成员数量: 'Number of Team Members',
    注册管理: 'Registration Management',
    总注册人数: 'Total Registered Users',
    总激活人数: 'Total Activated Users',
    个人用户: 'Individual Users',
    今日提交任务数量: 'Number of Tasks Submitted Today',
    总任务数量: 'Total Number of Tasks',
    已完成: 'Completed',
    进行中: 'In Progress',
    账户余额: 'Balance',
    消费记录: 'Spending Record',
    在线充值: 'Top-up',
    温馨提示:
        "Tips: When your balance is insufficient and you wish to continue using DrugFlow, please scan the WeChat QR code below to add our staff, or send an email to service{'@'}carbonsilicon.ai.",
    工作人员二维码: 'Staff QR Code',
    各模块使用频率统计图: 'Usage Frequency Statistics Chart for Each Module',
    近7天: 'Last 7 Days',
    近30天: 'Last 30 Days',
    全部: 'All',
    管理中心: 'Management Center',
    登出: 'Log Out',
    您好: 'Hello！',
    功能快捷入口: 'Function Shortcut',
    有权限: 'Authorized',
    无权限: 'Unauthorized',
    导入表格: 'Import Spreadsheet',
    试用状态: 'Trial Status',
    审核通过: 'Approved',
    审核不通过: 'Not Approved',
    用户类型: 'User Type',
    付费用户: 'Paid User',
    非付费用户: 'Non-paid User',
    有效期: 'Validity Period',
    联系方式: 'Contact Method',
    注册时间: 'Registration Time',
    激活时间: 'Activation Time',
    最新任务时间: 'Latest task time',
    新建团队: 'Create New Team',
    注册用户列表: 'Registered Users List',
    注册新用户: 'Register New User',
    用户名: 'User Name',
    新增用户: 'Add User',
    单位名称: 'Unit Name',
    删除: 'Delete',
    激活状态: 'Activation Status',
    添加用户: 'Add User',
    提交: 'Submit',
    创建APP: 'Create APP',
    团队成员管理: 'Team Member management',
    缺失管理员提示: 'Your modification failed! Each team needs at least one administrator. If you need to adjust managerial roles, please first designate the new account as an administrator, then modify the managerial privileges of the old account.',
    删除团队: 'Once deleting this team, the team will be disbanded, and the funds in its account will also be cleared. Are you sure you want to delete this team?',
    移出成员: 'After removing this account, it will depart from the team and no longer have any team-related privileges. Are you sure you want to remove this account?',
    操作失败: 'Operation Failed',
    请添加用户: 'Please Add A User',
    请添加团队: 'Please Add A Team',
    Token余额: 'Token Balance',
    已用Token: 'Token Used',
    实际计算费用: 'Actual cost',
    权限配置: 'Permission Configuration',
    充值: 'Top-up',
    充值金额: 'Recharge Amount (RMB)',
    请输入正整数: 'Please enter a positive integer',
    用户标签: 'User Label',
    高校: 'University',
    非高校: 'Non-university',
    消费Token: 'Consume Token',
    消费时间: 'Consumption Time',
    消费账号: 'Consumption Account (Name)',
    充值方式: 'Recharge Method',
    充值Token: 'Recharge (Token)',
    充值前Token余额: 'Token Balance Before Recharge',
    充值后Token余额: 'Token Balance After Recharge',
    订单总额: 'Order Total',
    点击刷新二维码: 'Click to Refresh QR Code',
    元: 'yuan',
    关闭二维码提示: 'Closing this QR code will close the current order, please confirm',
    用户信息确认: 'User Information Confirmation'
  },
  molecular_factory_error: {
    101213:
        'The platform cannot resolve the ligand in the structure, please change the pdb structure and try again.',
    101214:
        'The platform cannot resolve the ligand in the structure, please change the pdb structure and try again.'
  },
  common_error: {
    无数据:
        'When the task terminates, no molecule has completed the calculation, so it is not possible to proceed to the results page.',
    100: 'Unknow error',
    101: 'Parameter parse error',
    102: 'Missing essential parameter',
    103: 'This parameter is not alignment between front-end and back-end',
    104: 'Invalid setting value of parameter',
    105: 'Invalid action',
    106: 'Errors occurred during query parameter',
    107: 'Errors occorred in algorithm callback procedure',
    108: 'Internal error in algorithm',
    109: 'Description The number of tasks running at the same time exceeded the upper limit by three',
    110: `<div style="display: flex;flex-direction: column;align-items: center">The free computation quota provided by the current system has been exhausted. If you wish to continue using DrugFlow, please scan the WeChat QR code below to add the staff's enterprise WeChat for recharge. Thank you for your trust and support in CarbonSilicon AI's DrugFlow —— an AI-driven one-stop innovative drug discovery platform.<br /><img style="width: 180px;margin-left: -20px;" src="${img}"><br /></div>`,
    199: 'You cannot submit a new task because your DrugFlow license has expired',
    '199_upload': 'You cannot upload file because your DrugFlow license has expired',
    101217: 'The customized pocket is unreasonable, please adjust your pocket information and initiate it again.'
  },
  outer_service_error: {
    400: 'Failed to Trigger Cloud Service'
  },
  docking_error: {
    101301: 'Lack of protein file',
    101302: 'Lack of ligand file',
    101303: 'jason failed to parse the Inno-Docking parameter',
    101304: 'Essential parameter is missing in Inno-Docking',
    104300: 'Errors occurred during parsing ligand file ',
    104301: 'Errors occurred during parsing pdb file ',
    100302: 'Errors occurred during praparing missing residue',
    104303: 'Lack of docking task id or job id',
    107304: 'Inno-Docking failed to Trigger Cloud Service',
    107305: 'Errors occurred during the ligand preparation',
    107306: 'Errors occurred during the protein preparation',
    107307: 'Errors occurred during the docking process',
    108308: 'Errors occurred during docking result callback '
  },
  admet_error: {
    101401: 'Lack of smiles',
    101402: 'jason failed to parse the Inno-ADMET parameter',
    101403: 'Essential parameter is missing in Inno-ADMET',
    108404: 'Errors occorred in MGA algorithm',
    108405: 'Errors occorred in Inno-SA algorithm',
    108406: 'Errors occorred in ChemFH algorithm',
    301: 'Please input correct smiles',
    302: 'Not support GPU',
    400: 'Inno-ADMET failed to Trigger Cloud Service'
  },
  relation_error: {
    101501: 'jason failed to parse the RELATION parameter',
    101502: 'Essential parameter is missing in RELATION',
    108503: 'Errors occorred in RELATION algorithm'
  },
  qsar_error: {
    101701: 'jason failed to parse the Inno-QSAR parameter',
    101702: 'Essential parameter is missing in Inno-QSAR',
    101703: 'Lack of dataset_id',
    101704: 'Lack of label_col or input_col',
    101705: 'Invalid training algorithm',
    104706: 'Essential parameter is missing in Inno-QSAR Task',
    104707: 'The parameter required for the Inno-QSAR task is null',
    104708: 'Failed to trigger Inno-QSAR task',
    107709: 'Errors occurred during Inno-QSAR  train callback ',
    107710: 'Errors occurred during Inno-QSAR callback ',
    400: 'Inno-QSAR failed to Trigger Cloud Service'
  },
  rescoring_error: {
    101801: 'jason failed to parse the Rescoring parameter',
    101802: 'Essential parameter is missing in Rescoring',
    108803: 'Errors occorred in Inno-Rescoring algorithm'
  },
  lig_prep_error: {
    101901: 'jason failed to parse the LigPrep parameter',
    101902: 'Essential parameter is missing in LigPrep',
    101903: 'Lack of Ligand',
    108904: 'Errors occorred in Ligand Preparation algorithm'
  },
  resgen_error: {
    101201: 'Missing job id',
    204: 'The job id does not exist',
    205: 'Errors occurred during the protein pocket preparation',
    400: 'ResGen failed to Trigger Cloud Service',
    209: 'Invalid action',
    202: 'Failed to obtain the smiles_id in molecular detailed page',
    201: 'smiles_id  is missing in molecular similarity interface',
    101202: 'smiles_id and smiles is missing in molecular similarity interface',
    101203: 'job_id is missing in molecular similarity interface',
    203: 'Errors occurred during rdkit turn to  mol or generate fingerprint in molecular similarity interface',
    '101201d': 'job_id is missing in download interface',
    210: 'only csv files can be downloaded',
    101204: 'Clustering parameter is missing',
    211: 'No eligible molecules are available for download'
  },
  specturm_error: {
    101601: 'Parsing NMR parameter error',
    101602: 'Missing NMR task parameters',
    108603: 'Errors occorred in CReSS algorithm',
    108604: 'Errors occorred in CMGNet algorithm'
  },
  img2mol_error: {
    101901: 'Args parse error',
    101902: 'args missing error',
    108905: 'Task or job not found error',
    108904: 'Structure extract inner error',
    108906: 'The newly added positioning has failed because the structure of the newly added molecule is inconsistent with the original molecule.',
    108907: 'The molecule added for positioning is inconsistent with the structure of the original molecule, but it is consistent with the structure of other molecule.',
    108908: 'New localization failed, duplicate additions have been filtered out',
    108910: 'Sorry, your table parsing failed'
  }
}

export default en
